import React, { Component } from "react";
class ActiveInactiveCell extends Component {
  render() {
    const value = this.props.dataItem[this.props.field];
    return (
      <td
        style={{
          color: value ? this.props.myProp[0].color : this.props.myProp[1].color
        }}
      >
        {" "}
        {value === null ? (
          ""
        ) : (
          <React.Fragment>
            <input
              disabled
              className="k-checkbox"
              defaultChecked={value}
              //id={this.props.dataItem["FAQMasterID"]}
              type="checkbox"
            />
            <label
              //htmlFor={this.props.dataItem["FAQMasterID"]}
              className={value ? "k-checkbox-label" : "k-checkbox-label inactive"}
            >
              {value ? "Active" : "In active"}
            </label>
          </React.Fragment>
        )}
      </td>
    );
  }
}

export default ActiveInactiveCell;
