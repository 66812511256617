import React, { Component } from "react";
import Moment from "react-moment";
import fblogo from "../Images/facebook-footer.svg";
import twtlogo from "../Images/twitter-footer.svg";
import linkdinlogo from "../Images/linkedin-footer.svg";
import pinterestlogo from "../Images/pinterest-footer.svg";
import instalogo from "../Images/instagram-footer.svg";
import bloggerlogo from "../Images/blogger-footer.svg";


class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date()
    };
  }
  render() {
    return (
      <React.Fragment>
        <footer className="footer_wrap footerbot_wrap">
         
          <div className="footer-middle ">
            <div className="container">
              <div className="footer-midwrap">
                <div className="foot-mid-left">
                <ul id="menu-footermenu" className="footer-menu clearfix newfoot-menu">
                    <li>
                      <a href="https://www.conceptinfoway.net/" target="_blank">
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.conceptinfoway.net/blog/"
                        target="_blank"
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.conceptinfoway.net/news/"
                        target="_blank"
                      >
                        News
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.conceptinfoway.net/faq/"
                        target="_blank"
                      >
                        FAQ
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.conceptinfoway.net/privacy-policy/"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.conceptinfoway.net/terms-and-conditions/"
                        target="_blank"
                      >
                        Terms and Conditions
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                <a className="protected-btn"
                    target="_blank"
                    href="http://www.copyscape.com/online-copyright-protection/"
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="btm-footerwrap">
                <div className="bottom-left">
                  <div className="copy-text">
                    Copyright &copy;{" "}
                    <Moment format="YYYY">{this.state.currentDate}</Moment>{" "}
                    <a href="https://www.conceptinfoway.net/">
                      Concept Infoway
                    </a>
                    . All rights reserved.
                  </div>
                </div>
                <div className="bottom-right">
                  <ul className="socialmedia">
                    <li>
                      <a href="https://www.facebook.com/conceptinfoway" className="fb" target="_blank">
                      <img src={fblogo} />
                      </a>
                    </li>
                    <li>
                      <a
                      href="https://twitter.com/ConceptInfoway"
                      className="tw"
                      target="_blank"
                    ><img src={twtlogo} /></a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/conceptinfoway" className="in" target="_blank">
                        <img src={linkdinlogo} />
                      </a>
                    </li>
                    <li>
                      <a
                      href="https://www.pinterest.com/conceptinfoway1/"
                      className="pin"
                      target="_blank"
                    ><img src={pinterestlogo} /></a>
                    </li>
                    <li>
                      <a
                      href="https://www.instagram.com/conceptinfoway/"
                      className="inst"
                      target="_blank"
                    ><img src={instalogo} /></a>
                    </li>
                    <li>
                      <a
                      href="https://www.conceptinfoway.net/blog"
                      className="blogfooter"
                      target="_blank"
                    ><img src={bloggerlogo} /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
