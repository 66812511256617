import React, { Component } from "react";
import Noty from "noty";
import { Form, Input, Label, Button } from "reactstrap";
import { async } from "q";
import { changePassword } from "../APICalls/commonAPICalls";
import { decryptKey, passwordRegex } from "../CommonFunctions/CommonValues";
import Cryptr from "cryptr";

const cryptr = new Cryptr(decryptKey);
const strongRegex = passwordRegex;

const changePasswordFormValid = ({ changePasswordFormError, ...rest }) => {
  var valid = true;

  Object.values(changePasswordFormError).map(val => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).map(val => {
    (val.currentpassword === "" ||
      val.newpassword === "" ||
      val.confirmpassword === "") &&
      (val.newpassword !== "" && val.confirmpassword !== ""
        ? val.newpassword === val.confirmpassword
        : (valid = false)) &&
      (valid = false);
  });

  return valid;
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showAlertMessage: false,
      alertMessage: "",
      alertColor: "",
      notyTimeOut: 5000,
      alertTimeOut: 500,
      changePasswordModal: {
        currentpassword: "",
        newpassword: ""
      },
      changePasswordFormError: {
        currentpassword: "",
        newpassword: "",
        confirmpassword: ""
      }
    };
  }

  handleChange = e => {
    var changePasswordFormError = this.state.changePasswordFormError;
    var { changePasswordModal } = this.state;

    var target = e.target;
    var value = target.type === "checked" ? target.checked : target.value;
    var name = target.name;

    switch (name) {
      case "currentpassword":
        changePasswordModal.currentpassword = value;
        changePasswordFormError.currentpassword =
          !strongRegex.test(value) && value.length > 0
            ? "Password must contain at least 1 upper case, 1 lower case, 1 special character and 1 number upto minimum 8 characters."
            : value.length > 0 && value.length < 6
            ? "Minimum 6 characters required"
            : "";
        break;
      case "newpassword":
        changePasswordModal.newpassword = value;
        changePasswordFormError.newpassword =
          !strongRegex.test(value) && value.length > 0
            ? "Password must contain at least 1 upper case, 1 lower case, 1 special character and 1 number upto minimum 8 characters."
            : value.length > 0 && value.length < 6
            ? "Minimum 6 characters required"
            : "";
        break;
      case "confirmpassword":
        changePasswordModal.confirmpassword = value;
        changePasswordFormError.confirmpassword =
          !strongRegex.test(value) && value.length > 0
            ? "New Password and Confirm Password must be same."
            : value === changePasswordModal.newpassword
            ? ""
            : "New Password and Confirm Password must be same.";
        break;
      default:
        break;
    }

    this.setState({
      changePasswordFormError,
      changePasswordModal
    });
  };

  handleSubmit = async e => {
    e.preventDefault();

    if (changePasswordFormValid(this.state)) {
      this.setState({ isLoading: true });
      await changePassword(this.state.changePasswordModal).then(response => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          } else if (response.data.ResponseStatus === 1) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "success",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );

            var { changePasswordModal } = this.state;
            changePasswordModal.currentpassword = "";
            changePasswordModal.newpassword = "";
            this.setState({ changePasswordModal });

            window.setTimeout(() => {
              localStorage.clear();
              this.props.history.push("/recruitportal");
            }, this.state.alertTimeOut);
          } else {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          }
        }
      });
    } else {
      this.setState(
        {
          isLoading: false,
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: "Please fill proper details!"
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        }
      );
    }
  };

  onDismiss = () => {
    this.setState({ showAlertMessage: false });
  };

  showNotification(state) {
    new Noty({
      type: state.alertColor,
      theme: "bootstrap-v4",
      layout: "topRight",
      closeWith: ["button"],
      text: state.alertMessage,
      timeout: state.notyTimeOut
    }).show();
  }

  render() {
    const { changePasswordFormError } = this.state;
    const { changePasswordModal } = this.state;

    const ChangePasswordForm = (
      <Form
        className="form-signin"
        noValidate
        onSubmit={this.handleSubmit}
        style={{ textAlign: "left" }}
      >
        <div className="form-label-group">
          <Input
            type="password"
            id="currentpassword"
            name="currentpassword"
            className="form-control"
            placeholder="Current Password"
            noValidate
            autoFocus
            value={changePasswordModal.currentpassword}
            onChange={this.handleChange}
          />
          <span className="star">*</span>
          <Label htmlFor="currentpassword">Current Password</Label>
          {changePasswordFormError.currentpassword.length > 0 && (
            <span className="errorMessage">
              {changePasswordFormError.currentpassword}
            </span>
          )}
        </div>

        <div className="form-label-group">
          <Input
            type="password"
            id="newpassword"
            name="newpassword"
            className="form-control"
            placeholder="New Password"
            noValidate
            value={changePasswordModal.newpassword}
            onChange={this.handleChange}
          />
          <span className="star">*</span>
          <Label htmlFor="newpassword">New Password</Label>
          {changePasswordFormError.newpassword.length > 0 && (
            <span className="errorMessage">
              {changePasswordFormError.newpassword}
            </span>
          )}
        </div>

        <div className="form-label-group">
          <Input
            type="password"
            id="confirmpassword"
            name="confirmpassword"
            className="form-control"
            placeholder="Confirm Password"
            noValidate
            onChange={this.handleChange}
          />
          <span className="star">*</span>
          <Label htmlFor="confirmpassword">Confirm Password</Label>
          {changePasswordFormError.confirmpassword.length > 0 && (
            <span className="errorMessage">
              {changePasswordFormError.confirmpassword}
            </span>
          )}
        </div>

        <div>
          <Button
            className="btn btn-lg btn-primary btn-block mt-3 ciplbtn"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </Form>
    );

    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
          </div>
        ) : (
          ""
        )}
        <main role="main">
          <div className="starter-template">
            <h1 className="h3 mb-3 font-weight-normal">Change Password</h1>
            {ChangePasswordForm}
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default ChangePassword;
