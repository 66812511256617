import React, { Component } from "react";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <main role="main">
          <div className="starter-template view_recruit_main">
            {/* <h1>Welcome to Dashboard</h1> */}
            <h1 className="h3 mb-3 font-weight-normal">
              Welcome to admin panel of Recruitment
            </h1>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Dashboard;
