import React, { Component } from "react";
import { formatNumber } from "@telerik/kendo-intl";

class ExperienceCell extends Component {
  render() {
    const ExperienceValue = this.props.dataItem["Experience"];
    // const AfterDecimalValue = ExperienceValue.toString().substring(
    //   ExperienceValue.toString().lastIndexOf(".") + 1
    // );
    if (ExperienceValue > 0) {
      if (ExperienceValue.toString().indexOf(".") > -1) {
        return <td>{formatNumber(ExperienceValue, "0.00")}</td>;
      } else {
        return <td>{formatNumber(ExperienceValue, "0")}</td>;
      }
    } else {
      return <td></td>;
    }
  }
}

export default ExperienceCell;
