import React, { Component } from "react";
import Noty from "noty";
import { Label, Input, Form } from "reactstrap";
import "../css/form.css";
import noImage from "../Images/camera.png";
import Resizer from "react-image-file-resizer";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
  mobileVendor,
} from "react-device-detect";
import {
  technologyDropdown,
  addRecruit,
  referenceDropdown,
  noticePeriodDropdown,
  siteConfiguration,
  bindFromYears,
  bindToYears,
} from "../APICalls/commonAPICalls";
import Recaptcha from "react-recaptcha";
import "react-html5-camera-photo/build/css/index.css";
import Webcam from "react-webcam";
import {
  decryptKey,
  captchaKey,
  emailAddressRegex,
  digitRegex,
  noScriptRegex,
} from "../CommonFunctions/CommonValues";
import { animateScroll as scroll } from "react-scroll";
import {
  ShowErrorMessageForMandatoryFields,
  ShowErrorMessageForTechnologyFields,
  ShowErrorMessageForCompanyFields,
  ShowErrorMessageForInvalidCaptcha,
  ShowErrorMessageForNoWebCam,
  ShowErrorMessageForResumeSizeExceed,
  ShowErrorMessageForProfilePicSizeExceed,
} from "../Resources/MessageResource";
import Cryptr from "cryptr";
import { async } from "q";
import CommonImageCrop from "../CommonFunctions/CommonImageCrop";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const cryptr = new Cryptr(decryptKey);
const CaptchaKeyV2 = captchaKey;
const delay = 100;
const emailRegex = emailAddressRegex;
const numberRegex = digitRegex;
const scriptRegex = noScriptRegex;
const ErrorMessage = ShowErrorMessageForMandatoryFields;
const TechnologyErrorMessage = ShowErrorMessageForTechnologyFields;
const CompnayErrorMessage = ShowErrorMessageForCompanyFields;
const InvalidCaptcha = ShowErrorMessageForInvalidCaptcha;
const NoWebCam = ShowErrorMessageForNoWebCam;
const ResumeSizeExceed = ShowErrorMessageForResumeSizeExceed;
const ProfilePicSizeExceed = ShowErrorMessageForProfilePicSizeExceed;

const recruitFormValid = ({ recruitFormErrors, ...rest }) => {
  var valid = true;
  Object.values(rest).forEach((val) => {
    if (
      val.TechnologyMasterID === 0 ||
      val.FirstName === "" ||
      val.LastName === "" ||
      val.Email === "" ||
      val.Mobile === "" ||
      val.HighestQualification === "" ||
      val.CurrentSalary === "0" ||
      val.CurrentSalary === "" ||
      val.ExpectedSalary === "0" ||
      val.ExpectedSalary === "" ||
      val.NoticePeriodID === 0 ||
      val.SuitableDate === "" ||
      val.SuitableTime === ""
    ) {
      if (val.TechnologyMasterID === 0) {
        recruitFormErrors.TechnologyMasterID = "Please select Main Technology";
      }
      if (val.FirstName === "") {
        recruitFormErrors.FirstName = "Please enter valid First Name";
      }
      if (val.LastName === "") {
        recruitFormErrors.LastName = "Please enter valid Last Name";
      }
      if (val.Email === "") {
        recruitFormErrors.Email = "Please enter valid Email Address";
      }
      if (val.Mobile === "") {
        recruitFormErrors.Mobile = "Please enter valid Mobile Number";
      }
      if (val.HighestQualification === "") {
        recruitFormErrors.HighestQualification =
          "Please enter valid Highest Qualification";
      }
      if (val.CurrentSalary === "0" || val.CurrentSalary === "") {
        recruitFormErrors.CurrentSalary = "Please enter valid Current Salary";
      }
      if (val.ExpectedSalary === "0" || val.ExpectedSalary === "") {
        recruitFormErrors.ExpectedSalary = "Please enter valid Expected Salary";
      }
      if (val.NoticePeriodID === 0 || val.NoticePeriodID === "") {
        recruitFormErrors.NoticePeriodID = "Please select when can you join?";
      }
      if (val.SuitableDate === "") {
        recruitFormErrors.SuitableDate = "Please select Suitable Date";
      }
      if (val.SuitableTime === "") {
        recruitFormErrors.SuitableTime = "Please select Suitable Time";
      }
      valid = false;
    } else {
      if (
        recruitFormErrors.TechnologyMasterID === "" &&
        recruitFormErrors.FirstName === "" &&
        recruitFormErrors.LastName === "" &&
        recruitFormErrors.Email === "" &&
        recruitFormErrors.Mobile === "" &&
        recruitFormErrors.ReferenceName === "" &&
        recruitFormErrors.HighestQualification === "" &&
        recruitFormErrors.ExtraCertification === "" &&
        (recruitFormErrors.CurrentSalary === "" ||
          recruitFormErrors.CurrentSalary === 0) &&
        (recruitFormErrors.ExpectedSalary === "" ||
          recruitFormErrors.ExpectedSalary === 0) &&
        recruitFormErrors.NoticePeriodID === "" &&
        recruitFormErrors.SuitableDate === "" &&
        recruitFormErrors.SuitableTime === ""
      ) {
        valid = true;
      } else {
        valid = false;
      }
    }
  });
  return valid;
};

const technologyFormValid = ({ recruitFormErrors }) => {
  var valid = true;

  Object.values(recruitFormErrors.technologiesError).forEach((val) => {
    if (scriptRegex.test(val.technology)) {
      (val.technology === undefined ||
      val.experienceInMonth === undefined ||
      val.experienceInYear === undefined
        ? (valid = false)
        : val.technology.length > 0 ||
          val.experienceInMonth.length > 0 ||
          val.experienceInYear.length > 0) && (valid = false);
    } else {
      valid = false;
    }
  });
  return valid;
};

const employerFormValid = ({ recruitFormErrors }) => {
  var valid = true;

  Object.values(recruitFormErrors.employerDetailsError).forEach((val) => {
    if (scriptRegex.test(val.companyName)) {
      if (
        val.companyName === undefined ||
        val.durationFromYear === undefined ||
        val.durationFromMonth === undefined ||
        val.durationToYear === undefined ||
        val.durationToMonth === undefined
      ) {
        valid = false;
      } else {
        if (
          val.companyName.length > 0 ||
          val.durationFromYear.length > 0 ||
          val.durationFromMonth.length > 0 ||
          val.durationToYear.length > 0 ||
          val.durationToMonth.length > 0
        ) {
          valid = false;
        }
      }
    } else {
      valid = false;
    }
  });
  return valid;
};

class AddNewRecruit extends Component {
  months = [
    { Value: "0", ExperienceInMonth: 0 },
    { Value: "1", ExperienceInMonth: 1 },
    { Value: "2", ExperienceInMonth: 2 },
    { Value: "3", ExperienceInMonth: 3 },
    { Value: "4", ExperienceInMonth: 4 },
    { Value: "5", ExperienceInMonth: 5 },
    { Value: "6", ExperienceInMonth: 6 },
    { Value: "7", ExperienceInMonth: 7 },
    { Value: "8", ExperienceInMonth: 8 },
    { Value: "9", ExperienceInMonth: 9 },
    { Value: "10", ExperienceInMonth: 10 },
    { Value: "11", ExperienceInMonth: 11 },
    // { Value: "12", ExperienceInMonth: 12 }
  ];

  years = [
    { Value: "0", ExperienceInYear: 0 },
    { Value: "1", ExperienceInYear: 1 },
    { Value: "2", ExperienceInYear: 2 },
    { Value: "3", ExperienceInYear: 3 },
    { Value: "4", ExperienceInYear: 4 },
    { Value: "5", ExperienceInYear: 5 },
    { Value: "6", ExperienceInYear: 6 },
    { Value: "7", ExperienceInYear: 7 },
    { Value: "8", ExperienceInYear: 8 },
    { Value: "9", ExperienceInYear: 9 },
    { Value: "10", ExperienceInYear: 10 },
    { Value: "11", ExperienceInYear: 11 },
    { Value: "12", ExperienceInYear: 12 },
    { Value: "13", ExperienceInYear: 13 },
    { Value: "14", ExperienceInYear: 14 },
    { Value: "15", ExperienceInYear: 15 },
    { Value: "16", ExperienceInYear: 16 },
    { Value: "17", ExperienceInYear: 17 },
    { Value: "18", ExperienceInYear: 18 },
    { Value: "19", ExperienceInYear: 19 },
    { Value: "20", ExperienceInYear: 20 },
  ];

  fromMonths = [
    { Value: "Jan", DurationFromMonth: 1 },
    { Value: "Feb", DurationFromMonth: 2 },
    { Value: "Mar", DurationFromMonth: 3 },
    { Value: "Apr", DurationFromMonth: 4 },
    { Value: "May", DurationFromMonth: 5 },
    { Value: "Jun", DurationFromMonth: 6 },
    { Value: "Jul", DurationFromMonth: 7 },
    { Value: "Aug", DurationFromMonth: 8 },
    { Value: "Sep", DurationFromMonth: 9 },
    { Value: "Oct", DurationFromMonth: 10 },
    { Value: "Nov", DurationFromMonth: 11 },
    { Value: "Dec", DurationFromMonth: 12 },
  ];

  toMonths = [
    { Value: "Jan", DurationToMonth: 1 },
    { Value: "Feb", DurationToMonth: 2 },
    { Value: "Mar", DurationToMonth: 3 },
    { Value: "Apr", DurationToMonth: 4 },
    { Value: "May", DurationToMonth: 5 },
    { Value: "Jun", DurationToMonth: 6 },
    { Value: "Jul", DurationToMonth: 7 },
    { Value: "Aug", DurationToMonth: 8 },
    { Value: "Sep", DurationToMonth: 9 },
    { Value: "Oct", DurationToMonth: 10 },
    { Value: "Nov", DurationToMonth: 11 },
    { Value: "Dec", DurationToMonth: 12 },
  ];

  defaultTechnologyMonth = {
    Value: "Month(s)",
    experienceInMonth: -1,
  };

  defaultTechnologyYear = {
    Value: "Year(s)",
    experienceInYear: -1,
  };

  defaultNoticePeriodItem = {
    Description: "Select when can you Join?",
    NoticePeriodID: 0,
  };

  defaultTechnologyItem = {
    Description: "Select Main Technology",
    TechnologyMasterID: 0,
  };

  defaultReferenceItem = {
    Description: "Select Reference",
    ReferenceMasterID: 0,
  };

  defaultCompanyFromYear = {
    Value: "From Year",
    DurationFromYear: 0,
  };

  defaultCompanyToYear = {
    Value: "To Year",
    DurationToYear: 0,
  };

  defaultCompanyFromMonth = {
    Value: "From Month",
    DurationFromMonth: 0,
  };

  defaultCompanyToMonth = {
    Value: "To Month",
    DurationToMonth: 0,
  };

  steps = {
    hour: 1,
    minute: 5,
    second: 0,
  };

  constructor(props) {
    localStorage.setItem("localpath", props.location.pathname);
    super(props);
    this.state = {
      fromYears: [],
      toYears: [],
      isLoading: false,
      isCaptchaVerified: false,
      showAlertMessage: false,
      alertMessage: "",
      alertColor: "",
      notyTimeOut: 5000,
      alertTimeOut: 500,
      tempTechnologyData: [],
      technologyDropdown: [],
      tempReferenceData: [],
      referenceDropdown: [],
      tempNoticePeriodData: [],
      noticePeriodDropdown: [],
      currentDate: new Date(),
      loading: false,
      isWebCamOpen: false,
      Cropsrc: "",
      crop: {
        unit: "px",
        width: 230,
        height: 230,
        aspect: 1 / 1,
      },
      visibleDialog: false,
      rotateFlag: true,
      IsIOS: false,
      siteConfigData: {
        isWalkingInterview: false,
        suitableTimeStarting: 0,
        suitableTimeEnding: 0,
      },
      recruitModal: {
        isCamera: false,
        addNewReference: false,
        TechnologyMasterID: 0,
        Experienced: false,
        FirstName: "",
        LastName: "",
        Email: "",
        Mobile: "",
        CandidateImage: "",
        CandidateImageActual: "",
        CandidateImageBase64: "",
        PhotoName: "",
        ReferenceMasterID: 0,
        ReferenceName: "",
        HighestQualification: "",
        ExtraCertification: "",
        CurrentSalary: "",
        ExpectedSalary: "",
        Reconsider: false,
        NoticePeriodID: 0,
        Resume: "",
        ResumeFileName: "",
        SuitableDate: new Date(),
        SuitableTime: new Date(2100, 2, 10, 10, 0),
        DateOfJoining: "",
        Reason: "",
        CreatedDate: new Date(),
        resumeData: "",
        technologies: [
          { technology: "", ExperienceInMonth: -1, ExperienceInYear: -1 },
        ],
        employerDetails: [
          {
            companyName: "",
            DurationFromYear: 0,
            DurationFromMonth: 0,
            DurationToYear: 0,
            DurationToMonth: 0,
            Experience: "",
            ShowExperience: false,
          },
        ],
      },
      recruitFormErrors: {
        TechnologyMasterID: "",
        FirstName: "",
        LastName: "",
        Email: "",
        Mobile: "",
        ReferenceName: "",
        HighestQualification: "",
        ExtraCertification: "",
        CurrentSalary: 0,
        ExpectedSalary: 0,
        NoticePeriodID: "",
        SuitableDate: "",
        SuitableTime: "",
        technologiesError: [
          { technology: "", experienceInMonth: -1, experienceInYear: -1 },
        ],
        employerDetailsError: [
          {
            companyName: "",
            durationFromYear: 0,
            durationFromMonth: 0,
            durationToYear: 0,
            durationToMonth: 0,
          },
        ],
      },
    };
  }

  async componentDidMount() {
    await technologyDropdown().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          if (response.data.ResponseStatus === 1) {
            this.setState({
              tempTechnologyData: response.data.lstTechnology.slice(),
              technologyDropdown: response.data.lstTechnology.slice(),
            });
          }
        } else {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await referenceDropdown().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          if (response.data.ResponseStatus === 1) {
            this.setState({
              tempReferenceData: response.data.lstReference.slice(),
              referenceDropdown: response.data.lstReference.slice(),
            });
          }
        } else {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await noticePeriodDropdown().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            tempNoticePeriodData: response.data.lstNoticePeriod.slice(),
            noticePeriodDropdown: response.data.lstNoticePeriod.slice(),
          });
        } else {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await siteConfiguration().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            siteConfigData: {
              isWalkingInterview: response.data.IsWalkingInterview,
              suitableTimeStarting: response.data.SuitableTimeStarting,
              suitableTimeEnding: response.data.SuitableTimeEnding,
            },
          });
        } else {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await bindFromYears().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            isLoading: false,
            fromYears: response.data.YearJSON,
          });
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await bindToYears().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            isLoading: false,
            toYears: response.data.YearJSON,
          });
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });
  }

  handleRecruitSubmit = async (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    this.setState({ isLoading: true });
    if (recruitFormValid(this.state)) {
      if (technologyFormValid(this.state)) {
        if (employerFormValid(this.state)) {
          if (this.state.isCaptchaVerified) {
            await addRecruit(this.state.recruitModal).then((response) => {
              if (localStorage.getItem("failerMessage")) {
                this.setState(
                  {
                    isLoading: false,
                    showAlertMessage: true,
                    alertColor: "error",
                    alertMessage: localStorage.getItem("failerMessage"),
                  },
                  () => {
                    window.setTimeout(() => {
                      this.setState({ showAlertMessage: false });
                      this.showNotification(this.state);
                    }, this.state.alertTimeOut);
                  },
                );
                localStorage.removeItem("failerMessage");
              } else {
                var response = JSON.parse(cryptr.decrypt(response));
                if (response.data.ResponseStatus === 3) {
                  this.setState(
                    {
                      isLoading: false,
                      showAlertMessage: true,
                      alertColor: "error",
                      alertMessage: response.data.ResponseMsg,
                    },
                    () => {
                      window.setTimeout(() => {
                        this.setState({ showAlertMessage: false });
                        this.showNotification(this.state);
                      }, this.state.alertTimeOut);
                    },
                  );
                } else if (response.data.ResponseStatus === 1) {
                  scroll.scrollToTop();
                  this.setState(
                    {
                      isLoading: false,
                      showAlertMessage: true,
                      alertColor: "success",
                      alertMessage: response.data.ResponseMsg,
                    },
                    () => {
                      window.setTimeout(() => {
                        this.setState({ showAlertMessage: false });
                        this.showNotification(this.state);
                      }, this.state.alertTimeOut);
                    },
                  );
                  window.setTimeout(() => {
                    this.props.history.push("/recruitportal/view");
                  }, 1000);
                } else {
                  this.setState(
                    {
                      isLoading: false,
                      showAlertMessage: true,
                      alertColor: "error",
                      alertMessage: localStorage.getItem("failerMessage"),
                    },
                    () => {
                      window.setTimeout(() => {
                        this.setState({ showAlertMessage: false });
                        this.showNotification(this.state);
                      }, this.state.alertTimeOut);
                    },
                  );
                }
              }
            });
          } else {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: InvalidCaptcha,
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );
          }
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: CompnayErrorMessage,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      } else {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: TechnologyErrorMessage,
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
      }
    } else {
      this.setState(
        {
          isLoading: false,
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: ErrorMessage,
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        },
      );
    }
  };

  recaptchaLoaded = () => {
    console.log("Recaptcha Successfully Loaded");
  };

  verifyCallback = (response) => {
    if (response) {
      this.setState({ isCaptchaVerified: true });
    }
  };

  handleChange = (e) => {
    var recruitFormErrors = this.state.recruitFormErrors;
    var { recruitModal } = this.state;
    var target = e.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    var name = target.name;

    switch (name) {
      case "mainTechnology":
        recruitModal.TechnologyMasterID = value.TechnologyMasterID;
        recruitFormErrors.TechnologyMasterID =
          value.TechnologyMasterID == 0 || value.TechnologyMasterID == ""
            ? "Please select Main Technology"
            : "";
        break;
      case "firstname":
        recruitModal.FirstName = value;
        recruitFormErrors.FirstName =
          scriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid First Name";
        break;
      case "lastname":
        recruitModal.LastName = value;
        recruitFormErrors.LastName =
          scriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Last Name";
        break;
      case "email":
        recruitModal.Email = value;
        recruitFormErrors.Email =
          emailRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Email Address";
        break;
      case "mobile":
        recruitModal.Mobile = value;
        recruitFormErrors.Mobile =
          numberRegex.test(value) && value.length > 0
            ? value.length >= 10 && value.length <= 15
              ? ""
              : "Please enter valid Mobile Number"
            : "Please enter valid Mobile Number";
        break;
      case "reference":
        recruitModal.ReferenceMasterID = value.ReferenceMasterID;
        value.ReferenceMasterID === -1
          ? (recruitModal.addNewReference = true)
          : (recruitModal.addNewReference = false);
        break;
      case "referenceName":
        recruitModal.ReferenceName = value;
        recruitFormErrors.ReferenceName = scriptRegex.test(value)
          ? ""
          : "Please enter valid Reference Name";
        recruitModal.ReferenceMasterID = 0;
        break;
      case "highestQualification":
        recruitModal.HighestQualification = value;
        recruitFormErrors.HighestQualification =
          scriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Highest Qualification";
        break;
      case "extraCertification":
        recruitModal.ExtraCertification = value;
        recruitFormErrors.ExtraCertification = scriptRegex.test(value)
          ? ""
          : "Please enter valid Extra Certification";
        break;
      case "currentSalary":
        recruitModal.CurrentSalary = value;
        recruitFormErrors.CurrentSalary =
          numberRegex.test(value) && value.length > 0
            ? value === 0
              ? "Please enter valid Current Salary"
              : ""
            : "Please enter valid Current Salary";
        break;
      case "expectedSalary":
        recruitModal.ExpectedSalary = value;
        recruitFormErrors.ExpectedSalary =
          numberRegex.test(value) && value.length > 0
            ? value === 0
              ? "Please enter valid Expected Salary"
              : ""
            : "Please enter valid Expected Salary";
        break;
      case "reconsider":
        recruitModal.Reconsider = value;
        break;
      case "noticePeriod":
        recruitModal.NoticePeriodID = value.NoticePeriodID;
        recruitFormErrors.NoticePeriodID =
          value.NoticePeriodID == 0 || value.NoticePeriodID == ""
            ? "Please select when can you join?"
            : "";
        break;
      case "suitableDate":
        recruitModal.SuitableDate = value;
        var d = new Date(value);
        recruitFormErrors.SuitableDate =
          value != null
            ? this.state.siteConfigData.isWalkingInterview
              ? ""
              : d.getDay() === 6 || d.getDay() === 0
              ? "Please select Suitable Date within WeekDays"
              : ""
            : "Please select Suitable Date";
        break;
      case "suitableTime":
        recruitModal.SuitableTime = value;
        var startingRange = new Date(
          2100,
          2,
          10,
          this.state.siteConfigData.suitableTimeStarting,
          0,
        );
        var endingRange = new Date(
          2100,
          2,
          10,
          this.state.siteConfigData.suitableTimeEnding - 1,
          55,
        );
        var currentTime = new Date(value);
        if (this.state.siteConfigData.isWalkingInterview) {
          if (value === null) {
            recruitFormErrors.SuitableTime = "Please select Suitable Time";
          } else {
            if (currentTime >= startingRange && currentTime <= endingRange) {
              recruitFormErrors.SuitableTime = "";
            } else {
              recruitFormErrors.SuitableTime =
                "Please select proper Suitable Time";
            }
          }
        } else {
          recruitFormErrors.SuitableTime =
            value != null ? "" : "Please select Suitable Time";
        }
        break;
    }

    this.setState({
      recruitFormErrors,
      recruitModal,
    });
  };

  filterChangeTechnology = (event) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        technologyDropdown: this.filterTechnologyData(event.filter),
        loading: false,
      });
    }, delay);

    this.setState({
      loading: true,
    });
  };

  filterChangeReference = (event) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        referenceDropdown: this.filterReferenceData(event.filter),
        loading: false,
      });
    }, delay);

    this.setState({
      loading: true,
    });
  };

  filterChangeNoticePeriod = (event) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        noticePeriodDropdown: this.filterNoticePeriodData(event.filter),
        loading: false,
      });
    }, delay);

    this.setState({
      loading: true,
    });
  };

  filterTechnologyData(filter) {
    const data = this.state.tempTechnologyData;
    return filterBy(data, filter);
  }

  filterReferenceData(filter) {
    const data = this.state.tempReferenceData;
    return filterBy(data, filter);
  }

  filterNoticePeriodData(filter) {
    const data = this.state.tempNoticePeriodData;
    return filterBy(data, filter);
  }

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  capture = () => {
    var { recruitModal } = this.state;
    if (this.webcam.state.hasUserMedia) {
      const imageSrc = this.webcam.getScreenshot();
      recruitModal.CandidateImage = imageSrc;
      recruitModal.isCamera = true;
      this.setState({
        recruitModal,
        isWebCamOpen: false,
        Cropsrc: imageSrc,
      });
      this.setCropInit();
      this.toggleDialog(this.props);
    } else {
      recruitModal.CandidateImage = "";
      recruitModal.isCamera = false;
      this.setState({ recruitModal, isWebCamOpen: false, Cropsrc: "" });
      this.setState(
        {
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: NoWebCam,
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        },
      );
    }
  };

  openWebCam = () => {
    var { recruitModal } = this.state;
    if (this.state.isWebCamOpen) {
      this.setState({ isWebCamOpen: false });
    } else {
      recruitModal.CandidateImage = "";
      recruitModal.PhotoName = "";
      this.setState({ recruitModal, isWebCamOpen: true, Cropsrc: "" });
    }
  };

  _handleImageChange(e) {
    e.preventDefault();
    this.setState({
      Cropsrc: "",
    });
    var { recruitModal } = this.state;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file === undefined) {
    } else {
      if (file.size / 1048576 > 2) {
        this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: ProfilePicSizeExceed,
          },
          () => {
            window.setTimeout(() => {
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
      } else {
        // if (isIOS) {
        //   Resizer.imageFileResizer(
        //     e.target.files[0],
        //     230,
        //     230,
        //     "JPEG",
        //     100,
        //     90,
        //     uri => {
        //       recruitModal.PhotoName = file.name;
        //       recruitModal.CandidateImage = uri;
        //       this.setState({
        //         recruitModal,
        //         isWebCamOpen: false,
        //         isCamera: false
        //       });
        //     },
        //     "base64"
        //   );
        // } else if (isAndroid) {
        //   if (mobileVendor === "Samsung") {
        //     Resizer.imageFileResizer(
        //       e.target.files[0],
        //       230,
        //       230,
        //       "JPEG",
        //       100,
        //       270,
        //       uri => {
        //         recruitModal.PhotoName = file.name;
        //         recruitModal.CandidateImage = uri;
        //         this.setState({
        //           recruitModal,
        //           isWebCamOpen: false,
        //           isCamera: false
        //         });
        //       },
        //       "base64"
        //     );
        //   } else {
        //     Resizer.imageFileResizer(
        //       e.target.files[0],
        //       230,
        //       230,
        //       "JPEG",
        //       100,
        //       0,
        //       uri => {
        //         recruitModal.PhotoName = file.name;
        //         recruitModal.CandidateImage = uri;
        //         this.setState({
        //           recruitModal,
        //           isWebCamOpen: false,
        //           isCamera: false
        //         });
        //       },
        //       "base64"
        //     );
        //   }
        // } else {
        //   Resizer.imageFileResizer(
        //     e.target.files[0],
        //     230,
        //     230,
        //     "JPEG",
        //     100,
        //     0,
        //     uri => {
        //       recruitModal.PhotoName = file.name;
        //       recruitModal.CandidateImage = uri;
        //       this.setState({
        //         recruitModal,
        //         isWebCamOpen: false,
        //         isCamera: false
        //       });
        //     },
        //     "base64"
        //   );
        // }
        if (isAndroid && isMobile) {
          if (mobileVendor === "Samsung") {
            Resizer.imageFileResizer(
              e.target.files[0],
              350,
              350,
              "JPEG",
              100,
              270,
              (uri) => {
                recruitModal.PhotoName = file.name;
                recruitModal.CandidateImage = uri;
                this.setState({
                  recruitModal,
                  isWebCamOpen: false,
                  isCamera: false,
                  Cropsrc: uri,
                  IsIOS: false,
                });
              },
              "base64",
            );
          } else {
            Resizer.imageFileResizer(
              e.target.files[0],
              350,
              350,
              "JPEG",
              100,
              0,
              (uri) => {
                recruitModal.PhotoName = file.name;
                recruitModal.CandidateImage = uri;
                this.setState({
                  recruitModal,
                  isWebCamOpen: false,
                  isCamera: false,
                  Cropsrc: uri,
                  IsIOS: false,
                });
              },
              "base64",
            );
          }
        } else {
          if (isIOS) {
            Resizer.imageFileResizer(
              e.target.files[0],
              350,
              350,
              "JPEG",
              100,
              0,
              (uri) => {
                recruitModal.PhotoName = file.name;
                recruitModal.CandidateImage = uri;

                var resetImage = "";
                this.resetOrientation(uri, 6, function (resetBase64Image) {
                  resetImage = resetBase64Image;
                });

                this.setState({
                  recruitModal,
                  isWebCamOpen: false,
                  isCamera: false,
                  IsIOS: true,
                  //Cropsrc: uri
                });
              },
              "base64",
            );
          } else if (isBrowser) {
            Resizer.imageFileResizer(
              e.target.files[0],
              350,
              350,
              "JPEG",
              100,
              0,
              (uri) => {
                recruitModal.PhotoName = file.name;
                recruitModal.CandidateImage = uri;
                this.setState({
                  recruitModal,
                  isWebCamOpen: false,
                  isCamera: false,
                  Cropsrc: uri,
                  IsIOS: false,
                });
              },
              "base64",
            );
          } else {
            reader.onloadend = () => {
              recruitModal.PhotoName = file.name;
              recruitModal.CandidateImage = reader.result;
              this.setState({
                recruitModal,
                isWebCamOpen: false,
                isCamera: false,
                Cropsrc: reader.result,
                IsIOS: false,
              });
            };
            reader.readAsDataURL(file);
          }
        }
        this.setCropInit();
        this.toggleDialog(this.props);
      }
    }
  }

  resetOrientation = (srcBase64, srcOrientation, callback) => {
    var img = new Image();

    img.onload = function () {
      var width = img.width,
        height = img.height,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      //transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      const data = canvas.toDataURL();
      this.setState({
        Cropsrc: data,
      });

      callback(canvas.toDataURL());
    }.bind(this);

    img.src = srcBase64;
  };

  _handleResumeChange(e) {
    e.preventDefault();
    var { recruitModal } = this.state;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file === undefined) {
    } else {
      if (file.size / 1048576 > 2) {
        this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: ResumeSizeExceed,
          },
          () => {
            window.setTimeout(() => {
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
      } else {
        reader.onloadend = () => {
          recruitModal.Resume = reader.result;
          recruitModal.ResumeFileName = file.name;
          this.setState({
            recruitModal,
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }

  onDismiss = () => {
    this.setState({ showAlertMessage: false });
  };

  handleTechnologyChange = (idx) => (e) => {
    const recruitFormErrors = this.state.recruitFormErrors;
    const recruitModal = this.state.recruitModal;
    var target = e.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    var name = target.name;

    switch (name) {
      case "technology-" + idx:
        recruitModal.technologies[idx].technology = value;
        recruitFormErrors.technologiesError[idx].technology =
          scriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Technology";
        break;
      case "experienceInMonth-" + idx:
        recruitModal.technologies[idx].ExperienceInMonth =
          value.ExperienceInMonth;
        recruitFormErrors.technologiesError[idx].experienceInMonth =
          value.ExperienceInMonth >= 0 ? "" : "Please select Month";
        break;
      case "experienceInYear-" + idx:
        recruitModal.technologies[idx].ExperienceInYear =
          value.ExperienceInYear;
        recruitFormErrors.technologiesError[idx].experienceInYear =
          value.ExperienceInYear >= 0 ? "" : "Please select Year";
        break;
    }
    this.setState({
      recruitFormErrors,
      recruitModal,
    });
  };

  handleAddTechnologyRow = () => {
    if (technologyFormValid(this.state)) {
      const recruitFormErrors = this.state.recruitFormErrors;
      const recruitModal = this.state.recruitModal;
      recruitModal.technologies = [
        ...recruitModal.technologies,
        { technology: "", ExperienceInMonth: -1, ExperienceInYear: -1 },
      ];
      recruitFormErrors.technologiesError = [
        ...recruitFormErrors.technologiesError,
        { technology: "", experienceInMonth: -1, experienceInYear: -1 },
      ];
      this.setState({
        recruitFormErrors,
        recruitModal,
      });
    } else {
      this.setState(
        {
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: TechnologyErrorMessage,
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        },
      );
    }
  };

  handleRemoveRow = () => {
    this.setState({
      technologies: this.state.technologies.slice(0, -1),
    });
  };

  handleRemoveTechnologyRow = (idx) => () => {
    const { technologies } = this.state.recruitModal;
    const { technologiesError } = this.state.recruitFormErrors;
    technologies.splice(idx, 1);
    technologiesError.splice(idx, 1);
    this.setState({
      technologiesError,
      technologies,
    });
  };

  handleEmployerChange = (idx) => (e) => {
    const recruitFormErrors = this.state.recruitFormErrors;
    const recruitModal = this.state.recruitModal;
    var target = e.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    var name = target.name;
    var todayDate = new Date();

    switch (name) {
      case "companyName-" + idx:
        recruitModal.employerDetails[idx].companyName = value;
        recruitFormErrors.employerDetailsError[idx].companyName =
          scriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Company name";
        break;
      case "fromYear-" + idx:
        recruitModal.employerDetails[idx].DurationFromYear =
          value.DurationFromYear;
        if (
          recruitModal.employerDetails[idx].DurationFromMonth > 0 &&
          recruitModal.employerDetails[idx].DurationFromYear > 0 &&
          recruitModal.employerDetails[idx].DurationToMonth > 0 &&
          recruitModal.employerDetails[idx].DurationToYear > 0
        ) {
          recruitModal.employerDetails[idx].ShowExperience = true;
        } else {
          recruitModal.employerDetails[idx].ShowExperience = false;
        }

        if (recruitModal.employerDetails[idx - 1] != undefined) {
          recruitFormErrors.employerDetailsError[idx - 1].durationToYear =
            value.DurationFromYear > 0
              ? value.DurationFromYear >=
                recruitModal.employerDetails[idx - 1].DurationToYear
                ? ""
                : "To Year should less than next companies From Year"
              : "";

          recruitFormErrors.employerDetailsError[idx - 1].durationToMonth =
            value.DurationFromYear > 0
              ? value.DurationFromYear >=
                recruitModal.employerDetails[idx - 1].DurationToYear
                ? ""
                : "To Month should less than next companies From Month"
              : "";
        }

        recruitFormErrors.employerDetailsError[idx].durationFromYear =
          value.DurationFromYear > 0
            ? recruitModal.employerDetails[idx - 1] != undefined
              ? recruitModal.employerDetails[idx - 1].DurationToYear >
                recruitModal.employerDetails[idx].DurationFromYear
                ? "From Year should greater than previous companies To Year"
                : ""
              : value.DurationFromYear <=
                recruitModal.employerDetails[idx].DurationToYear
              ? ""
              : "From Year should less than To Year"
            : "Please select From Year";

        recruitFormErrors.employerDetailsError[idx].durationToYear =
          value.DurationFromYear > 0
            ? value.DurationFromYear <=
              recruitModal.employerDetails[idx].DurationToYear
              ? ""
              : "To Year should greater than From Year"
            : "Please select To Year";

        recruitFormErrors.employerDetailsError[idx].durationFromMonth =
          recruitModal.employerDetails[idx].DurationFromMonth > 0
            ? value.DurationFromYear === todayDate.getFullYear()
              ? todayDate.getMonth() + 1 <
                recruitModal.employerDetails[idx].DurationFromMonth
                ? "From Month should less than current calendar Month"
                : ""
              : recruitModal.employerDetails[idx - 1] != undefined
              ? recruitModal.employerDetails[idx - 1].DurationToYear >
                recruitModal.employerDetails[idx].DurationFromYear
                ? ""
                : recruitModal.employerDetails[idx - 1].DurationToYear ===
                    recruitModal.employerDetails[idx].DurationFromYear &&
                  recruitModal.employerDetails[idx - 1].DurationToMonth === 12
                ? "From Year & Month should greater than previous companies To Year & Month"
                : recruitModal.employerDetails[idx - 1].DurationToYear ===
                    recruitModal.employerDetails[idx].DurationFromYear &&
                  recruitModal.employerDetails[idx - 1].DurationToMonth >
                    recruitModal.employerDetails[idx].DurationFromMonth
                ? "From Year & Month should greater than previous companies To Year & Month"
                : ""
              : value.DurationFromYear <
                recruitModal.employerDetails[idx].DurationToYear
              ? ""
              : ""
            : "Please select From Month";

        recruitFormErrors.employerDetailsError[idx].durationToMonth =
          recruitModal.employerDetails[idx].DurationToMonth > 0
            ? recruitModal.employerDetails[idx].DurationFromMonth >
              recruitModal.employerDetails[idx].DurationToMonth
              ? recruitModal.employerDetails[idx].DurationFromYear <
                recruitModal.employerDetails[idx].DurationToYear
                ? ""
                : "To Month should greater than From Month"
              : ""
            : "Please select To Month";
        break;

      case "fromMonth-" + idx:
        recruitModal.employerDetails[idx].DurationFromMonth =
          value.DurationFromMonth;
        if (
          recruitModal.employerDetails[idx].DurationFromMonth > 0 &&
          recruitModal.employerDetails[idx].DurationFromYear > 0 &&
          recruitModal.employerDetails[idx].DurationToMonth > 0 &&
          recruitModal.employerDetails[idx].DurationToYear > 0
        ) {
          recruitModal.employerDetails[idx].ShowExperience = true;
        } else {
          recruitModal.employerDetails[idx].ShowExperience = false;
        }

        if (recruitModal.employerDetails[idx - 1] != undefined) {
          recruitFormErrors.employerDetailsError[idx - 1].durationToMonth =
            value.DurationFromMonth > 0
              ? value.DurationFromMonth >=
                recruitModal.employerDetails[idx - 1].DurationToMonth
                ? ""
                : recruitModal.employerDetails[idx - 1].DurationToYear <
                  recruitModal.employerDetails[idx].DurationFromYear
                ? ""
                : "To Month should less than next companies From Month"
              : "";
        }

        recruitFormErrors.employerDetailsError[idx].durationFromMonth =
          value.DurationFromMonth > 0
            ? recruitModal.employerDetails[idx].DurationFromYear ===
              todayDate.getFullYear()
              ? todayDate.getMonth() + 1 < value.DurationFromMonth
                ? "From Month should not greater than current calendar Month"
                : ""
              : recruitModal.employerDetails[idx - 1] != undefined
              ? recruitModal.employerDetails[idx - 1].DurationToYear ===
                  recruitModal.employerDetails[idx].DurationFromYear &&
                recruitModal.employerDetails[idx - 1].DurationToMonth >
                  value.DurationFromMonth
                ? "From Month should greater than previous companies To Month"
                : recruitModal.employerDetails[idx].DurationFromYear ===
                    recruitModal.employerDetails[idx].DurationToYear &&
                  value.DurationFromMonth >=
                    recruitModal.employerDetails[idx].DurationToMonth
                ? "From Month should less than To Month"
                : ""
              : ""
            : "Please select From Month";

        break;

      case "toMonth-" + idx:
        recruitModal.employerDetails[idx].DurationToMonth =
          value.DurationToMonth;
        if (
          recruitModal.employerDetails[idx].DurationFromMonth > 0 &&
          recruitModal.employerDetails[idx].DurationFromYear > 0 &&
          recruitModal.employerDetails[idx].DurationToMonth > 0 &&
          recruitModal.employerDetails[idx].DurationToYear > 0
        ) {
          recruitModal.employerDetails[idx].ShowExperience = true;
        } else {
          recruitModal.employerDetails[idx].ShowExperience = false;
        }

        recruitFormErrors.employerDetailsError[idx].durationToMonth =
          value.DurationToMonth > 0
            ? recruitModal.employerDetails[idx].DurationToYear ===
              todayDate.getFullYear()
              ? todayDate.getMonth() + 1 < value.DurationToMonth
                ? "To Month should less than current calendar Month"
                : ""
              : recruitModal.employerDetails[idx - 1] != undefined
              ? recruitModal.employerDetails[idx - 1].DurationToYear ===
                  recruitModal.employerDetails[idx].DurationFromYear &&
                recruitModal.employerDetails[idx].DurationFromMonth <
                  recruitModal.employerDetails[idx - 1].DurationToMonth
                ? "From Month should greater than previous companies To Month"
                : recruitModal.employerDetails[idx].DurationFromYear ===
                    recruitModal.employerDetails[idx].DurationToYear &&
                  recruitModal.employerDetails[idx].DurationFromMonth >=
                    value.DurationToMonth
                ? "To Month should greater than From Month"
                : recruitModal.employerDetails[idx + 1] != undefined
                ? recruitModal.employerDetails[idx + 1].DurationFromYear ===
                    recruitModal.employerDetails[idx].DurationToYear &&
                  recruitModal.employerDetails[idx + 1].DurationFromMonth <
                    recruitModal.employerDetails[idx].DurationToMonth
                  ? "To Month should less than next companies From Month"
                  : ""
                : ""
              : recruitModal.employerDetails[idx + 1] != undefined
              ? recruitModal.employerDetails[idx + 1].DurationFromYear ===
                  recruitModal.employerDetails[idx].DurationToYear &&
                recruitModal.employerDetails[idx + 1].DurationFromMonth <
                  recruitModal.employerDetails[idx].DurationToMonth
                ? "To Month should less than next companies From Month"
                : ""
              : ""
            : "Please select To Month";
        break;

      case "toYear-" + idx:
        recruitModal.employerDetails[idx].DurationToYear = value.DurationToYear;
        if (
          recruitModal.employerDetails[idx].DurationFromMonth > 0 &&
          recruitModal.employerDetails[idx].DurationFromYear > 0 &&
          recruitModal.employerDetails[idx].DurationToMonth > 0 &&
          recruitModal.employerDetails[idx].DurationToYear > 0
        ) {
          recruitModal.employerDetails[idx].ShowExperience = true;
        } else {
          recruitModal.employerDetails[idx].ShowExperience = false;
        }

        recruitFormErrors.employerDetailsError[idx].durationFromYear =
          recruitModal.employerDetails[idx].DurationFromYear > 0
            ? recruitModal.employerDetails[idx].DurationFromYear <=
              value.DurationToYear
              ? recruitModal.employerDetails[idx - 1] != undefined
                ? recruitModal.employerDetails[idx - 1].DurationToYear >
                  recruitModal.employerDetails[idx].DurationFromYear
                  ? "From Year should greater than previous companies To Year"
                  : ""
                : ""
              : "From Year should less than To Year"
            : "Please select From Year";

        recruitFormErrors.employerDetailsError[idx].durationToYear =
          value.DurationToYear > 0
            ? value.DurationToYear >=
              recruitModal.employerDetails[idx].DurationFromYear
              ? recruitModal.employerDetails[idx + 1] != undefined
                ? recruitModal.employerDetails[idx].DurationToMonth === 12 &&
                  recruitModal.employerDetails[idx].DurationToYear >=
                    recruitModal.employerDetails[idx + 1].DurationFromYear
                  ? "To Year should less than next companies From Year"
                  : recruitModal.employerDetails[idx].DurationToYear >
                    recruitModal.employerDetails[idx + 1].DurationFromYear
                  ? "To Year should less than next companies From Year"
                  : ""
                : ""
              : "To Year should greater than From Year"
            : "Please select To Year";

        recruitFormErrors.employerDetailsError[idx].durationToMonth =
          recruitModal.employerDetails[idx].DurationToMonth > 0
            ? recruitModal.employerDetails[idx].DurationToYear ===
              todayDate.getFullYear()
              ? todayDate.getMonth() + 1 <
                recruitModal.employerDetails[idx].DurationToMonth
                ? "To Month should less than current calendar Month"
                : ""
              : recruitModal.employerDetails[idx].DurationFromMonth >
                recruitModal.employerDetails[idx].DurationToMonth
              ? recruitModal.employerDetails[idx].DurationFromYear <
                recruitModal.employerDetails[idx].DurationToYear
                ? ""
                : "To Month should greater than From Month"
              : ""
            : "Please select To Month";
        break;
    }
    this.setState(
      {
        recruitFormErrors,
        recruitModal,
      },
      () => this.setExperience(name),
    );
  };

  setExperience = (name) => {
    const recruitModal = this.state.recruitModal;
    var idx = name.split("-")[1];
    var FromDate =
      "01/" +
      recruitModal.employerDetails[idx].DurationFromMonth +
      "/" +
      recruitModal.employerDetails[idx].DurationFromYear;

    var ToDate =
      "01/" +
      recruitModal.employerDetails[idx].DurationToMonth +
      "/" +
      recruitModal.employerDetails[idx].DurationToYear;

    var df = new Date(FromDate);
    var dt = new Date(ToDate);
    var startMonth =
      df.getFullYear() * 12 +
      recruitModal.employerDetails[idx].DurationFromMonth;
    var endMonth =
      dt.getFullYear() * 12 + recruitModal.employerDetails[idx].DurationToMonth;
    var monthInterval = endMonth - startMonth;
    var yearsOfExperience = Math.floor(monthInterval / 12);
    var monthsOfExperience = monthInterval % 12;

    yearsOfExperience >= 0 && monthsOfExperience >= 0
      ? (recruitModal.employerDetails[idx].Experience = recruitModal
          .employerDetails[idx].ShowExperience
          ? yearsOfExperience +
            "." +
            (monthsOfExperience >= 10
              ? monthsOfExperience
              : "0" + monthsOfExperience) +
            ""
          : "")
      : (recruitModal.employerDetails[idx].Experience = "");
    this.setState({ recruitModal });
  };

  handleAddEmployerRow = () => {
    if (employerFormValid(this.state)) {
      const recruitFormErrors = this.state.recruitFormErrors;
      const recruitModal = this.state.recruitModal;
      recruitModal.employerDetails = [
        ...recruitModal.employerDetails,
        {
          companyName: "",
          DurationFromYear: 0,
          DurationFromMonth: 0,
          DurationToYear: 0,
          DurationToMonth: 0,
          Experience: "",
          ShowExperience: false,
        },
      ];
      recruitFormErrors.employerDetailsError = [
        ...recruitFormErrors.employerDetailsError,
        {
          companyName: "",
          durationFromYear: 0,
          durationFromMonth: 0,
          durationToYear: 0,
          durationToMonth: 0,
        },
      ];
      this.setState({
        recruitFormErrors,
        recruitModal,
      });
    } else {
      this.setState(
        {
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: CompnayErrorMessage,
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        },
      );
    }
  };

  handleRemoveEmployerRow = (idx) => () => {
    const { employerDetails } = this.state.recruitModal;
    const { employerDetailsError } = this.state.recruitFormErrors;
    employerDetails.splice(idx, 1);
    employerDetailsError.splice(idx, 1);
    this.setState({
      employerDetailsError,
      employerDetails,
    });
  };

  resetForm = () => {
    this.setState(
      {
        recruitModal: {
          addNewReference: false,
          TechnologyMasterID: 0,
          Experienced: false,
          FirstName: "",
          LastName: "",
          Email: "",
          Mobile: "",
          CandidateImage: "",
          PhotoName: "",
          ReferenceMasterID: 0,
          ReferenceName: "",
          HighestQualification: "",
          ExtraCertification: "",
          CurrentSalary: "",
          ExpectedSalary: "",
          Reconsider: false,
          NoticePeriod: "",
          Resume: "",
          ResumeFileName: "",
          SuitableDate: new Date(),
          SuitableTime: new Date(),
          DateOfJoining: "",
          Reason: "",
          CreatedDate: new Date(),
          resumeData: "",
          technologies: [
            { technology: "", ExperienceInMonth: -1, ExperienceInYear: -1 },
          ],
          employerDetails: [
            {
              companyName: "",
              DurationFromYear: 0,
              DurationFromMonth: 0,
              DurationToYear: 0,
              DurationToMonth: 0,
            },
          ],
        },
      },
      () => {
        window.setTimeout(() => {
          this.props.history.push("/loading");
          this.props.history.push("/recruitportal/add");
        }, 500);
      },
    );
  };

  hideReferenceName = () => {
    const recruitModal = this.state.recruitModal;

    recruitModal.addNewReference = false;
    this.setState({
      recruitModal,
      referenceDropdown: this.state.tempReferenceData,
    });
  };

  showNotification(state) {
    new Noty({
      type: state.alertColor,
      theme: "bootstrap-v4",
      layout: "topRight",
      closeWith: ["button"],
      text: state.alertMessage,
      timeout: state.notyTimeOut,
    }).show();
  }

  //Added By Tarangini Alva 01.01.2020 from Recruit Image cropping
  setCropInit = () => {
    var { crop } = this.state;
    crop.unit = "px";
    crop.width = 230;
    crop.height = 230;
    crop.aspect = 1 / 1;
    this.setState({
      crop,
    });
  };

  toggleDialog = () => {
    var { recruitModal } = this.state;
    if (recruitModal.CandidateImageBase64 != "") {
      recruitModal.CandidateImage = recruitModal.CandidateImageBase64;
    }

    this.setState({
      visibleDialog: !this.state.visibleDialog,
      //recruitModal
    });
  };

  toggleDialogClose = () => {
    var { recruitModal } = this.state;
    recruitModal.CandidateImage = "";

    this.setState({
      Cropsrc: "",
      visibleDialog: !this.state.visibleDialog,
      recruitModal,
    });
  };

  getSelectedImageBase64 = (base64Image) => {
    var { recruitModal } = this.state;
    recruitModal.CandidateImageBase64 = base64Image;

    this.setState({
      recruitModal,
      // IsProfilePicValid: true
    });
  };

  rotateBase64Image90deg = (isClockwise) => {
    this.setState({ rotateFlag: false });
    var base64Image = this.state.Cropsrc;
    // create an off-screen canvas

    // cteate Image
    var img = new Image();
    img.src = base64Image;
    img.onload = function () {
      var offScreenCanvas = document.createElement("canvas");
      var offScreenCanvasCtx = offScreenCanvas.getContext("2d");
      // set its dimension to rotated size
      offScreenCanvas.height = img.width;
      offScreenCanvas.width = img.height;

      // rotate and draw source image into the off-screen canvas:
      if (isClockwise) {
        offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
        offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
      }

      offScreenCanvasCtx.drawImage(img, 0, 0);

      // encode image to data-uri with base64
      const data = offScreenCanvas.toDataURL();
      this.setState({
        Cropsrc: data,
      });
    }.bind(this);
  };

  //Added By Tarangini Alva 01.01.2020 from Recruit Image cropping

  render() {
    const videoConstraints = {
      width: 230,
      height: 230,
      facingMode: "user",
    };
    const recruitFormErrors = this.state.recruitFormErrors;
    const recruitModal = this.state.recruitModal;

    const recruiteForm = (
      <Form id="recruitFormID" noValidate>
        <div className="row">
          <div className="persondetailwrap">
            {this.state.isWebCamOpen ? (
              <div className="userpic_box">
                <Webcam
                  audio={false}
                  height={230}
                  ref={this.setRef}
                  screenshotFormat="image/jpeg"
                  width={230}
                  videoConstraints={videoConstraints}
                />
                <a
                  className="camera_icon"
                  onClick={this.capture}
                  title="Capture Image"
                />
                <div className="uploadbut">
                  <input
                    type="file"
                    className="picupload"
                    id="photoUpload"
                    title="Upload Image"
                    name="photoUpload"
                    value=""
                    accept=".jpeg, .jpg, .png"
                    onChange={(e) => this._handleImageChange(e)}
                  />
                </div>
                <small className="align_right bot">
                  Please upload .jpeg, .jpg, .png file upto 2 MB
                </small>
              </div>
            ) : (
              <div className="userpic_box">
                <img
                  style={{ height: "230px", width: "230px" }}
                  src={
                    recruitModal.CandidateImage === ""
                      ? noImage
                      : recruitModal.CandidateImage
                  }
                  alt=""
                />
                <a
                  className="camera_icon"
                  onClick={this.openWebCam}
                  title="Capture Image"
                ></a>
                <div className="uploadbut">
                  <input
                    type="file"
                    className="picupload"
                    id="photoUpload"
                    title="Upload Image"
                    name="photoUpload"
                    value=""
                    accept=".jpeg, .jpg, .png"
                    onChange={(e) => this._handleImageChange(e)}
                  />
                </div>
                <small className="align_right bot">
                  Please upload .jpeg, .jpg, .png file upto 2 MB
                </small>
              </div>
            )}

            <div className="personal_from">
              <div className="col-md-12">
                <h3>Personal Details</h3>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-lg-4">
                  <div
                    className={
                      recruitFormErrors.TechnologyMasterID.length > 0
                        ? "field_text fieldErrorMessage"
                        : "field_text"
                    }
                  >
                    <DropDownList
                      className="selectbox"
                      name="mainTechnology"
                      data={this.state.technologyDropdown}
                      textField="Description"
                      dataItemKey="TechnologyMasterID"
                      defaultItem={this.defaultTechnologyItem}
                      value={recruitModal.Description}
                      onChange={this.handleChange}
                      filterable={true}
                      onFilterChange={this.filterChangeTechnology}
                      loading={this.state.loading}
                    />
                    <span className="star">*</span>
                  </div>
                  {recruitFormErrors.TechnologyMasterID.length > 0 && (
                    <span className="errorMessage">
                      {recruitFormErrors.TechnologyMasterID}
                    </span>
                  )}
                </div>
                <div className="col-xs-12 col-sm-6 col-lg-4">
                  <div
                    className={
                      recruitFormErrors.FirstName.length > 0
                        ? "field_text fieldErrorMessage"
                        : "field_text"
                    }
                  >
                    <Input
                      type="text"
                      id="firstname"
                      name="firstname"
                      placeholder="First Name"
                      maxLength="150"
                      noValidate
                      value={recruitModal.FirstName}
                      onChange={this.handleChange}
                    />
                    <span className="star">*</span>
                  </div>
                  {recruitFormErrors.FirstName.length > 0 && (
                    <span className="errorMessage">
                      {recruitFormErrors.FirstName}
                    </span>
                  )}
                </div>
                <div className="col-xs-12 col-sm-6 col-lg-4">
                  <div
                    className={
                      recruitFormErrors.LastName.length > 0
                        ? "field_text fieldErrorMessage"
                        : "field_text"
                    }
                  >
                    <Input
                      type="text"
                      id="lastname"
                      name="lastname"
                      placeholder="Last Name"
                      maxLength="150"
                      noValidate
                      value={recruitModal.LastName}
                      onChange={this.handleChange}
                    />
                    <span className="star">*</span>
                  </div>
                  {recruitFormErrors.LastName.length > 0 && (
                    <span className="errorMessage">
                      {recruitFormErrors.LastName}
                    </span>
                  )}
                </div>
                <div className="col-xs-12 col-sm-6 col-lg-4">
                  <div
                    className={
                      recruitFormErrors.Email.length > 0
                        ? "field_text fieldErrorMessage"
                        : "field_text"
                    }
                  >
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      maxLength="320"
                      noValidate
                      value={recruitModal.Email}
                      onChange={this.handleChange}
                    />
                    <span className="star">*</span>
                  </div>
                  {recruitFormErrors.Email.length > 0 && (
                    <span className="errorMessage">
                      {recruitFormErrors.Email}
                    </span>
                  )}
                </div>
                <div className="col-xs-12 col-sm-6 col-lg-4">
                  <div
                    className={
                      recruitFormErrors.Mobile.length > 0
                        ? "field_text fieldErrorMessage"
                        : "field_text"
                    }
                  >
                    <Input
                      type="text"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile No."
                      minLength="10"
                      maxLength="15"
                      noValidate
                      value={recruitModal.Mobile}
                      onChange={this.handleChange}
                    />
                    <span className="star">*</span>
                  </div>
                  {recruitFormErrors.Mobile.length > 0 && (
                    <span className="errorMessage">
                      {recruitFormErrors.Mobile}
                    </span>
                  )}
                </div>
                {this.state.recruitModal.addNewReference ? (
                  <React.Fragment>
                    <div className="col-md-3">
                      <div
                        className={
                          recruitFormErrors.ReferenceName.length > 0
                            ? "field_text fieldErrorMessage"
                            : "field_text"
                        }
                      >
                        <Input
                          type="text"
                          id="referenceName"
                          name="referenceName"
                          placeholder="Reference Name"
                          maxLength="150"
                          noValidate
                          value={recruitModal.ReferenceName}
                          onChange={this.handleChange}
                        />
                      </div>
                      {recruitFormErrors.ReferenceName.length > 0 && (
                        <span className="errorMessage">
                          {recruitFormErrors.ReferenceName}
                        </span>
                      )}
                    </div>
                    <div className="col-md-1">
                      <div className="field_text">
                        <button
                          style={{
                            padding: "8px 10px",
                            margin: "0 0 0 0",
                          }}
                          className="cancel changebtn"
                          type="button"
                          onClick={this.hideReferenceName}
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="col-xs-12 col-sm-6 col-lg-4">
                    <div className="field_text">
                      <DropDownList
                        className="selectbox"
                        name="reference"
                        data={this.state.referenceDropdown}
                        textField="Description"
                        dataItemKey="ReferenceMasterID"
                        defaultItem={this.defaultReferenceItem}
                        value={recruitModal.Reference}
                        onChange={this.handleChange}
                        filterable={true}
                        onFilterChange={this.filterChangeReference}
                        loading={this.state.loading}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h3>Qualification Details</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className={
                recruitFormErrors.HighestQualification.length > 0
                  ? "field_text fieldErrorMessage"
                  : "field_text"
              }
            >
              <Input
                type="text"
                id="highestQualification"
                name="highestQualification"
                className="form-control"
                placeholder="Highest Qualification"
                noValidate
                value={recruitModal.HighestQualification}
                onChange={this.handleChange}
              />
              <span className="star">*</span>
            </div>
            {recruitFormErrors.HighestQualification.length > 0 && (
              <span className="errorMessage">
                {recruitFormErrors.HighestQualification}
              </span>
            )}
          </div>
          <div className="col-md-8">
            <div
              className={
                recruitFormErrors.ExtraCertification.length > 0
                  ? "field_text fieldErrorMessage"
                  : "field_text"
              }
            >
              <Input
                type="text"
                id="extraCertification"
                name="extraCertification"
                className="form-control"
                placeholder="Extra Certification"
                noValidate
                value={recruitModal.ExtraCertification}
                onChange={this.handleChange}
              />
            </div>
            {recruitFormErrors.ExtraCertification.length > 0 && (
              <span className="errorMessage">
                {recruitFormErrors.ExtraCertification}
              </span>
            )}
          </div>
          {/* <div className="note">(e.g. 6 months)</div> */}
        </div>

        <div className="row">
          <div className="col-md-12">
            <h3>
              Technical Skils
              <small
                className="note_message"
                style={{
                  textTransform: "none",
                  fontSize: "14px !important",
                }}
              >
                Click on the "+" icon on the right to add more details.
              </small>
            </h3>
            <a
              className="glyphicon-plus glyphicon"
              style={{
                height: "23px",
                float: "right",
                margin: "72px 0 0 0",
                cursor: "pointer",
              }}
              onClick={this.handleAddTechnologyRow}
            ></a>
          </div>
        </div>
        {recruitModal.technologies.map((item, idx) => (
          <div className="row" id={"technology-" + idx} key={idx}>
            <div className="col-md-3">
              <div className="field_text">
                <Input
                  type="text"
                  data-id={idx}
                  id={"technology-" + idx}
                  name={"technology-" + idx}
                  className="form-control"
                  placeholder="Technology"
                  maxLength="50"
                  noValidate
                  value={recruitModal.technologies[idx].technology}
                  onChange={this.handleTechnologyChange(idx)}
                />
              </div>
              {recruitFormErrors.technologiesError[idx].technology === undefined
                ? ""
                : recruitFormErrors.technologiesError[idx].technology.length >
                    0 && (
                    <span className="errorMessage">
                      {recruitFormErrors.technologiesError[idx].technology}
                    </span>
                  )}
            </div>
            <div className="col-md-2">
              <div className="field_text">
                <DropDownList
                  className="selectbox"
                  data-id={idx}
                  name={"experienceInYear-" + idx}
                  data={this.years}
                  textField="Value"
                  dataItemKey="ExperienceInYear"
                  defaultItem={this.defaultTechnologyYear}
                  value={recruitModal.technologies[idx].experienceInYear}
                  onChange={this.handleTechnologyChange(idx)}
                />
              </div>
              {recruitFormErrors.technologiesError[idx].experienceInYear ===
              undefined
                ? ""
                : recruitFormErrors.technologiesError[idx].experienceInYear
                    .length > 0 && (
                    <span className="errorMessage">
                      {
                        recruitFormErrors.technologiesError[idx]
                          .experienceInYear
                      }
                    </span>
                  )}
            </div>
            <div className="col-md-2">
              <div className="field_text">
                <DropDownList
                  className="selectbox"
                  data-id={idx}
                  name={"experienceInMonth-" + idx}
                  data={this.months}
                  textField="Value"
                  dataItemKey="ExperienceInMonth"
                  defaultItem={this.defaultTechnologyMonth}
                  value={recruitModal.technologies[idx].experienceInMonth}
                  onChange={this.handleTechnologyChange(idx)}
                />
              </div>
              {recruitFormErrors.technologiesError[idx].experienceInMonth ===
              undefined
                ? ""
                : recruitFormErrors.technologiesError[idx].experienceInMonth
                    .length > 0 && (
                    <span className="errorMessage">
                      {
                        recruitFormErrors.technologiesError[idx]
                          .experienceInMonth
                      }
                    </span>
                  )}
            </div>
            {idx === 0 ? (
              ""
            ) : (
              <a
                className="glyphicon-minus glyphicon"
                onClick={this.handleRemoveTechnologyRow(idx)}
              ></a>
            )}
          </div>
        ))}

        <div className="row">
          <div className="col-md-12">
            <h3>
              Employment Details
              <small
                className="note_message"
                style={{
                  textTransform: "none",
                  fontSize: "14px !important",
                }}
              >
                Click on "+" icon on the right to add more details, starting
                from the First Job to the Current one.
              </small>
            </h3>
            <a
              className="glyphicon-plus glyphicon"
              style={{
                height: "23px",
                float: "right",
                margin: "72px 0 0 0",
                cursor: "pointer",
              }}
              onClick={this.handleAddEmployerRow}
            ></a>
          </div>
        </div>
        {recruitModal.employerDetails.map((item, idx) => (
          <div className="row" id={"employment-" + idx} key={idx}>
            <div className="col-sm-12 col-lg-3">
              <div className="field_text">
                <Input
                  type="text"
                  data-id={idx}
                  id={"companyName-" + idx}
                  name={"companyName-" + idx}
                  className="form-control"
                  placeholder="Company Name"
                  maxLength="200"
                  noValidate
                  value={recruitModal.employerDetails[idx].companyName}
                  onChange={this.handleEmployerChange(idx)}
                />
              </div>
              {recruitFormErrors.employerDetailsError[idx].companyName ===
              undefined
                ? ""
                : recruitFormErrors.employerDetailsError[idx].companyName
                    .length > 0 && (
                    <span className="errorMessage">
                      {recruitFormErrors.employerDetailsError[idx].companyName}
                    </span>
                  )}
            </div>
            <div className="col-sm-3 col-lg-2">
              <div className="field_text">
                <DropDownList
                  className="selectbox"
                  data-id={idx}
                  name={"fromMonth-" + idx}
                  data={this.fromMonths}
                  textField="Value"
                  dataItemKey="DurationFromMonth"
                  defaultItem={this.defaultCompanyFromMonth}
                  value={recruitModal.employerDetails[idx].durationFromMonth}
                  onChange={this.handleEmployerChange(idx)}
                />
              </div>
              {recruitFormErrors.employerDetailsError[idx].durationFromMonth ===
              undefined
                ? ""
                : recruitFormErrors.employerDetailsError[idx].durationFromMonth
                    .length > 0 && (
                    <span className="errorMessage">
                      {
                        recruitFormErrors.employerDetailsError[idx]
                          .durationFromMonth
                      }
                    </span>
                  )}
            </div>
            <div className="col-sm-3 col-lg-2">
              <div className="field_text">
                <DropDownList
                  className="selectbox"
                  data-id={idx}
                  name={"fromYear-" + idx}
                  data={this.state.fromYears}
                  textField="Value"
                  dataItemKey="DurationFromYear"
                  defaultItem={this.defaultCompanyFromYear}
                  value={recruitModal.employerDetails[idx].durationFromYear}
                  onChange={this.handleEmployerChange(idx)}
                />
              </div>
              {recruitFormErrors.employerDetailsError[idx].durationFromYear ===
              undefined
                ? ""
                : recruitFormErrors.employerDetailsError[idx].durationFromYear
                    .length > 0 && (
                    <span className="errorMessage">
                      {
                        recruitFormErrors.employerDetailsError[idx]
                          .durationFromYear
                      }
                    </span>
                  )}
            </div>
            <div className="col-sm-3 col-lg-2">
              <div className="field_text">
                <DropDownList
                  className="selectbox"
                  data-id={idx}
                  name={"toMonth-" + idx}
                  data={this.toMonths}
                  textField="Value"
                  dataItemKey="DurationToMonth"
                  defaultItem={this.defaultCompanyToMonth}
                  value={recruitModal.employerDetails[idx].durationToMonth}
                  onChange={this.handleEmployerChange(idx)}
                />
              </div>
              {recruitFormErrors.employerDetailsError[idx].durationToMonth ===
              undefined
                ? ""
                : recruitFormErrors.employerDetailsError[idx].durationToMonth
                    .length > 0 && (
                    <span className="errorMessage">
                      {
                        recruitFormErrors.employerDetailsError[idx]
                          .durationToMonth
                      }
                    </span>
                  )}
            </div>
            <div className="col-sm-3 col-lg-2">
              <div className="field_text">
                <DropDownList
                  className="selectbox"
                  data-id={idx}
                  name={"toYear-" + idx}
                  data={this.state.toYears}
                  textField="Value"
                  dataItemKey="DurationToYear"
                  defaultItem={this.defaultCompanyToYear}
                  value={recruitModal.employerDetails[idx].durationToYear}
                  onChange={this.handleEmployerChange(idx)}
                />
              </div>
              {recruitFormErrors.employerDetailsError[idx].durationToYear ===
              undefined
                ? ""
                : recruitFormErrors.employerDetailsError[idx].durationToYear
                    .length > 0 && (
                    <span className="errorMessage">
                      {
                        recruitFormErrors.employerDetailsError[idx]
                          .durationToYear
                      }
                    </span>
                  )}
            </div>
            <div className="col-md-1">
              <Label style={{ marginTop: "-55px" }}>&nbsp;</Label>
              <div>
                <Label style={{ margin: "0px", fontSize: "12px" }}>
                  {recruitModal.employerDetails[idx].Experience}
                </Label>
              </div>
            </div>
            {idx === 0 ? (
              ""
            ) : (
              <a
                className="glyphicon-minus glyphicon"
                onClick={this.handleRemoveEmployerRow(idx)}
              ></a>
            )}
          </div>
        ))}

        <div className="row">
          <div className="col-md-12">
            <h3>Salary (INR Per month)</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className={
                recruitFormErrors.CurrentSalary.length > 0
                  ? "field_text fieldErrorMessage"
                  : "field_text"
              }
            >
              <Input
                type="text"
                id="currentSalary"
                name="currentSalary"
                className="form-control"
                placeholder="Current Salary per Month"
                maxLength="10"
                noValidate
                value={recruitModal.CurrentSalary}
                onChange={this.handleChange}
              />
              <span className="star">*</span>
            </div>
            {recruitFormErrors.CurrentSalary.length > 0 && (
              <span className="errorMessage">
                {recruitFormErrors.CurrentSalary}
              </span>
            )}
          </div>
          <div className="col-md-4">
            <div
              className={
                recruitFormErrors.ExpectedSalary.length > 0
                  ? "field_text fieldErrorMessage"
                  : "field_text"
              }
            >
              <Input
                type="text"
                id="expectedSalary"
                name="expectedSalary"
                className="form-control"
                placeholder="Expected Salary per Month"
                maxLength="10"
                noValidate
                value={recruitModal.ExpectedSalary}
                onChange={this.handleChange}
              />
              <span className="star">*</span>
            </div>
            {recruitFormErrors.ExpectedSalary.length > 0 && (
              <span className="errorMessage">
                {recruitFormErrors.ExpectedSalary}
              </span>
            )}
          </div>
          <div className="col-sm-12 col-lg-4 radio_btn_part">
            <div className="field_text">
              <span className="field_texttitle">Ready to Reconsider</span>
              <div className="radiobutreconsider">
                <div className="custom-control custom-radio">
                  <input
                    className="custom-control-input"
                    id="yes"
                    type="radio"
                    value={true}
                    name="reconsider"
                    onChange={this.handleChange}
                  />
                  <label className="custom-control-label" htmlFor="yes">
                    Yes
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    className="custom-control-input"
                    id="no"
                    type="radio"
                    value={false}
                    name="reconsider"
                    onChange={this.handleChange}
                  />
                  <label className="custom-control-label" htmlFor="no">
                    No
                  </label>
                </div>
              </div>
              <small className="align_right bot">
                Are you ready to negotiate, the mentioned expected salary?
              </small>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <h3>When can you join?</h3>
            <div className="col-md-12">
              <div
                className={
                  recruitFormErrors.NoticePeriodID.length > 0
                    ? "field_text fieldErrorMessage"
                    : "field_text"
                }
              >
                <DropDownList
                  className="selectbox"
                  name="noticePeriod"
                  data={this.state.noticePeriodDropdown}
                  textField="Description"
                  dataItemKey="NoticePeriodID"
                  defaultItem={this.defaultNoticePeriodItem}
                  value={recruitModal.Description}
                  onChange={this.handleChange}
                  filterable={true}
                  onFilterChange={this.filterChangeNoticePeriod}
                  loading={this.state.loading}
                />
                <span className="star">*</span>
              </div>
              {recruitFormErrors.NoticePeriodID.length > 0 && (
                <span className="errorMessage">
                  {recruitFormErrors.NoticePeriodID}
                </span>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <h3>Interview Schedule</h3>
            <div className="row">
              <div className="col-md-6">
                <small className="align_right bot labelclass">
                  Suitable Date
                </small>
                <div className="field_text">
                  <DatePicker
                    format={"dd/MM/yyyy"}
                    name="suitableDate"
                    min={new Date()}
                    defaultValue={new Date()}
                    value={recruitModal.SuitableDate}
                    onChange={this.handleChange}
                    width="96%"
                  />
                  <span className="star">*</span>
                </div>
                {recruitFormErrors.SuitableDate.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.SuitableDate}
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <div className="field_text date_timepicker">
                  {this.state.siteConfigData.isWalkingInterview ? (
                    <TimePicker
                      name="suitableTime"
                      steps={this.steps}
                      min={
                        new Date(
                          2100,
                          2,
                          10,
                          this.state.siteConfigData.suitableTimeStarting,
                          0,
                        )
                      }
                      max={
                        new Date(
                          2110,
                          2,
                          10,
                          this.state.siteConfigData.suitableTimeEnding - 1,
                          55,
                        )
                      }
                      defaultValue={new Date(2100, 2, 10, 10, 0)}
                      value={recruitModal.SuitableTime}
                      onChange={this.handleChange}
                      width="96%"
                    />
                  ) : (
                    <TimePicker
                      name="suitableTime"
                      steps={this.steps}
                      min={new Date(2100, 2, 10, 10, 0)}
                      max={new Date(2110, 2, 10, 16, 30)}
                      defaultValue={new Date()}
                      value={recruitModal.SuitableTime}
                      onChange={this.handleChange}
                      width="96%"
                    />
                  )}
                  <span className="star">*</span>
                  <small className="align_right bot">
                    (except 1:00 PM to 1:30 PM Lunch hours)
                  </small>
                </div>
                {recruitFormErrors.SuitableTime.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.SuitableTime}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h3>Resume</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="field_text">
              <div className="file_listbutwrap">
                <div className="file_listbut">
                  <input
                    type="file"
                    id="resumeFile"
                    name="resumeFile"
                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                    onChange={(e) => this._handleResumeChange(e)}
                  />
                </div>
                <div className="file_listbox">
                  {this.state.recruitModal.ResumeFileName != ""
                    ? this.state.recruitModal.ResumeFileName
                    : "Upload Resume"}
                </div>
                <small className="align_right bot">
                  Please upload .doc, .docx, .pdf file upto 2 MB
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="captcha_code">
              <Recaptcha
                sitekey={CaptchaKeyV2}
                render="explicit"
                onloadCallback={this.recaptchaLoaded}
                verifyCallback={this.verifyCallback}
              />
            </div>
          </div>
          <div className="col-md-12 mb-4 submitbutwrap">
            {this.state.isLoading ? (
              <React.Fragment>
                <button className="submit" type="button" disabled="disabled">
                  Submit
                </button>
                <button className="cancel" type="button" disabled="disabled">
                  Reset
                </button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <button
                  className="submit"
                  type="button"
                  onClick={this.handleRecruitSubmit}
                >
                  Submit
                </button>
                <button
                  className="cancel"
                  type="button"
                  onClick={this.resetForm}
                >
                  Reset
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </Form>
    );

    const ReactCropImage = (
      <div className="cropImage">
        {this.state.Cropsrc && this.state.rotateFlag && (
          <CommonImageCrop
            src={this.state.Cropsrc}
            getSelectedImageBase64={this.getSelectedImageBase64}
          />
        )}
      </div>
    );
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
          </div>
        ) : (
          ""
        )}

        {/* {this.state.showAlertMessage ? (
          <div>
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlertMessage}
              toggle={this.onDismiss}
            >
              {this.state.alertMessage}
            </Alert>
          </div>
        ) : null} */}

        <div className="mainbody">
          <div className="career_form">
            <div className="container">
              <div className="career_formwrap">
                <h1>Apply for</h1>
                {ReactCropImage && this.state.visibleDialog && (
                  <Dialog
                    title={"Crop the Profile Picture"}
                    onClose={this.toggleDialogClose}
                  >
                    {ReactCropImage}
                    <DialogActionsBar>
                      {this.state.IsIOS ? (
                        <button
                          className="m-2 btn btn-danger"
                          onClick={this.rotateBase64Image90deg}
                        >
                          Rotate
                        </button>
                      ) : (
                        ""
                      )}

                      <button
                        className="m-2 btn btn-danger"
                        onClick={this.toggleDialog}
                      >
                        Crop
                      </button>
                    </DialogActionsBar>
                  </Dialog>
                )}
                {recruiteForm}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddNewRecruit;
