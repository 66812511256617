import React, { Component } from "react";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <main role="main" className="container">
          <div className="starter-template">
            <h1 className="error404">404</h1>
            <h3 className="pagenotfound">Page Not Found</h3>
            <div className="errorDiv">
              Sorry, we couldn't find the page you're looking for.
            </div>
            <div className="backtoHome mt-4">
              {localStorage.getItem("userToken") ? (
                <a href="/#/recruitportal/dashboard">Back To Dashboard</a>
              ) : (
                <a href="/#/recruitportal">Back To Dashboard</a>
              )}
            </div>
            <div className="pagenotfoundContact">
              Can't find what you want? Just feel free to{" "}
              <a
                title="CONTACT US"
                href="https://www.conceptinfoway.net/contact-us/"
              >
                contact us
              </a>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default NotFound;
