import React, { Component } from "react";
import { decryptKey } from "../CommonFunctions/CommonValues";
import Cryptr from "cryptr";
const cryptr = new Cryptr(decryptKey);

class RedirectTo extends Component {
  constructor(props) {
    super(props);
    let RecruitMasterID = props.match.params.id;
    RecruitMasterID = RecruitMasterID.replace(":", "");
    this.props.history.push(
      "/recruitportal/editrecruit:" + cryptr.encrypt(RecruitMasterID)
    );
  }
  render() {
    return <div></div>;
  }
}

export default RedirectTo;
