import React, { Component } from "react";
import logo from "../Images/CIPL-Logo-header.svg";
import $ from "jquery";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setMobileMenu: false
    };
  }
  async componentDidMount() {

    $(".menu-toggle").click(function(){
      $("body").addClass("menu-open");
    });
    $(".closemenu").click(function(){
      $("body").removeClass("menu-open");
    });
  }

  showMenuInMobile = () => {
    if (this.state.setMobileMenu) {
      this.setState({ setMobileMenu: false });
    } else {
      this.setState({ setMobileMenu: true });
    }
  };

  render() {
    return (
      <React.Fragment>
        <header className="header_wrap">
          
          <div className="header-wrap">
              <div className="container">
                <div className="header-main-wrap">
                  <div className="header-left">
                    <div className="logo">
                      <a href="http://conceptinfoway.com" className="headerlogo" target="_blank">
                        <img src={logo} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="header-right">
                    <div className="header-right-top desk">
                      <a className="email" href="mailto:hr@conceptinfoway.com">
                        hr@conceptinfoway.com
                      </a>
                      <a className="phone" href="tel:+91 99789 31415">+91 99789 31415</a>
                      <a className="phone" href="tel:+91 79 26872057">+91 79 26872057 </a>
                    </div>
                    <div className="header-right-btm">
                      <div className="mobilemenu">
                      <a class="menu-toggle" href="#">X</a>
                      </div>
                      <div className="responsive_hamburger_container mobile-menu">
                        <div className="responsive_menuinner">
                          <div className="responsive_menuinnerwrap">
                          <div className="mobile_header_box">
                            <div className="menumobilewrapper">
                            <a href="http://conceptinfoway.com" className="headerlogo" target="_blank">
                              <img src={logo} alt="" />
                            </a>
                            <a className="menu-toggle closemenu" href="#"></a>
                            </div>
                            <div className="menu-mobile-menu-container">
                            <ul className="menu mobmenu-list">
                      <li className="menu-item">
                        <a className="mega-menu-link"
                          href="https://www.conceptinfoway.net/company/"
                          target="_blank"
                        >
                          Company
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="mega-menu-link"
                          href="https://www.conceptinfoway.net/company/meet-our-team/"
                          target="_blank"
                        >
                          Meet our team
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="mega-menu-link"
                          href="https://www.conceptinfoway.net/company/infrastructure/"
                          target="_blank"
                        >
                           Infrastructure
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="mega-menu-link"
                          href="https://www.conceptinfoway.net/company/photo-gallery/"
                          target="_blank"
                        >
                          Life at Concept
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="mega-menu-link"
                          href="https://www.conceptinfoway.net/company/career/"
                          target="_blank"
                        >
                          Current openings
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="mega-menu-link"
                          href="https://www.conceptinfoway.net/contact-us/"
                          target="_blank"
                        >
                           Contact us
                          
                        </a>
                      </li>
                    </ul>
                    <div className="header-right-top mob">
                      <a className="email" href="mailto:hr@conceptinfoway.com">
                        hr@conceptinfoway.com
                      </a>
                      <a className="phone" href="tel:+91 99789 31415">+91 99789 31415</a>
                      <a className="phone" href="tel:+91 79 26872057">+91 79 26872057 </a>
                    </div>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-menu deskmenu">
                      <ul className="menu ">
                      <li className="menu-item">
                        <a className="mega-menu-link"
                          href="https://www.conceptinfoway.net/company/"
                          target="_blank"
                        >
                          Company
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="mega-menu-link"
                          href="https://www.conceptinfoway.net/company/meet-our-team/"
                          target="_blank"
                        >
                          Meet our team
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="mega-menu-link"
                          href="https://www.conceptinfoway.net/company/infrastructure/"
                          target="_blank"
                        >
                           Infrastructure
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="mega-menu-link"
                          href="https://www.conceptinfoway.net/company/photo-gallery/"
                          target="_blank"
                        >
                          Life at Concept
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="mega-menu-link"
                          href="https://www.conceptinfoway.net/company/career/"
                          target="_blank"
                        >
                          Current openings
                        </a>
                      </li>
                      <li className="menu-item">
                        <a className="mega-menu-link"
                          href="https://www.conceptinfoway.net/contact-us/"
                          target="_blank"
                        >
                           Contact us
                          
                        </a>
                      </li>
                    </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
