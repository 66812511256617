import React, { Component } from "react";
import "../css/form.css";
import "bootstrap/dist/css/bootstrap.min.css";
import thankyou from "../Images/Thankyou-Bg.png";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { decryptKey } from "../CommonFunctions/CommonValues";
import Cryptr from "cryptr";
// import Moment from "react-moment";

const cryptr = new Cryptr(decryptKey);
class ThankYou extends Component {
  constructor(props) {
    super(props);
    let CandidateNumber = props.match.params.id;
    CandidateNumber = CandidateNumber.replace(":", "");
    this.state = {
      setMobileMenu: false,
      CandidateNumber: cryptr.decrypt(CandidateNumber)
    };
  }

  showMenuInMobile = () => {
    if (this.state.setMobileMenu) {
      this.setState({ setMobileMenu: false });
    } else {
      this.setState({ setMobileMenu: true });
    }
  };

  backToHome = () => {
    this.props.history.push("/");
  };
  render() {
    return (
      <React.Fragment>
        <Header></Header>
        <div className="bannerpart thankyoupage"></div>

        <div
          className="career_form"
          style={{
            margin: 0,
            paddingTop: "39px",
            paddingBottom: "31px",
            backgroundImage: { thankyou }
          }}
        >
          <div className="container">
            <div className="starter-template" style={{ padding: "0" }}>
              <h1 className="h3 mb-3 font-weight-normal">
                Thank you for your application. <br></br>Your application
                reference # is {this.state.CandidateNumber}.
              </h1>
              <h3
                className="h3 mb-3 font-weight-normal"
                style={{ textTransform: "none" }}
              >
                To give you an insight, the interview generally comprises of the
                below mentioned three rounds; subject to the vacancy.
              </h3>
              <div className="career_descpart">
                <ul>
                  <li className="title_name">1. Technical Round</li>
                  <li className="title_description">
                    • It will be a verbal round of interview, primarily focused
                    towards reviewing and gauging the technical knowledge.
                  </li>
                  <li className="title_name">2. Practical Round</li>
                  <li className="title_description">
                    • The focus here will be to evaluate your practical
                    knowledge of the domain. Here, we will provide you a
                    platform to showcase your skills on a real-time scenario
                    basis, which will further help us to evaluate that your
                    practical knowledge matches in-line with your theoretical
                    knowledge.
                  </li>
                  <li className="title_name">3. HR Round</li>
                  <li className="title_description">
                    • This will be the final round, wherein we will explain our
                    work culture / environment, benefits / perks, brief of HR
                    policies as well as will discuss about the commercial
                    expectations.
                  </li>
                </ul>
              </div>
              <h3
                className="h3 font-weight-normal"
                style={{ textTransform: "none" }}
              >
                Our HR will contact you shortly during our business hours, via
                email / phone; for the next steps.
              </h3>
              <h3
                className="h3 font-weight-normal"
                style={{ textTransform: "none" }}
              >
                P.S.: You will also receive an acknowledgment email to confirm
                your application receipt and next steps.
              </h3>
              {/* <h3
                className="h3 mb-3 font-weight-normal"
                onClick={this.backToHome}
                style={{ cursor: "pointer" }}
              >
                Back to HOME
              </h3> */}
            </div>
          </div>
        </div>

        <Footer></Footer>
      </React.Fragment>
    );
  }
}

export default ThankYou;
