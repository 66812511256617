import React, { Component } from "react";
import Noty from "noty";
import { Form, Input, Label, Button } from "reactstrap";
import { addUpdateReference } from "../../APICalls/commonAPICalls";
import { getReferenceByID } from "../../APICalls/commonAPICalls";
import {
  NoAccess,
  EnterProperDescription
} from "../../Resources/MessageResource";
import { decryptKey, noScriptRegex } from "../../CommonFunctions/CommonValues";
import Cryptr from "cryptr";
import { async } from "q";

const cryptr = new Cryptr(decryptKey);
const noScriptTag = noScriptRegex;

const referenceForValid = ({ referenceFormErrors, ...rest }) => {
  var valid = true;

  Object.values(referenceFormErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach(val => {
    val.description === "" && (valid = false);
  });
  return valid;
};

class EditReference extends Component {
  constructor(props) {
    localStorage.setItem("localpath", props.location.pathname.split(":")[0]);
    super(props);
    const ReferenceMasterID = props.match.params.id;
    this.state = {
      isLoading: false,
      userType: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .UserType
        : "",
      showUnauthorizedMessage: true,
      showAlertMessage: false,
      alertMessage: "",
      alertColor: "",
      notyTimeOut: 5000,
      alertTimeOut: 500,
      referenceData: {
        ReferenceMasterID: cryptr.decrypt(ReferenceMasterID.replace(":", ""))
      },
      referenceModal: {
        referenceMasterID: 0,
        description: "",
        isActive: 0,
        encCreatedBy: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .UserMasterID
          : 0,
        createdDate: new Date(),
        modifiedDate: new Date()
      },
      referenceFormErrors: {
        description: ""
      }
    };
  }

  componentDidCatch(error, info) {
    console.log(error);
    console.log(info);
  }

  async componentDidMount() {
    let { referenceModal } = this.state;
    this.state.userType === "xRGjRCA3M94%3d" ||
    this.state.userType === "6QjgBiG4ZRU%3d"
      ? await getReferenceByID(this.state.referenceData).then(response => {
          if (localStorage.getItem("failerMessage")) {
            this.setState(
              {
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
            localStorage.removeItem("failerMessage");
          } else {
            var response = JSON.parse(cryptr.decrypt(response));
            if (response.data.ResponseStatus === 3) {
              this.setState(
                {
                  showAlertMessage: true,
                  alertColor: "error",
                  alertMessage: response.data.ResponseMsg
                },
                () => {
                  window.setTimeout(() => {
                    this.setState({ showAlertMessage: false });
                    this.showNotification(this.state);
                  }, this.state.alertTimeOut);
                }
              );
            } else if (response.data.ResponseStatus === 1) {
              referenceModal.referenceMasterID =
                response.data.ReferenceMasterID;
              referenceModal.description = response.data.Description;
              referenceModal.isActive = response.data.IsActive;
              this.setState({
                referenceModal,
                isLoading: false
              });
            } else {
              this.setState(
                {
                  showAlertMessage: true,
                  alertColor: "error",
                  alertMessage: localStorage.getItem("failerMessage")
                },
                () => {
                  window.setTimeout(() => {
                    this.setState({ showAlertMessage: false });
                    this.showNotification(this.state);
                  }, this.state.alertTimeOut);
                }
              );
            }
          }
        })
      : this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: NoAccess
          },
          () => {
            window.setTimeout(() => {
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          }
        );
  }

  handleSubmit = async e => {
    e.preventDefault();
    if (referenceForValid(this.state)) {
      this.setState({ isLoading: true });
      await addUpdateReference(this.state.referenceModal).then(response => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          } else if (
            response.data.ResponseStatus === 1 ||
            response.data.ResponseStatus === 5
          ) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "success",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );

            window.setTimeout(() => {
              this.props.history.push("/recruitportal/reference");
            }, this.state.alertTimeOut);
          } else {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          }
        }
      });
    } else {
      this.setState(
        {
          isLoading: false,
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: EnterProperDescription
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        }
      );
    }
  };

  handleChange = e => {
    var referenceFormErrors = this.state.referenceFormErrors;
    var { referenceModal } = this.state;

    var target = e.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    var name = target.name;

    switch (name) {
      case "description":
        referenceModal.description = value;
        referenceFormErrors.description = !noScriptTag.test(value)
          ? "Invalid description"
          : value.length > 0
          ? ""
          : "Please enter description";
        break;
      case "status":
        referenceModal.isActive = value;
        break;
      default:
        break;
    }
    this.setState({
      referenceFormErrors,
      referenceModal
    });
  };

  handleBacktoList = () => {
    this.props.history.push("/recruitportal/reference");
  };

  onDismiss = () => {
    this.setState({ showAlertMessage: false, showUnauthorizedMessage: false });
  };

  showNotification(state) {
    new Noty({
      type: state.alertColor,
      theme: "bootstrap-v4",
      layout: "topRight",
      closeWith: ["button"],
      text: state.alertMessage,
      timeout: state.notyTimeOut
    }).show();
  }

  render() {
    const { referenceFormErrors } = this.state;
    const { referenceModal } = this.state;

    const editStatus = (
      <Form className="form-signin" noValidate onSubmit={this.handleSubmit}>
        <div className="mb-4">
          <h1 className="h5 mb-3 font-weight-normal">Edit Reference</h1>
          <div className="form-label-group">
            <Input
              type="text"
              id="description"
              name="description"
              className="form-control"
              placeholder="Description"
              noValidate
              autoFocus
              value={referenceModal.description}
              onChange={this.handleChange}
            />
            <span className="star">*</span>
            <Label htmlFor="description">Description</Label>
          </div>
          {referenceFormErrors.description.length > 0 && (
            <span className="errorMessage">
              {referenceFormErrors.description}
            </span>
          )}
          <div>
            <input
              className="k-checkbox"
              name="status"
              checked={referenceModal.isActive ? true : false}
              type="checkbox"
              id={this.props.match.params.id}
              onChange={this.handleChange}
            />
            <label
              htmlFor={this.props.match.params.id}
              className={
                referenceModal.isActive
                  ? "k-checkbox-label"
                  : "k-checkbox-label inactive"
              }
            >
              {referenceModal.isActive ? "Active" : "In active"}
            </label>
          </div>
          <div>
            <Button
              className="btn btn-lg btn-primary btn-block mt-3 ciplbtn"
              color="primary"
              type="submit"
            >
              Update
            </Button>
            <input
              type="button"
              value="Back to list"
              className="btn btn-lg btn-secondary btn-block mt-3 ciplbtn"
              onClick={this.handleBacktoList}
            />
          </div>
        </div>
      </Form>
    );
    return (
      <React.Fragment>
        <main role="main">
          {this.state.userType === "xRGjRCA3M94%3d" ||
          this.state.userType === "6QjgBiG4ZRU%3d" ? (
            <div className="starter-template">
              <h1 className="h3 mb-3 font-weight-normal" />
              <div style={{ textAlign: "left" }}>{editStatus}</div>
              {this.state.isLoading ? (
                <div className="k-loading-mask">
                  <span className="k-loading-text">Loading</span>
                  <div className="k-loading-image" />
                  <div className="k-loading-color" />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </main>
      </React.Fragment>
    );
  }
}

export default EditReference;
