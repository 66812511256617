import React, { useState } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { formatDate } from "@telerik/kendo-intl";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import IdleTimer from "react-idle-timer";
import Moment from "react-moment";
import Navigation from "./Navigation";
import NotFound from "../CommonFunctions/NotFound";
import Dashboard from "../Admin/Dashboard";
import ChangePassword from "../Account/ChangePassword";
import ViewRecruite from "../Admin/ViewRecruite";
import TechnologyList from "../Admin/Technology/TechnologyList";
import StatusList from "../Admin/Status/StatusList";
import AddTechnology from "../Admin/Technology/AddTechnology";
import EditTechnology from "../Admin/Technology/EditTechnology";
import AddStatus from "../Admin/Status/AddStatus";
import EditStatus from "../Admin/Status/EditStatus";
import UserList from "../Admin/User/UserList";
import AddUser from "../Admin/User/AddUser";
import EditUser from "../Admin/User/EditUser";
import ReferenceList from "../Admin/Reference/ReferenceList";
import AddReference from "../Admin/Reference/AddReference";
import EditReference from "../Admin/Reference/EditReference";
import EditRecruit from "../Admin/EditRecruit";
import AddNewRecruit from "../Admin/AddNewRecruit";
import ShowAllComments from "../Admin/ShowAllComments";

const MasterPage = ({ handleLogOut }) => {
  const [currentDate] = useState(new Date());
  const [idleTimer, setIdleTimer] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);

  const onAction = e => {
    //console.log("user did something", e);
  };

  const onActive = e => {
    //console.log("user is active", e);
    var duration = idleTimer.getRemainingTime();
    var milliseconds = parseInt((duration % 1000) / 100),
      seconds = parseInt((duration / 1000) % 60),
      minutes = parseInt((duration / (1000 * 60)) % 60),
      hours = parseInt((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    // console.log(
    //   "time remaining",
    //   hours + ":" + minutes + ":" + seconds + "." + milliseconds
    // );
  };

  const onIdle = e => {
    setVisibleDialog(true);
    // console.log("user is idle", e);
    // console.log(
    //   "last active",
    //   formatDate(new Date(idleTimer.getLastActiveTime()), "dd/MM/yyyy HH:mm:ss")
    // );
  };

  const toggleDialog = () => {
    setVisibleDialog(false);
    onActive();
  };

  const handleYesButton = () => {
    handleLogOut();
  };

  return (
    <div>
      <IdleTimer
        ref={ref => {
          setIdleTimer(ref);
        }}
        element={document}
        onActive={() => onActive()}
        onIdle={() => onIdle()}
        onAction={() => onAction()}
        debounce={250}
        timeout={1000 * 60 * 15}
      />
      {visibleDialog && (
        <Dialog title={"You have been Idle!"} onClose={() => toggleDialog()}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            You will get timed out. You want to stay?
          </p>
          <DialogActionsBar>
            <button className="m-2 btn btn-info" onClick={() => toggleDialog()}>
              Stay
            </button>
            <button
              className="m-2 btn btn-danger"
              onClick={() => handleYesButton()}
            >
              Logout
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      <Router>
        <Navigation handleLogOut={handleLogOut} />
        <Switch>
          <Route exact path="/recruitportal" component={ViewRecruite} />
          <Route path="/recruitportal/dashboard" component={Dashboard} />
          <Route
            path="/recruitportal/changepassword"
            component={ChangePassword}
          />
          <Route path="/recruitportal/add" component={AddNewRecruit} />
          <Route path="/recruitportal/view" component={ViewRecruite} />
          <Route path="/recruitportal/editrecruit:id" component={EditRecruit} />
          <Route path="/recruitportal/user" component={UserList} />
          <Route path="/recruitportal/adduser" component={AddUser} />
          <Route path="/recruitportal/edituser:id" component={EditUser} />
          <Route path="/recruitportal/technology" component={TechnologyList} />
          <Route
            path="/recruitportal/addtechnology"
            component={AddTechnology}
          />
          <Route
            path="/recruitportal/edittechnology:id"
            component={EditTechnology}
          />
          <Route path="/recruitportal/status" component={StatusList} />
          <Route path="/recruitportal/addstatus" component={AddStatus} />
          <Route path="/recruitportal/editstatus:id" component={EditStatus} />
          <Route path="/recruitportal/reference" component={ReferenceList} />
          <Route path="/recruitportal/addreference" component={AddReference} />
          <Route
            path="/recruitportal/editreference:id"
            component={EditReference}
          />
          <Route
            path="/recruitportal/showcomments:id"
            component={ShowAllComments}
          />
          <Route component={NotFound} />
        </Switch>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          {/* <div className="container"> */}
          {/* <a className="navbar-brand" href="#">
          Logo Here
        </a> */}
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav mr-auto" />
            <span className="navbar-text">
              Copyright &copy;
              <Moment format="YYYY">{currentDate}</Moment> Concept Infoway. All
              rights reserved
            </span>
          </div>
          {/* </div> */}
        </nav>
      </Router>
    </div>
  );
};

export default MasterPage;
