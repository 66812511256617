import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class CommonImageCrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Cropsrc: this.props.src,
      crop: {
        unit: "px",
        width: 230,
        height: 230,
        aspect: 1 / 1
      }
    };
  }

  setCropInit = () => {
    var { crop } = this.state;
    crop.unit = "px";
    crop.width = 230;
    crop.height = 230;
    crop.aspect = 1 / 1;
    this.setState({
      crop
    });
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height && this.state.Cropsrc) {
      // if (this.imageRef.height >= 230) {
      await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
      this.setState({ crop });

      // } else {
      //   this.setState(
      //     {
      //       IsProfilePicValid: false,
      //       showAlertMessage: true,
      //       alertColor: "error",
      //       alertMessage: ProfilePicResolution
      //     },
      //     () => {
      //       window.setTimeout(() => {
      //         this.showNotification(this.state);
      //       }, this.state.alertTimeOut);
      //     }
      //   );
      // }
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    //As Base64 string
    const base64Image = canvas.toDataURL("image/jpeg");
    this.props.getSelectedImageBase64(base64Image);
  }

  render() {
    return (
      <ReactCrop
        src={this.state.Cropsrc}
        crop={this.state.crop}
        // locked={true}
        ruleOfThirds
        onImageLoaded={this.onImageLoaded}
        onComplete={this.onCropComplete}
        onChange={this.onCropChange}
      />
    );
  }
}

export default CommonImageCrop;
