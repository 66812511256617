import React, { Component } from "react";
import { decryptKey } from "./CommonValues";
import Cryptr from "cryptr";

const cryptr = new Cryptr(decryptKey);
class RecruitActionMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .UserType
        : ""
    };
  }

  render() {
    const AlreadyUsed = this.props.dataItem["AlreadyUsed"];
    return (
      <td>
        <a
          target="_blank"
          title="Edit Candidate"
          className="btn mr-2 glyphicon glyphicon-edit actionMenu"
          onClick={this.props.callEdit}
        ></a>
        {AlreadyUsed == 0 || AlreadyUsed == undefined ? (
          this.state.userType === "xRGjRCA3M94%3d" ||
          this.state.userType === "6QjgBiG4ZRU%3d" ? (
            <React.Fragment>
              <a
                title="Delete Candidate"
                className="btn mr-2 glyphicon glyphicon-delete actionMenu"
                onClick={this.props.callDelete}
              ></a>
              <a
                title="Add to Calendar"
                className="btn glyphicon glyphicon-addcalendar actionMenu"
                onClick={this.props.callAddToCalendar}
              ></a>
            </React.Fragment>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </td>
    );
  }
}

export default RecruitActionMenu;
