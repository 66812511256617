import React, { Component } from "react";
import Noty from "noty";
import { Label, Input, Form, UncontrolledTooltip } from "reactstrap";
import Moment from "react-moment";
import "../css/form.css";
import noImage from "../Images/camera.png";
import Resizer from "react-image-file-resizer";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
  mobileVendor,
} from "react-device-detect";
import {
  technologyDropdown,
  updateRecruit,
  referenceDropdown,
  getRecruitByID,
  statusDropdown,
  statusDropdownForNormalUser,
  userDropdown,
  addTechnicalComment,
  addPracticalComment,
  addHRComment,
  noticePeriodDropdown,
  siteConfiguration,
  bindFromYears,
  bindToYears,
  UpdateRecruitImage,
} from "../APICalls/commonAPICalls";
import "react-html5-camera-photo/build/css/index.css";
import Webcam from "react-webcam";
import {
  decryptKey,
  emailAddressRegex,
  digitRegex,
  noScriptRegex,
} from "../CommonFunctions/CommonValues";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import {
  ShowErrorMessageForMandatoryFields,
  ShowErrorMessageForTechnologyFields,
  ShowErrorMessageForCompanyFields,
  ShowErrorMessageForInvalidCaptcha,
  ShowErrorMessageForNoWebCam,
  NoTechnologyFound,
  NoEmployerFound,
  EnterProperTechnicalComment,
  EnterProperPracticalComment,
  EnterProperHRComment,
  ShowErrorMessageForResumeSizeExceed,
  ShowErrorMessageForProfilePicSizeExceed,
} from "../Resources/MessageResource";
import Cryptr from "cryptr";
import { async } from "q";
import CommonImageCrop from "../CommonFunctions/CommonImageCrop";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const cryptr = new Cryptr(decryptKey);
const delay = 100;
const emailRegex = emailAddressRegex;
const numberRegex = digitRegex;
const scriptRegex = noScriptRegex;
const ErrorMessage = ShowErrorMessageForMandatoryFields;
const TechnologyErrorMessage = ShowErrorMessageForTechnologyFields;
const CompnayErrorMessage = ShowErrorMessageForCompanyFields;
const InvalidCaptcha = ShowErrorMessageForInvalidCaptcha;
const NoWebCam = ShowErrorMessageForNoWebCam;
const ResumeSizeExceed = ShowErrorMessageForResumeSizeExceed;
const ProfilePicSizeExceed = ShowErrorMessageForProfilePicSizeExceed;

const recruitFormValid = ({ recruitFormErrors, ...rest }) => {
  var valid = true;
  Object.values(rest).forEach((val) => {
    if (
      JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
        .UserType == "6QjgBiG4ZRU%3d"
    ) {
      if (
        val.TechnologyMasterID === 0 ||
        val.FirstName === "" ||
        val.LastName === "" ||
        val.Email === "" ||
        val.Mobile === "" ||
        val.HighestQualification === "" ||
        val.CurrentSalary === "0" ||
        val.CurrentSalary === "" ||
        val.ExpectedSalary === "0" ||
        val.ExpectedSalary === "" ||
        val.NoticePeriodID === 0 ||
        val.SuitableDate === "" ||
        val.SuitableTime === "" ||
        val.StatusMasterID === null ||
        val.InterviewDate === null
      ) {
        if (val.TechnologyMasterID === 0) {
          recruitFormErrors.TechnologyMasterID =
            "Please select Main Technology";
        }
        if (val.FirstName === "") {
          recruitFormErrors.FirstName = "Please enter valid First Name";
        }
        if (val.LastName === "") {
          recruitFormErrors.LastName = "Please enter valid Last Name";
        }
        if (val.Email === "") {
          recruitFormErrors.Email = "Please enter valid Email Address";
        }
        if (val.Mobile === "") {
          recruitFormErrors.Mobile = "Please enter valid Mobile Number";
        }
        if (val.HighestQualification === "") {
          recruitFormErrors.HighestQualification =
            "Please enter valid Highest Qualification";
        }
        if (val.CurrentSalary === "0" || val.CurrentSalary === "") {
          recruitFormErrors.CurrentSalary = "Please enter valid Current Salary";
        }
        if (val.ExpectedSalary === "0" || val.ExpectedSalary === "") {
          recruitFormErrors.ExpectedSalary =
            "Please enter valid Expected Salary";
        }
        if (val.NoticePeriodID === 0 || val.NoticePeriodID === "") {
          recruitFormErrors.NoticePeriodID = "Please select when can you join?";
        }
        if (val.SuitableDate === "") {
          recruitFormErrors.SuitableDate = "Please select Suitable Date";
        }
        if (val.SuitableTime === "") {
          recruitFormErrors.SuitableTime = "Please select Suitable Time";
        }
        if (val.StatusMasterID === null) {
          recruitFormErrors.StatusMasterID = "Please select Suitable Status";
        }
        if (val.InterviewDate === null) {
          recruitFormErrors.InterviewDate = "Please select Interview Date";
        }
        valid = false;
      } else {
        if (
          recruitFormErrors.TechnologyMasterID === "" &&
          recruitFormErrors.FirstName === "" &&
          recruitFormErrors.LastName === "" &&
          recruitFormErrors.Email === "" &&
          recruitFormErrors.Mobile === "" &&
          recruitFormErrors.ReferenceName === "" &&
          recruitFormErrors.HighestQualification === "" &&
          recruitFormErrors.ExtraCertification === "" &&
          (recruitFormErrors.CurrentSalary === "" ||
            recruitFormErrors.CurrentSalary === 0) &&
          (recruitFormErrors.ExpectedSalary === "" ||
            recruitFormErrors.ExpectedSalary === 0) &&
          recruitFormErrors.NoticePeriodID === "" &&
          recruitFormErrors.SuitableDate === "" &&
          recruitFormErrors.SuitableTime === "" &&
          recruitFormErrors.StatusMasterID === "" &&
          recruitFormErrors.InterviewDate === ""
        ) {
          valid = true;
        } else {
          valid = false;
        }
      }
    } else {
      if (
        val.TechnologyMasterID === 0 ||
        val.FirstName === "" ||
        val.LastName === "" ||
        val.Email === "" ||
        val.Mobile === "" ||
        val.HighestQualification === "" ||
        val.CurrentSalary === "0" ||
        val.CurrentSalary === "" ||
        val.ExpectedSalary === "0" ||
        val.ExpectedSalary === "" ||
        val.NoticePeriodID === 0 ||
        val.SuitableDate === "" ||
        val.SuitableTime === "" ||
        val.InterviewTakenByID === null ||
        val.InterviewTakenByID === "" ||
        val.StatusMasterID === null ||
        val.InterviewDate === null
      ) {
        if (val.TechnologyMasterID === 0) {
          recruitFormErrors.TechnologyMasterID =
            "Please select Main Technology";
        }
        if (val.FirstName === "") {
          recruitFormErrors.FirstName = "Please enter valid First Name";
        }
        if (val.LastName === "") {
          recruitFormErrors.LastName = "Please enter valid Last Name";
        }
        if (val.Email === "") {
          recruitFormErrors.Email = "Please enter valid Email Address";
        }
        if (val.Mobile === "") {
          recruitFormErrors.Mobile = "Please enter valid Mobile Number";
        }
        if (val.HighestQualification === "") {
          recruitFormErrors.HighestQualification =
            "Please enter valid Highest Qualification";
        }
        if (val.CurrentSalary === "0" || val.CurrentSalary === "") {
          recruitFormErrors.CurrentSalary = "Please enter valid Current Salary";
        }
        if (val.ExpectedSalary === "0" || val.ExpectedSalary === "") {
          recruitFormErrors.ExpectedSalary =
            "Please enter valid Expected Salary";
        }
        if (val.NoticePeriodID === 0 || val.NoticePeriodID === "") {
          recruitFormErrors.NoticePeriodID = "Please select when can you join?";
        }
        if (val.SuitableDate === "") {
          recruitFormErrors.SuitableDate = "Please select Suitable Date";
        }
        if (val.SuitableTime === "") {
          recruitFormErrors.SuitableTime = "Please select Suitable Time";
        }
        if (val.InterviewTakenByID === null || val.InterviewTakenByID === "") {
          recruitFormErrors.InterviewTakenBy =
            "Please enter Interview Taken By";
        }
        if (val.StatusMasterID === null) {
          recruitFormErrors.StatusMasterID = "Please select Suitable Status";
        }
        if (val.InterviewDate === null) {
          recruitFormErrors.InterviewDate = "Please select Interview Date";
        }
        valid = false;
      } else {
        if (
          recruitFormErrors.TechnologyMasterID === "" &&
          recruitFormErrors.FirstName === "" &&
          recruitFormErrors.LastName === "" &&
          recruitFormErrors.Email === "" &&
          recruitFormErrors.Mobile === "" &&
          recruitFormErrors.ReferenceName === "" &&
          recruitFormErrors.HighestQualification === "" &&
          recruitFormErrors.ExtraCertification === "" &&
          (recruitFormErrors.CurrentSalary === "" ||
            recruitFormErrors.CurrentSalary === 0) &&
          (recruitFormErrors.ExpectedSalary === "" ||
            recruitFormErrors.ExpectedSalary === 0) &&
          recruitFormErrors.NoticePeriodID === "" &&
          recruitFormErrors.SuitableDate === "" &&
          recruitFormErrors.SuitableTime === "" &&
          recruitFormErrors.InterviewTakenBy === "" &&
          recruitFormErrors.StatusMasterID === "" &&
          recruitFormErrors.InterviewDate === ""
        ) {
          valid = true;
        } else {
          valid = false;
        }
      }
    }
  });
  return valid;
};

const technologyFormValid = ({ recruitFormErrors }) => {
  var valid = true;

  Object.values(recruitFormErrors.technologiesError).forEach((val) => {
    if (scriptRegex.test(val.technology)) {
      (val.technology === undefined ||
      val.experienceInMonth === undefined ||
      val.experienceInYear === undefined
        ? (valid = false)
        : val.technology.length > 0 ||
          val.experienceInMonth.length > 0 ||
          val.experienceInYear.length > 0) && (valid = false);
    } else {
      valid = false;
    }
  });
  return valid;
};

const employerFormValid = ({ recruitFormErrors }) => {
  var valid = true;

  Object.values(recruitFormErrors.employerDetailsError).forEach((val) => {
    if (scriptRegex.test(val.companyName)) {
      (val.companyName === undefined ||
      val.durationFromYear === undefined ||
      val.durationFromMonth === undefined ||
      val.durationToYear === undefined ||
      val.durationToMonth === undefined
        ? (valid = false)
        : val.companyName.length > 0 ||
          val.durationFromYear.length > 0 ||
          val.durationFromMonth.length > 0 ||
          val.durationToYear.length > 0 ||
          val.durationToMonth.length > 0) && (valid = false);
    } else {
      valid = false;
    }
  });
  return valid;
};

const technicalCommentFormValid = ({ recruitFormErrors, ...rest }) => {
  var valid = true;
  Object.values(rest).forEach((val) => {
    if (scriptRegex.test(val.TechnicalComment)) {
      val.TechnicalComment === "" && (valid = false);
    } else {
      valid = false;
    }
  });
  return valid;
};

const practicalCommentFormValid = ({ recruitFormErrors, ...rest }) => {
  var valid = true;
  Object.values(rest).forEach((val) => {
    if (scriptRegex.test(val.PracticalComment)) {
      if (
        val.PracticalComment !== "" &&
        val.PracticalDate !== undefined &&
        val.PracticalDate !== ""
      ) {
        valid = true;
      } else {
        valid = false;
      }
    } else {
      valid = false;
    }
  });
  return valid;
};

const hrCommentFormValid = ({ recruitFormErrors, ...rest }) => {
  var valid = true;
  Object.values(rest).forEach((val) => {
    if (scriptRegex.test(val.HRComment)) {
      if (
        val.HRComment !== "" &&
        val.HRInterviewDate !== undefined &&
        val.HRInterviewDate !== ""
      ) {
        valid = true;
      } else {
        valid = false;
      }
    } else {
      valid = false;
    }
  });
  return valid;
};

//Added By Tarangini Alva 30.12.2019 For Update Recruit Image
const CandidateImageValid = ({ recruitFormErrors, ...rest }) => {
  var valid = true;
  Object.values(rest).forEach((val) => {
    if (
      val.CandidateImage != "" &&
      (val.ChangePhoto === true || val.isCamera === true)
    ) {
      valid = true;
    } else {
      valid = false;
    }
  });
  return valid;
};
//Added By Tarangini Alva 30.12.2019 For Update Recruit Image

class EditRecruit extends Component {
  months = [
    { Value: "0", ExperienceInMonth: 0 },
    { Value: "1", ExperienceInMonth: 1 },
    { Value: "2", ExperienceInMonth: 2 },
    { Value: "3", ExperienceInMonth: 3 },
    { Value: "4", ExperienceInMonth: 4 },
    { Value: "5", ExperienceInMonth: 5 },
    { Value: "6", ExperienceInMonth: 6 },
    { Value: "7", ExperienceInMonth: 7 },
    { Value: "8", ExperienceInMonth: 8 },
    { Value: "9", ExperienceInMonth: 9 },
    { Value: "10", ExperienceInMonth: 10 },
    { Value: "11", ExperienceInMonth: 11 },
    // { Value: "12", ExperienceInMonth: 12 }
  ];

  years = [
    { Value: "0", ExperienceInYear: 0 },
    { Value: "1", ExperienceInYear: 1 },
    { Value: "2", ExperienceInYear: 2 },
    { Value: "3", ExperienceInYear: 3 },
    { Value: "4", ExperienceInYear: 4 },
    { Value: "5", ExperienceInYear: 5 },
    { Value: "6", ExperienceInYear: 6 },
    { Value: "7", ExperienceInYear: 7 },
    { Value: "8", ExperienceInYear: 8 },
    { Value: "9", ExperienceInYear: 9 },
    { Value: "10", ExperienceInYear: 10 },
    { Value: "11", ExperienceInYear: 11 },
    { Value: "12", ExperienceInYear: 12 },
    { Value: "13", ExperienceInYear: 13 },
    { Value: "14", ExperienceInYear: 14 },
    { Value: "15", ExperienceInYear: 15 },
    { Value: "16", ExperienceInYear: 16 },
    { Value: "17", ExperienceInYear: 17 },
    { Value: "18", ExperienceInYear: 18 },
    { Value: "19", ExperienceInYear: 19 },
    { Value: "20", ExperienceInYear: 20 },
  ];

  fromMonths = [
    { Value: "Jan", DurationFromMonth: 1 },
    { Value: "Feb", DurationFromMonth: 2 },
    { Value: "Mar", DurationFromMonth: 3 },
    { Value: "Apr", DurationFromMonth: 4 },
    { Value: "May", DurationFromMonth: 5 },
    { Value: "Jun", DurationFromMonth: 6 },
    { Value: "Jul", DurationFromMonth: 7 },
    { Value: "Aug", DurationFromMonth: 8 },
    { Value: "Sep", DurationFromMonth: 9 },
    { Value: "Oct", DurationFromMonth: 10 },
    { Value: "Nov", DurationFromMonth: 11 },
    { Value: "Dec", DurationFromMonth: 12 },
  ];

  toMonths = [
    { Value: "Jan", DurationToMonth: 1 },
    { Value: "Feb", DurationToMonth: 2 },
    { Value: "Mar", DurationToMonth: 3 },
    { Value: "Apr", DurationToMonth: 4 },
    { Value: "May", DurationToMonth: 5 },
    { Value: "Jun", DurationToMonth: 6 },
    { Value: "Jul", DurationToMonth: 7 },
    { Value: "Aug", DurationToMonth: 8 },
    { Value: "Sep", DurationToMonth: 9 },
    { Value: "Oct", DurationToMonth: 10 },
    { Value: "Nov", DurationToMonth: 11 },
    { Value: "Dec", DurationToMonth: 12 },
  ];

  commentType = [
    { Value: "Technical", CommentTypeID: 1 },
    { Value: "Practical", CommentTypeID: 2 },
    { Value: "HR", CommentTypeID: 3 },
  ];

  commentTypeForNormalUser = [
    { Value: "Technical", CommentTypeID: 1 },
    { Value: "Practical", CommentTypeID: 2 },
  ];

  defaultTechnologyMonth = {
    Value: "Month",
    experienceInMonth: -1,
  };

  defaultTechnologyYear = {
    Value: "Year",
    experienceInYear: -1,
  };

  defaultNoticePeriodItem = {
    Description: "Select when can you Join?",
    NoticePeriodID: 0,
  };

  defaultTechnologyItem = {
    Description: "Select Main Technology",
    TechnologyMasterID: 0,
  };

  defaultStatusItem = {
    Description: "Select Suitable Status",
    StatusMasterID: 0,
  };

  defaultReferenceItem = {
    Description: "Select Reference",
    ReferenceMasterID: 0,
  };

  defaultCompanyFromYear = {
    Value: "Year",
    DurationFromYear: 0,
  };

  defaultCompanyToYear = {
    Value: "Year",
    DurationToYear: 0,
  };

  defaultCompanyFromMonth = {
    Value: "Month",
    DurationFromMonth: 0,
  };

  defaultCompanyToMonth = {
    Value: "Month",
    DurationToMonth: 0,
  };

  defaultComment = {
    Value: "Select Comment Type",
    CommentTypeID: 0,
  };

  steps = {
    hour: 1,
    minute: 5,
    second: 10,
  };

  constructor(props) {
    super(props);
    let RecruitMasterID = props.match.params.id;
    RecruitMasterID = RecruitMasterID.replace(":", "");
    this.state = {
      fromYears: [],
      toYears: [],
      isLoading: false,
      userType: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .UserType
        : "",
      showUnauthorizedMessage: true,
      isCaptchaVerified: false,
      showAlertMessage: false,
      alertMessage: "",
      alertColor: "",
      notyTimeOut: 5000,
      alertTimeOut: 500,
      tempTechnologyData: [],
      technologyDropdown: [],
      tempReferenceData: [],
      referenceDropdown: [],
      tempNoticePeriodData: [],
      noticePeriodDropdown: [],
      tempStatusData: [],
      statusDropdown: [],
      tempStatusDataForNormalUser: [],
      statusDropdownForNormalUser: [],
      tempUserData: [],
      userDropdown: [],
      currentDate: new Date(),
      loading: false,
      pageLoad: true,
      isWebCamOpen: false,
      Cropsrc: "",
      crop: {
        unit: "px",
        width: 230,
        height: 230,
        aspect: 1 / 1,
      },
      visibleDialog: false,
      rotateFlag: true,
      IsIOS: false,
      recruitData: {
        RecruitMasterID: cryptr.decrypt(RecruitMasterID),
      },
      siteConfigData: {
        isWalkingInterview: false,
        suitableTimeStarting: 0,
        suitableTimeEnding: 0,
      },
      recruitModal: {
        addNewReference: false,
        commentType: 0,
        ChangePhoto: false,
        ChangeResume: false,
        TechnologyMasterID: 0,
        TechnologyName: "",
        Experienced: false,
        TotalExp: 0,
        FirstName: "",
        LastName: "",
        Email: "",
        Mobile: "",
        CandidateImage: "",
        CandidateImageActual: "",
        CandidateImageBase64: "",
        PhotoName: "",
        ReferenceMasterID: 0,
        ReferenceName: "",
        HighestQualification: "",
        ExtraCertification: "",
        CurrentSalary: "",
        ExpectedSalary: "",
        Reconsider: false,
        NoticePeriodID: 0,
        Resume: "",
        ResumeFileName: "",
        SuitableDate: new Date(),
        SuitableTime: new Date(),
        DateOfJoining: "",
        Reason: "",
        ModifiedDate: new Date(),
        resumeData: "",
        InterviewTakenByID: [],
        InterviewTakenByOld: "",
        InterviewTakenBy: "",
        InterviewDate: new Date(),
        StatusMasterID: 0,
        TechnicalComment: "",
        TechnicalOtherComment: "",
        PracticalComment: "",
        PracticalOtherComment: "",
        PracticalDate: "",
        HRComment: "",
        HRInterviewDate: "",
        ReadyForBond: true,
        AgreeCTC: "",
        technologies: [
          { technology: "", ExperienceInMonth: -1, ExperienceInYear: -1 },
        ],
        employerDetails: [
          {
            companyName: "",
            DurationFromYear: 0,
            DurationFromMonth: 0,
            DurationToYear: 0,
            DurationToMonth: 0,
            Experience: "",
            ShowExperience: false,
          },
        ],
        commentDetails: [
          {
            CommentType: 0,
            Comment: "",
            OtherComment: "",
            Name: "",
            Date: "",
          },
        ],
        commentDetailsWithoutHRComments: [
          {
            CommentType: 0,
            Comment: "",
            OtherComment: "",
            Name: "",
            Date: "",
          },
        ],
      },
      recruitFormErrors: {
        TechnologyMasterID: "",
        FirstName: "",
        LastName: "",
        Email: "",
        Mobile: "",
        ReferenceName: "",
        HighestQualification: "",
        ExtraCertification: "",
        CurrentSalary: 0,
        ExpectedSalary: 0,
        NoticePeriodID: "",
        SuitableDate: "",
        SuitableTime: "",
        InterviewTakenBy: "",
        InterviewDate: "",
        StatusMasterID: "",
        TechnicalComment: "",
        TechnicalOtherComment: "",
        PracticalComment: "",
        PracticalOtherComment: "",
        HRComment: "",
        DateOfJoining: "",
        Reason: "",
        AgreeCTC: 0,
        technologiesError: [
          { technology: "", experienceInMonth: -1, experienceInYear: -1 },
        ],
        employerDetailsError: [
          {
            companyName: "",
            durationFromYear: 0,
            durationFromMonth: 0,
            durationToYear: 0,
            durationToMonth: 0,
          },
        ],
      },
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    await getRecruitByID(this.state.recruitData).then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            pageLoad: false,
            crop: {
              unit: "px",
              width: 230,
              height: 230,
              aspect: 1 / 1,
            },
            recruitModal: {
              RecruitMasterID: response.data.RecruitMasterID,
              TechnologyMasterID: response.data.TechnologyMasterID,
              TechnologyName: response.data.TechnologyName,
              CandidateNumber: response.data.CandidateNumber,
              Experienced:
                response.data.Experienced === null
                  ? false
                  : response.data.Experienced,
              TotalExp: response.data.TotalExp,
              FirstName: response.data.FirstName,
              LastName: response.data.LastName,
              Email: response.data.Email,
              Mobile: response.data.Mobile,
              CandidateImage: response.data.CandidateImage,
              CandidateImageActual: response.data.CandidateImage,
              ReferenceMasterID: response.data.ReferenceMasterID,
              addNewReference:
                response.data.ReferenceMasterID === 0 &&
                response.data.ReferenceName !== ""
                  ? true
                  : false,
              ReferenceName: response.data.ReferenceName,
              HighestQualification: response.data.HighestQualification,
              ExtraCertification: response.data.ExtraCertification,
              CurrentSalary: response.data.CurrentSalary,
              ExpectedSalary: response.data.ExpectedSalary,
              Reconsider: response.data.Reconsider,
              NoticePeriodID: response.data.NoticePeriodID,
              NoticePeriodOld: response.data.NoticePeriodOld,
              Resume: response.data.Resume,
              ResumeFileName: response.data.ResumeFileName,
              SuitableDate: new Date(response.data.SuitableDate),
              SuitableTime: new Date(response.data.SuitableTime),
              DateOfJoining:
                response.data.DateOfJoining === null
                  ? ""
                  : new Date(response.data.DateOfJoining),
              Reason: response.data.Reason,
              resumeData: response.data.Resume,
              technologies: response.data.technologies,
              employerDetails: response.data.employerDetails,
              InterviewTakenByID: response.data.InterviewTakenBy,
              InterviewTakenByOld: response.data.InterviewTakenByOld,
              InterviewTakenBy: response.data.InterviewTakenByJSON,
              InterviewDate:
                response.data.InterviewTakenDate === null
                  ? new Date()
                  : new Date(response.data.InterviewTakenDate),
              StatusMasterID: response.data.StatusMasterID,
              TechnicalComment: "",
              TechnicalOtherComment: "",
              PracticalComment: "",
              PracticalOtherComment: "",
              PracticalDate: "",
              HRComment: "",
              HRInterviewDate: "",
              commentDetails: response.data.commentDetails,
              commentDetailsWithoutHRComments:
                response.data.commentDetailsWithoutHRComments,
              ReadyForBond: response.data.ReadyForBond,
              AgreeCTC: response.data.AgreeCTC,
            },
            recruitFormErrors: {
              TechnologyMasterID: "",
              FirstName: "",
              LastName: "",
              Email: "",
              Mobile: "",
              ReferenceName: "",
              HighestQualification: "",
              ExtraCertification: "",
              CurrentSalary: 0,
              ExpectedSalary: 0,
              NoticePeriodID: "",
              SuitableDate: "",
              SuitableTime: "",
              InterviewTakenBy: "",
              InterviewDate: "",
              StatusMasterID: "",
              TechnicalComment: "",
              TechnicalOtherComment: "",
              PracticalComment: "",
              PracticalOtherComment: "",
              PracticalDate: "",
              HRComment: "",
              HRInterviewDate: "",
              DateOfJoining: "",
              Reason: "",
              AgreeCTC: 0,
              technologiesError: response.data.technologiesError,
              employerDetailsError: response.data.employerDetailsError,
            },
          });
        } else if (response.data.ResponseStatus === 4) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
          window.setTimeout(() => {
            this.props.history.push("/recruitportal/view");
          }, 1000);
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await technologyDropdown().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );

        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            isLoading: false,
            tempTechnologyData: response.data.lstTechnology.slice(),
            technologyDropdown: response.data.lstTechnology.slice(),
          });
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await referenceDropdown().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );

        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            isLoading: false,
            tempReferenceData: response.data.lstReference.slice(),
            referenceDropdown: response.data.lstReference.slice(),
          });
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await statusDropdown().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            isLoading: false,
            tempStatusData: response.data.lstStatus.slice(),
            statusDropdown: response.data.lstStatus.slice(),
          });
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await statusDropdownForNormalUser().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            isLoading: false,
            tempStatusDataForNormalUser:
              response.data.lstStatusForNormalUser.slice(),
            statusDropdownForNormalUser:
              response.data.lstStatusForNormalUser.slice(),
          });
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await userDropdown().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            isLoading: false,
            tempUserData: response.data.lstUser.slice(),
            userDropdown: response.data.lstUser.slice(),
          });
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await noticePeriodDropdown().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            tempNoticePeriodData: response.data.lstNoticePeriod.slice(),
            noticePeriodDropdown: response.data.lstNoticePeriod.slice(),
          });
        } else {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await siteConfiguration().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            isLoading: false,
            siteConfigData: {
              isWalkingInterview: response.data.IsWalkingInterview,
              suitableTimeStarting: response.data.SuitableTimeStarting,
              suitableTimeEnding: response.data.SuitableTimeEnding,
            },
          });
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await bindFromYears().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            isLoading: false,
            fromYears: response.data.YearJSON,
          });
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });

    await bindToYears().then((response) => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage"),
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            isLoading: false,
            toYears: response.data.YearJSON,
          });
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      }
    });
  }

  handleRecruitSubmit = async (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    this.setState({ isLoading: true });
    if (recruitFormValid(this.state)) {
      if (technologyFormValid(this.state)) {
        if (employerFormValid(this.state)) {
          await updateRecruit(this.state.recruitModal).then((response) => {
            if (localStorage.getItem("failerMessage")) {
              this.setState(
                {
                  isLoading: false,
                  showAlertMessage: true,
                  alertColor: "error",
                  alertMessage: localStorage.getItem("failerMessage"),
                },
                () => {
                  window.setTimeout(() => {
                    this.setState({ showAlertMessage: false });
                    this.showNotification(this.state);
                  }, this.state.alertTimeOut);
                },
              );
              localStorage.removeItem("failerMessage");
            } else {
              var response = JSON.parse(cryptr.decrypt(response));
              if (response.data.ResponseStatus === 3) {
                this.setState(
                  {
                    isLoading: false,
                    showAlertMessage: true,
                    alertColor: "error",
                    alertMessage: response.data.ResponseMsg,
                  },
                  () => {
                    window.setTimeout(() => {
                      this.setState({ showAlertMessage: false });
                      this.showNotification(this.state);
                    }, this.state.alertTimeOut);
                  },
                );
              } else if (response.data.ResponseStatus === 1) {
                scroll.scrollToTop();
                this.setState(
                  {
                    isLoading: false,
                    showAlertMessage: true,
                    alertColor: "success",
                    alertMessage: response.data.ResponseMsg,
                  },
                  () => {
                    window.setTimeout(() => {
                      this.setState({ showAlertMessage: false });
                      this.showNotification(this.state);
                    }, this.state.alertTimeOut);
                  },
                );
                window.setTimeout(() => {
                  this.props.history.push("/recruitportal/view");
                }, 1000);
              } else if (response.data.ResponseStatus === 4) {
                this.setState(
                  {
                    isLoading: false,
                    showAlertMessage: true,
                    alertColor: "error",
                    alertMessage: response.data.ResponseMsg,
                  },
                  () => {
                    window.setTimeout(() => {
                      this.setState({ showAlertMessage: false });
                      this.showNotification(this.state);
                    }, this.state.alertTimeOut);
                  },
                );
              } else {
                this.setState(
                  {
                    isLoading: false,
                    showAlertMessage: true,
                    alertColor: "error",
                    alertMessage: localStorage.getItem("failerMessage"),
                  },
                  () => {
                    window.setTimeout(() => {
                      this.setState({ showAlertMessage: false });
                      this.showNotification(this.state);
                    }, this.state.alertTimeOut);
                  },
                );
              }
            }
          });
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: CompnayErrorMessage,
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
        }
      } else {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: TechnologyErrorMessage,
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
      }
    } else {
      this.setState(
        {
          isLoading: false,
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: ErrorMessage,
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        },
      );
    }
  };

  recaptchaLoaded = () => {
    console.log("Recaptcha Successfully Loaded");
  };

  verifyCallback = (response) => {
    if (response) {
      this.setState({ isCaptchaVerified: true });
    }
  };

  handleChange = (e) => {
    var recruitFormErrors = this.state.recruitFormErrors;
    var { recruitModal } = this.state;
    var target = e.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    var name = target.name;

    switch (name) {
      case "mainTechnology":
        recruitModal.TechnologyMasterID = value.TechnologyMasterID;
        recruitFormErrors.TechnologyMasterID =
          value.TechnologyMasterID == 0 || value.TechnologyMasterID == ""
            ? "Please select Main Technology"
            : "";
        break;
      case "firstname":
        recruitModal.FirstName = value;
        recruitFormErrors.FirstName =
          scriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid First Name";
        break;
      case "lastname":
        recruitModal.LastName = value;
        recruitFormErrors.LastName =
          scriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Last Name";
        break;
      case "email":
        recruitModal.Email = value;
        recruitFormErrors.Email =
          emailRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Email Address";
        break;
      case "mobile":
        recruitModal.Mobile = value;
        recruitFormErrors.Mobile =
          numberRegex.test(value) && value.length > 0
            ? value.length >= 10 && value.length <= 15
              ? ""
              : "Please enter valid Mobile Number"
            : "Please enter valid Mobile Number";
        break;
      case "reference":
        recruitModal.ReferenceMasterID = value.ReferenceMasterID;
        value.ReferenceMasterID === -1
          ? (recruitModal.addNewReference = true)
          : (recruitModal.addNewReference = false);
        break;
      case "referenceName":
        recruitModal.ReferenceName = value;
        recruitFormErrors.ReferenceName = scriptRegex.test(value)
          ? ""
          : "Please enter valid Reference Name";
        recruitModal.ReferenceMasterID = 0;
        break;
      case "highestQualification":
        recruitModal.HighestQualification = value;
        recruitFormErrors.HighestQualification =
          scriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Highest Qualification";
        break;
      case "extraCertification":
        recruitModal.ExtraCertification = value;
        recruitFormErrors.ExtraCertification = scriptRegex.test(value)
          ? ""
          : "Please enter valid Extra Certification";
        break;
      case "experienced":
        recruitModal.Experienced = value;
        break;
      case "currentSalary":
        recruitModal.CurrentSalary = value;
        recruitFormErrors.CurrentSalary =
          numberRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Current Salary";
        break;
      case "expectedSalary":
        recruitModal.ExpectedSalary = value;
        recruitFormErrors.ExpectedSalary =
          numberRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Expected Salary";
        break;
      case "reconsider":
        recruitModal.Reconsider = value;
        break;
      case "noticePeriod":
        recruitModal.NoticePeriodID = value.NoticePeriodID;
        recruitFormErrors.NoticePeriodID =
          value.NoticePeriodID == 0 || value.NoticePeriodID == ""
            ? "Please select when can you join?"
            : "";
        break;
      case "suitableDate":
        recruitModal.SuitableDate = value;
        var d = new Date(value);
        recruitFormErrors.SuitableDate =
          value != null
            ? this.state.siteConfigData.isWalkingInterview
              ? ""
              : d.getDay() === 6 || d.getDay() === 0
              ? "Please select Suitable Date within WeekDays"
              : ""
            : "Please select Suitable Date";
        break;
      case "suitableTime":
        recruitModal.SuitableTime = value;
        recruitFormErrors.SuitableTime =
          value != null ? "" : "Please select Suitable Time";
        break;
      case "interviewTakenBy":
        var commaSeparatedValues = "";
        for (var i = 0; i < value.length; i++) {
          commaSeparatedValues += value[i].UserMasterID + ",";
        }
        recruitModal.InterviewTakenByID = commaSeparatedValues;
        recruitModal.InterviewTakenBy = value;
        recruitFormErrors.InterviewTakenBy =
          value.length > 0 ? "" : "Please enter Interview Taken By";
        break;
      case "interviewDate":
        recruitModal.InterviewDate = value;
        recruitModal.PracticalDate = value;
        recruitModal.HRInterviewDate = value;
        recruitFormErrors.InterviewDate =
          value != null ? "" : "Please select Interview Date";
        break;
      case "mainSuitableStatus":
        recruitModal.StatusMasterID = value.StatusMasterID;
        recruitFormErrors.StatusMasterID =
          value.StatusMasterID == 0 || value.StatusMasterID == ""
            ? "Please select Suitable Status"
            : "";
        break;
      case "techComment":
        recruitModal.TechnicalComment = value;
        recruitFormErrors.TechnicalComment =
          noScriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Technical Comment";
        break;
      case "techOtherComment":
        recruitModal.TechnicalOtherComment = value;
        recruitFormErrors.TechnicalOtherComment = noScriptRegex.test(value)
          ? ""
          : "Please enter valid Technical Other Comment";
        break;
      case "practicalComment":
        recruitModal.PracticalComment = value;
        recruitFormErrors.PracticalComment =
          noScriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Practical Comment";
        break;
      case "practicalOtherComment":
        recruitModal.PracticalOtherComment = value;
        recruitFormErrors.PracticalOtherComment = noScriptRegex.test(value)
          ? ""
          : "Please enter valid Practical Other Comment";
        break;
      case "practicalDate":
        recruitModal.PracticalDate = value;
        recruitFormErrors.PracticalDate =
          value != null ? "" : "Please select Practical Date";
        break;
      case "hrComment":
        recruitModal.HRComment = value;
        recruitFormErrors.HRComment =
          noScriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid HR Comment";
        break;
      case "hrInterviewDate":
        recruitModal.HRInterviewDate = value;
        recruitFormErrors.HRInterviewDate =
          value != null ? "" : "Please select HR Interview Date";
        break;
      case "dateOfJoining":
        recruitModal.DateOfJoining = value;
        break;
      case "reason":
        recruitModal.Reason = value;
        recruitFormErrors.Reason = noScriptRegex.test(value)
          ? ""
          : "Please enter valid Reason";
        break;
      case "agreeCTC":
        recruitModal.AgreeCTC = value;
        recruitFormErrors.AgreeCTC = numberRegex.test(value)
          ? ""
          : "Please enter valid CTC";
        break;
      case "readyForBond":
        recruitModal.ReadyForBond = value;
        break;
    }

    this.setState({
      recruitFormErrors,
      recruitModal,
    });
  };

  filterChangeTechnology = (event) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        technologyDropdown: this.filterTechnologyData(event.filter),
        loading: false,
      });
    }, delay);

    this.setState({
      loading: true,
    });
  };

  filterChangeStatusForNormalUser = (event) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        statusDropdownForNormalUser: this.filterStatusDataForNormalUser(
          event.filter,
        ),
        loading: false,
      });
    }, delay);

    this.setState({
      loading: true,
    });
  };

  filterChangeStatus = (event) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        statusDropdown: this.filterStatusData(event.filter),
        loading: false,
      });
    }, delay);

    this.setState({
      loading: true,
    });
  };

  filterChangeReference = (event) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        referenceDropdown: this.filterReferenceData(event.filter),
        loading: false,
      });
    }, delay);

    this.setState({
      loading: true,
    });
  };

  filterChangeInterviewTakenBy = (event) => {
    this.setState({
      userDropdown: filterBy(this.state.tempUserData.slice(), event.filter),
    });
  };

  filterTechnologyData(filter) {
    const data = this.state.tempTechnologyData;
    return filterBy(data, filter);
  }

  filterStatusDataForNormalUser(filter) {
    const data = this.state.tempStatusDataForNormalUser;
    return filterBy(data, filter);
  }

  filterStatusData(filter) {
    const data = this.state.tempStatusData;
    return filterBy(data, filter);
  }

  filterReferenceData(filter) {
    const data = this.state.tempReferenceData;
    return filterBy(data, filter);
  }

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  capture = () => {
    this.setState({
      Cropsrc: "",
    });
    var { recruitModal } = this.state;
    if (this.webcam.state.hasUserMedia) {
      const imageSrc = this.webcam.getScreenshot();
      recruitModal.CandidateImage = imageSrc;
      recruitModal.isCamera = true;
      this.setState({ recruitModal, isWebCamOpen: false, Cropsrc: imageSrc });
      this.toggleDialog(this.props);
    } else {
      recruitModal.CandidateImage = "";
      recruitModal.isCamera = false;
      this.setState({ recruitModal, isWebCamOpen: false, Cropsrc: "" });
      this.setState(
        {
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: NoWebCam,
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        },
      );
    }
  };

  openWebCam = () => {
    var { recruitModal } = this.state;
    if (this.state.isWebCamOpen) {
      this.setState({ isWebCamOpen: false });
    } else {
      recruitModal.CandidateImage = "";
      recruitModal.PhotoName = "";
      recruitModal.ChangePhoto = false;
      this.setState({ recruitModal, isWebCamOpen: true, Cropsrc: "" });
    }
  };

  _handleImageChange(e) {
    this.setState({
      Cropsrc: "",
    });
    e.preventDefault();
    var { recruitModal } = this.state;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file === undefined) {
    } else {
      if (file.size / 1048576 > 2) {
        this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: ProfilePicSizeExceed,
          },
          () => {
            window.setTimeout(() => {
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
      } else {
        // if (isIOS) {
        //   Resizer.imageFileResizer(
        //     e.target.files[0],
        //     230,
        //     230,
        //     "JPEG",
        //     100,
        //     90,
        //     uri => {
        //       recruitModal.PhotoName = file.name;
        //       recruitModal.CandidateImage = uri;
        //       recruitModal.ChangePhoto = true;
        //       this.setState({
        //         recruitModal
        //       });
        //     },
        //     "base64"
        //   );
        // } else if (isAndroid) {
        //   if (mobileVendor === "Samsung") {
        //     Resizer.imageFileResizer(
        //       e.target.files[0],
        //       230,
        //       230,
        //       "JPEG",
        //       100,
        //       270,
        //       uri => {
        //         recruitModal.PhotoName = file.name;
        //         recruitModal.CandidateImage = uri;
        //         recruitModal.ChangePhoto = true;
        //         this.setState({
        //           recruitModal
        //         });
        //       },
        //       "base64"
        //     );
        //   } else {
        //     Resizer.imageFileResizer(
        //       e.target.files[0],
        //       230,
        //       230,
        //       "JPEG",
        //       100,
        //       0,
        //       uri => {
        //         recruitModal.PhotoName = file.name;
        //         recruitModal.CandidateImage = uri;
        //         recruitModal.ChangePhoto = true;
        //         this.setState({
        //           recruitModal
        //         });
        //       },
        //       "base64"
        //     );
        //   }
        // } else {
        //   Resizer.imageFileResizer(
        //     e.target.files[0],
        //     230,
        //     230,
        //     "JPEG",
        //     100,
        //     0,
        //     uri => {
        //       recruitModal.PhotoName = file.name;
        //       recruitModal.CandidateImage = uri;
        //       recruitModal.ChangePhoto = true;
        //       this.setState({
        //         recruitModal
        //       });
        //     },
        //     "base64"
        //   );
        // }
        if (isAndroid && isMobile) {
          if (mobileVendor === "Samsung") {
            Resizer.imageFileResizer(
              e.target.files[0],
              350,
              350,
              "JPEG",
              100,
              270,
              (uri) => {
                recruitModal.PhotoName = file.name;
                recruitModal.CandidateImage = uri;
                recruitModal.ChangePhoto = true;
                this.setState({
                  recruitModal,
                  Cropsrc: uri,
                  IsIOS: false,
                });
              },
              "base64",
            );
          } else {
            Resizer.imageFileResizer(
              e.target.files[0],
              350,
              350,
              "JPEG",
              100,
              0,
              (uri) => {
                recruitModal.PhotoName = file.name;
                recruitModal.CandidateImage = uri;
                recruitModal.ChangePhoto = true;
                this.setState({
                  recruitModal,
                  Cropsrc: uri,
                  IsIOS: false,
                });
              },
              "base64",
            );
          }
        } else {
          if (isIOS) {
            Resizer.imageFileResizer(
              e.target.files[0],
              350,
              350,
              "JPEG",
              100,
              0,
              (uri) => {
                recruitModal.PhotoName = file.name;
                recruitModal.CandidateImage = uri;
                recruitModal.ChangePhoto = true;

                var resetImage = "";
                this.resetOrientation(uri, 6, function (resetBase64Image) {
                  resetImage = resetBase64Image;
                });
                this.setState({
                  recruitModal,
                  IsIOS: true,
                  //Cropsrc: uri
                });
              },
              "base64",
            );
          } else if (isBrowser) {
            Resizer.imageFileResizer(
              e.target.files[0],
              350,
              350,
              "JPEG",
              100,
              0,
              (uri) => {
                recruitModal.PhotoName = file.name;
                recruitModal.CandidateImage = uri;
                recruitModal.ChangePhoto = true;
                this.setState({
                  recruitModal,
                  Cropsrc: uri,
                  IsIOS: false,
                });
              },
              "base64",
            );
          } else {
            reader.onloadend = () => {
              recruitModal.PhotoName = file.name;
              recruitModal.CandidateImage = reader.result;
              recruitModal.ChangePhoto = true;
              this.setState({
                recruitModal,
                Cropsrc: reader.result,
                IsIOS: false,
              });
            };
            reader.readAsDataURL(file);
          }
        }
        this.setCropInit();
        this.toggleDialog(this.props);
      }
    }
  }

  resetOrientation = (srcBase64, srcOrientation, callback) => {
    var img = new Image();

    img.onload = function () {
      var width = img.width,
        height = img.height,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      //transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      const data = canvas.toDataURL();
      this.setState({
        Cropsrc: data,
      });

      callback(canvas.toDataURL());
    }.bind(this);

    img.src = srcBase64;
  };

  _handleResumeChange(e) {
    e.preventDefault();
    var { recruitModal } = this.state;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file === undefined) {
    } else {
      if (file.size / 1048576 > 2) {
        this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: ResumeSizeExceed,
          },
          () => {
            window.setTimeout(() => {
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          },
        );
      } else {
        reader.onloadend = () => {
          recruitModal.Resume = reader.result;
          recruitModal.ResumeFileName = file.name;
          recruitModal.ChangeResume = true;
          this.setState({
            recruitModal,
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }

  onDismiss = () => {
    this.setState({ showAlertMessage: false });
  };

  handleTechnologyChange = (idx) => (e) => {
    const recruitFormErrors = this.state.recruitFormErrors;
    const recruitModal = this.state.recruitModal;
    var target = e.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    var name = target.name;

    switch (name) {
      case "technology-" + idx:
        recruitModal.technologies[idx].technology = value;
        recruitFormErrors.technologiesError[idx].technology =
          scriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Technology";
        break;
      case "experienceInMonth-" + idx:
        recruitModal.technologies[idx].ExperienceInMonth =
          value.ExperienceInMonth;
        recruitFormErrors.technologiesError[idx].experienceInMonth =
          value.ExperienceInMonth >= 0 ? "" : "Please select Month";
        break;
      case "experienceInYear-" + idx:
        recruitModal.technologies[idx].ExperienceInYear =
          value.ExperienceInYear;
        recruitFormErrors.technologiesError[idx].experienceInYear =
          value.ExperienceInYear >= 0 ? "" : "Please select Year";
        break;
    }
    this.setState({
      recruitFormErrors,
      recruitModal,
    });
  };

  handleAddTechnologyRow = () => {
    if (technologyFormValid(this.state)) {
      const recruitFormErrors = this.state.recruitFormErrors;
      const recruitModal = this.state.recruitModal;
      recruitModal.technologies = [
        ...recruitModal.technologies,
        { technology: "", ExperienceInMonth: -1, ExperienceInYear: -1 },
      ];
      recruitFormErrors.technologiesError = [
        ...recruitFormErrors.technologiesError,
        { technology: "", experienceInMonth: -1, experienceInYear: -1 },
      ];
      this.setState({
        recruitFormErrors,
        recruitModal,
      });
    } else {
      this.setState(
        {
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: TechnologyErrorMessage,
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        },
      );
    }
  };

  handleRemoveRow = () => {
    this.setState({
      technologies: this.state.technologies.slice(0, -1),
    });
  };

  handleRemoveTechnologyRow = (idx) => () => {
    const { technologies } = this.state.recruitModal;
    const { technologiesError } = this.state.recruitFormErrors;
    technologies.splice(idx, 1);
    technologiesError.splice(idx, 1);
    this.setState({
      technologiesError,
      technologies,
    });
  };

  handleEmployerChange = (idx) => (e) => {
    const recruitFormErrors = this.state.recruitFormErrors;
    const recruitModal = this.state.recruitModal;
    var target = e.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    var name = target.name;
    var todayDate = new Date();

    switch (name) {
      case "companyName-" + idx:
        recruitModal.employerDetails[idx].companyName = value;
        recruitFormErrors.employerDetailsError[idx].companyName =
          scriptRegex.test(value) && value.length > 0
            ? ""
            : "Please enter valid Company name";
        break;
      case "fromYear-" + idx:
        recruitModal.employerDetails[idx].DurationFromYear =
          value.DurationFromYear;
        if (
          recruitModal.employerDetails[idx].DurationFromMonth > 0 &&
          recruitModal.employerDetails[idx].DurationFromYear > 0 &&
          recruitModal.employerDetails[idx].DurationToMonth > 0 &&
          recruitModal.employerDetails[idx].DurationToYear > 0
        ) {
          recruitModal.employerDetails[idx].ShowExperience = true;
        } else {
          recruitModal.employerDetails[idx].ShowExperience = false;
        }

        if (recruitModal.employerDetails[idx - 1] != undefined) {
          recruitFormErrors.employerDetailsError[idx - 1].durationToYear =
            value.DurationFromYear > 0
              ? value.DurationFromYear >=
                recruitModal.employerDetails[idx - 1].DurationToYear
                ? ""
                : "To Year should less than next companies From Year"
              : "";

          recruitFormErrors.employerDetailsError[idx - 1].durationToMonth =
            value.DurationFromYear > 0
              ? value.DurationFromYear >=
                recruitModal.employerDetails[idx - 1].DurationToYear
                ? ""
                : "To Month should less than next companies From Month"
              : "";
        }

        recruitFormErrors.employerDetailsError[idx].durationFromYear =
          value.DurationFromYear > 0
            ? recruitModal.employerDetails[idx - 1] != undefined
              ? recruitModal.employerDetails[idx - 1].DurationToYear >
                recruitModal.employerDetails[idx].DurationFromYear
                ? "From Year should greater than previous companies To Year"
                : ""
              : value.DurationFromYear <=
                recruitModal.employerDetails[idx].DurationToYear
              ? ""
              : "From Year should less than To Year"
            : "Please select From Year";

        recruitFormErrors.employerDetailsError[idx].durationToYear =
          value.DurationFromYear > 0
            ? value.DurationFromYear <=
              recruitModal.employerDetails[idx].DurationToYear
              ? ""
              : "To Year should greater than From Year"
            : "Please select To Year";

        recruitFormErrors.employerDetailsError[idx].durationFromMonth =
          recruitModal.employerDetails[idx].DurationFromMonth > 0
            ? value.DurationFromYear === todayDate.getFullYear()
              ? todayDate.getMonth() + 1 <
                recruitModal.employerDetails[idx].DurationFromMonth
                ? "From Month should less than current calendar Month"
                : ""
              : recruitModal.employerDetails[idx - 1] != undefined
              ? recruitModal.employerDetails[idx - 1].DurationToYear >
                recruitModal.employerDetails[idx].DurationFromYear
                ? ""
                : recruitModal.employerDetails[idx - 1].DurationToYear ===
                    recruitModal.employerDetails[idx].DurationFromYear &&
                  recruitModal.employerDetails[idx - 1].DurationToMonth === 12
                ? "From Year & Month should greater than previous companies To Year & Month"
                : recruitModal.employerDetails[idx - 1].DurationToYear ===
                    recruitModal.employerDetails[idx].DurationFromYear &&
                  recruitModal.employerDetails[idx - 1].DurationToMonth >
                    recruitModal.employerDetails[idx].DurationFromMonth
                ? "From Year & Month should greater than previous companies To Year & Month"
                : ""
              : value.DurationFromYear <
                recruitModal.employerDetails[idx].DurationToYear
              ? ""
              : ""
            : "Please select From Month";

        recruitFormErrors.employerDetailsError[idx].durationToMonth =
          recruitModal.employerDetails[idx].DurationToMonth > 0
            ? recruitModal.employerDetails[idx].DurationFromMonth >
              recruitModal.employerDetails[idx].DurationToMonth
              ? recruitModal.employerDetails[idx].DurationFromYear <
                recruitModal.employerDetails[idx].DurationToYear
                ? ""
                : "To Month should greater than From Month"
              : ""
            : "Please select To Month";
        break;
      case "fromMonth-" + idx:
        recruitModal.employerDetails[idx].DurationFromMonth =
          value.DurationFromMonth;
        if (
          recruitModal.employerDetails[idx].DurationFromMonth > 0 &&
          recruitModal.employerDetails[idx].DurationFromYear > 0 &&
          recruitModal.employerDetails[idx].DurationToMonth > 0 &&
          recruitModal.employerDetails[idx].DurationToYear > 0
        ) {
          recruitModal.employerDetails[idx].ShowExperience = true;
        } else {
          recruitModal.employerDetails[idx].ShowExperience = false;
        }

        if (recruitModal.employerDetails[idx - 1] != undefined) {
          recruitFormErrors.employerDetailsError[idx - 1].durationToMonth =
            value.DurationFromMonth > 0
              ? value.DurationFromMonth >=
                recruitModal.employerDetails[idx - 1].DurationToMonth
                ? ""
                : recruitModal.employerDetails[idx - 1].DurationToYear <
                  recruitModal.employerDetails[idx].DurationFromYear
                ? ""
                : "To Month should less than next companies From Month"
              : "";
        }

        recruitFormErrors.employerDetailsError[idx].durationFromMonth =
          value.DurationFromMonth > 0
            ? recruitModal.employerDetails[idx].DurationFromYear ===
              todayDate.getFullYear()
              ? todayDate.getMonth() + 1 < value.DurationFromMonth
                ? "From Month should not greater than current calendar Month"
                : ""
              : recruitModal.employerDetails[idx - 1] != undefined
              ? recruitModal.employerDetails[idx - 1].DurationToYear ===
                  recruitModal.employerDetails[idx].DurationFromYear &&
                recruitModal.employerDetails[idx - 1].DurationToMonth >
                  value.DurationFromMonth
                ? "From Month should greater than previous companies To Month"
                : recruitModal.employerDetails[idx].DurationFromYear ===
                    recruitModal.employerDetails[idx].DurationToYear &&
                  value.DurationFromMonth >=
                    recruitModal.employerDetails[idx].DurationToMonth
                ? "From Month should less than To Month"
                : ""
              : ""
            : "Please select From Month";

        break;

      case "toMonth-" + idx:
        recruitModal.employerDetails[idx].DurationToMonth =
          value.DurationToMonth;
        if (
          recruitModal.employerDetails[idx].DurationFromMonth > 0 &&
          recruitModal.employerDetails[idx].DurationFromYear > 0 &&
          recruitModal.employerDetails[idx].DurationToMonth > 0 &&
          recruitModal.employerDetails[idx].DurationToYear > 0
        ) {
          recruitModal.employerDetails[idx].ShowExperience = true;
        } else {
          recruitModal.employerDetails[idx].ShowExperience = false;
        }

        recruitFormErrors.employerDetailsError[idx].durationToMonth =
          value.DurationToMonth > 0
            ? recruitModal.employerDetails[idx].DurationToYear ===
              todayDate.getFullYear()
              ? todayDate.getMonth() + 1 < value.DurationToMonth
                ? "To Month should less than current calendar Month"
                : ""
              : recruitModal.employerDetails[idx - 1] != undefined
              ? recruitModal.employerDetails[idx - 1].DurationToYear ===
                  recruitModal.employerDetails[idx].DurationFromYear &&
                recruitModal.employerDetails[idx].DurationFromMonth <
                  recruitModal.employerDetails[idx - 1].DurationToMonth
                ? "From Month should greater than previous companies To Month"
                : recruitModal.employerDetails[idx].DurationFromYear ===
                    recruitModal.employerDetails[idx].DurationToYear &&
                  recruitModal.employerDetails[idx].DurationFromMonth >=
                    value.DurationToMonth
                ? "To Month should greater than From Month"
                : recruitModal.employerDetails[idx + 1] != undefined
                ? recruitModal.employerDetails[idx + 1].DurationFromYear ===
                    recruitModal.employerDetails[idx].DurationToYear &&
                  recruitModal.employerDetails[idx + 1].DurationFromMonth <
                    recruitModal.employerDetails[idx].DurationToMonth
                  ? "To Month should less than next companies From Month"
                  : ""
                : ""
              : recruitModal.employerDetails[idx + 1] != undefined
              ? recruitModal.employerDetails[idx + 1].DurationFromYear ===
                  recruitModal.employerDetails[idx].DurationToYear &&
                recruitModal.employerDetails[idx + 1].DurationFromMonth <
                  recruitModal.employerDetails[idx].DurationToMonth
                ? "To Month should less than next companies From Month"
                : ""
              : ""
            : "Please select To Month";
        break;

      case "toYear-" + idx:
        recruitModal.employerDetails[idx].DurationToYear = value.DurationToYear;
        if (
          recruitModal.employerDetails[idx].DurationFromMonth > 0 &&
          recruitModal.employerDetails[idx].DurationFromYear > 0 &&
          recruitModal.employerDetails[idx].DurationToMonth > 0 &&
          recruitModal.employerDetails[idx].DurationToYear > 0
        ) {
          recruitModal.employerDetails[idx].ShowExperience = true;
        } else {
          recruitModal.employerDetails[idx].ShowExperience = false;
        }

        recruitFormErrors.employerDetailsError[idx].durationFromYear =
          recruitModal.employerDetails[idx].DurationFromYear > 0
            ? recruitModal.employerDetails[idx].DurationFromYear <=
              value.DurationToYear
              ? recruitModal.employerDetails[idx - 1] != undefined
                ? recruitModal.employerDetails[idx - 1].DurationToYear >
                  recruitModal.employerDetails[idx].DurationFromYear
                  ? "From Year should greater than previous companies To Year"
                  : ""
                : ""
              : "From Year should less than To Year"
            : "Please select From Year";

        recruitFormErrors.employerDetailsError[idx].durationToYear =
          value.DurationToYear > 0
            ? value.DurationToYear >=
              recruitModal.employerDetails[idx].DurationFromYear
              ? recruitModal.employerDetails[idx + 1] != undefined
                ? recruitModal.employerDetails[idx].DurationToMonth === 12 &&
                  recruitModal.employerDetails[idx].DurationToYear >=
                    recruitModal.employerDetails[idx + 1].DurationFromYear
                  ? "To Year should less than next companies From Year"
                  : recruitModal.employerDetails[idx].DurationToYear >
                    recruitModal.employerDetails[idx + 1].DurationFromYear
                  ? "To Year should less than next companies From Year"
                  : ""
                : ""
              : "To Year should greater than From Year"
            : "Please select To Year";

        recruitFormErrors.employerDetailsError[idx].durationToMonth =
          recruitModal.employerDetails[idx].DurationToMonth > 0
            ? recruitModal.employerDetails[idx].DurationToYear ===
              todayDate.getFullYear()
              ? todayDate.getMonth() + 1 <
                recruitModal.employerDetails[idx].DurationToMonth
                ? "To Month should less than current calendar Month"
                : ""
              : recruitModal.employerDetails[idx].DurationFromMonth >
                recruitModal.employerDetails[idx].DurationToMonth
              ? recruitModal.employerDetails[idx].DurationFromYear <
                recruitModal.employerDetails[idx].DurationToYear
                ? ""
                : "To Month should greater than From Month"
              : ""
            : "Please select To Month";
        break;
    }
    this.setState(
      {
        recruitFormErrors,
        recruitModal,
      },
      () => this.setExperience(name),
    );
  };

  setExperience = (name) => {
    const recruitModal = this.state.recruitModal;
    var idx = name.split("-")[1];
    var FromDate =
      "01/" +
      recruitModal.employerDetails[idx].DurationFromMonth +
      "/" +
      recruitModal.employerDetails[idx].DurationFromYear;

    var ToDate =
      "01/" +
      recruitModal.employerDetails[idx].DurationToMonth +
      "/" +
      recruitModal.employerDetails[idx].DurationToYear;

    var df = new Date(FromDate);
    var dt = new Date(ToDate);
    var startMonth =
      df.getFullYear() * 12 +
      recruitModal.employerDetails[idx].DurationFromMonth;
    var endMonth =
      dt.getFullYear() * 12 + recruitModal.employerDetails[idx].DurationToMonth;
    var monthInterval = endMonth - startMonth;
    var yearsOfExperience = Math.floor(monthInterval / 12);
    var monthsOfExperience = monthInterval % 12;

    yearsOfExperience >= 0 && monthsOfExperience >= 0
      ? (recruitModal.employerDetails[idx].Experience = recruitModal
          .employerDetails[idx].ShowExperience
          ? yearsOfExperience +
            "." +
            (monthsOfExperience >= 10
              ? monthsOfExperience
              : "0" + monthsOfExperience) +
            ""
          : "")
      : (recruitModal.employerDetails[idx].Experience = "");
    this.setState({ recruitModal });
  };

  handleAddEmployerRow = () => {
    if (employerFormValid(this.state)) {
      const recruitFormErrors = this.state.recruitFormErrors;
      const recruitModal = this.state.recruitModal;
      recruitModal.employerDetails = [
        ...recruitModal.employerDetails,
        {
          companyName: "",
          DurationFromYear: 0,
          DurationFromMonth: 0,
          DurationToYear: 0,
          DurationToMonth: 0,
          Experience: "",
          ShowExperience: false,
        },
      ];
      recruitFormErrors.employerDetailsError = [
        ...recruitFormErrors.employerDetailsError,
        {
          companyName: "",
          durationFromYear: 0,
          durationFromMonth: 0,
          durationToYear: 0,
          durationToMonth: 0,
        },
      ];
      this.setState({
        recruitFormErrors,
        recruitModal,
      });
    } else {
      this.setState(
        {
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: CompnayErrorMessage,
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        },
      );
      this.showNotification(this.state);
    }
  };

  handleRemoveEmployerRow = (idx) => () => {
    const { employerDetails } = this.state.recruitModal;
    const { employerDetailsError } = this.state.recruitFormErrors;
    employerDetails.splice(idx, 1);
    employerDetailsError.splice(idx, 1);
    this.setState({
      employerDetailsError,
      employerDetails,
    });
  };

  handleChangeCommentType = (e) => {
    const recruitFormErrors = this.state.recruitFormErrors;
    const recruitModal = this.state.recruitModal;
    var target = e.target;
    var value = target.value;
    var name = target.name;
    recruitFormErrors.TechnicalComment = "";
    recruitFormErrors.TechnicalOtherComment = "";
    recruitFormErrors.PracticalComment = "";
    recruitFormErrors.PracticalOtherComment = "";
    recruitFormErrors.PracticalDate = "";
    recruitFormErrors.HRComment = "";
    recruitFormErrors.HRInterviewDate = "";
    recruitModal.commentType = value.CommentTypeID;
    this.setState({
      recruitModal,
      recruitFormErrors,
    });
  };

  handleSaveTechnicalComment = async (e) => {
    e.preventDefault();
    if (technicalCommentFormValid(this.state)) {
      this.setState({ isLoading: true });
      await addTechnicalComment(this.state.recruitModal).then((response) => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: response.data.ResponseMsg,
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );
          } else if (response.data.ResponseStatus === 1) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "success",
                alertMessage: response.data.ResponseMsg,
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );

            const recruitModal = this.state.recruitModal;
            recruitModal.commentType = 0;
            recruitModal.TechnicalComment = "";
            recruitModal.TechnicalOtherComment = "";
            recruitModal.commentDetails = response.data.commentDetails;
            recruitModal.commentDetailsWithoutHRComments =
              response.data.commentDetailsWithoutHRComments;
            this.setState({ recruitModal });
          } else {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage"),
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );
          }
        }
      });
    } else {
      this.setState(
        {
          isLoading: false,
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: EnterProperTechnicalComment,
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        },
      );
    }
  };

  handleSavePracticalComment = async (e) => {
    e.preventDefault();
    if (practicalCommentFormValid(this.state)) {
      this.setState({ isLoading: true });
      await addPracticalComment(this.state.recruitModal).then((response) => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: response.data.ResponseMsg,
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );
          } else if (response.data.ResponseStatus === 1) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "success",
                alertMessage: response.data.ResponseMsg,
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );
            const recruitModal = this.state.recruitModal;
            recruitModal.commentType = 0;
            recruitModal.PracticalComment = "";
            recruitModal.PracticalOtherComment = "";
            recruitModal.PracticalDate = new Date();
            recruitModal.commentDetails = response.data.commentDetails;
            recruitModal.commentDetailsWithoutHRComments =
              response.data.commentDetailsWithoutHRComments;
            this.setState({ recruitModal });
          } else {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage"),
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );
          }
        }
      });
    } else {
      this.setState(
        {
          isLoading: false,
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: EnterProperPracticalComment,
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        },
      );
    }
  };

  handleSaveHRComment = async (e) => {
    e.preventDefault();
    if (hrCommentFormValid(this.state)) {
      this.setState({ isLoading: true });
      await addHRComment(this.state.recruitModal).then((response) => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: response.data.ResponseMsg,
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );
          } else if (response.data.ResponseStatus === 1) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "success",
                alertMessage: response.data.ResponseMsg,
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );
            const recruitModal = this.state.recruitModal;
            recruitModal.commentType = 0;
            recruitModal.HRComment = "";
            recruitModal.HRInterviewDate = new Date();
            recruitModal.commentDetails = response.data.commentDetails;
            this.setState({ recruitModal });
          } else {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage"),
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );
          }
        }
      });
    } else {
      this.setState(
        {
          isLoading: false,
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: EnterProperHRComment,
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        },
      );
    }
  };

  resetForm = () => {
    this.props.history.push("/recruitportal/view");
  };

  hideReferenceName = () => {
    const recruitModal = this.state.recruitModal;
    recruitModal.addNewReference = false;
    this.setState({
      recruitModal,
      referenceDropdown: this.state.tempReferenceData,
    });
  };

  getIndex(list, ID, props) {
    for (var i = 0; i < list.length; i++) {
      if (list[i][props] === ID) {
        return i;
      }
    }
  }

  showNotification(state) {
    new Noty({
      type: state.alertColor,
      theme: "bootstrap-v4",
      layout: "topRight",
      closeWith: ["button"],
      text: state.alertMessage,
      timeout: state.notyTimeOut,
    }).show();
  }

  showAllComments = () => {
    this.props.history.push(
      "/recruitportal/showcomments:" +
        cryptr.encrypt(this.state.recruitData.RecruitMasterID),
    );
  };

  itemRender = (li, itemProps) => {
    const itemChildren = (
      <span>
        <input
          type="checkbox"
          checked={itemProps.selected}
          onChange={() => {}}
        />
        &nbsp;{li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  //Added By Tarangini Alva 30.12.2019 For Update Recruit Image
  handleSaveImage = async (e) => {
    e.preventDefault();
    var { recruitModal } = this.state;
    recruitModal.CandidateImageActual = recruitModal.CandidateImage;
    if (CandidateImageValid(this.state)) {
      this.setState({ isLoading: true, recruitModal });

      await UpdateRecruitImage(this.state.recruitModal).then((response) => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage"),
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            },
          );
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: response.data.ResponseMsg,
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );
          } else if (response.data.ResponseStatus === 1) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "success",
                alertMessage: response.data.ResponseMsg,
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );

            const recruitModal = this.state.recruitModal;
            recruitModal.ChangePhoto = false;
            recruitModal.isCamera = false;
            this.setState({ recruitModal });
          } else {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage"),
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              },
            );
          }
        }
      });
    }
  };
  //Added By Tarangini Alva 30.12.2019 For Update Recruit Image

  //Added By Tarangini Alva 01.01.2020 from Recruit Image cropping
  setCropInit = () => {
    var { crop } = this.state;
    crop.unit = "px";
    crop.width = 230;
    crop.height = 230;
    crop.aspect = 1 / 1;
    this.setState({
      crop,
    });
  };

  toggleDialog = () => {
    var { recruitModal } = this.state;
    if (recruitModal.CandidateImageBase64 != "") {
      recruitModal.CandidateImage = recruitModal.CandidateImageBase64;
    }

    this.setState({
      visibleDialog: !this.state.visibleDialog,
      //recruitModal
    });
  };

  toggleDialogClose = () => {
    var { recruitModal } = this.state;
    recruitModal.CandidateImage = recruitModal.CandidateImageActual;

    this.setState({
      Cropsrc: "",
      visibleDialog: !this.state.visibleDialog,
      recruitModal,
    });
  };

  getSelectedImageBase64 = (base64Image) => {
    var { recruitModal } = this.state;
    recruitModal.CandidateImageBase64 = base64Image;

    this.setState({
      recruitModal,
      // IsProfilePicValid: true
    });
  };

  rotateBase64Image90deg = (isClockwise) => {
    this.setState({ rotateFlag: false });
    var base64Image = this.state.Cropsrc;
    // create an off-screen canvas

    // cteate Image
    var img = new Image();
    img.src = base64Image;
    img.onload = function () {
      var offScreenCanvas = document.createElement("canvas");
      var offScreenCanvasCtx = offScreenCanvas.getContext("2d");
      // set its dimension to rotated size
      offScreenCanvas.height = img.width;
      offScreenCanvas.width = img.height;

      // rotate and draw source image into the off-screen canvas:
      if (isClockwise) {
        offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
        offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
      }

      offScreenCanvasCtx.drawImage(img, 0, 0);

      // encode image to data-uri with base64
      const data = offScreenCanvas.toDataURL();
      this.setState({
        Cropsrc: data,
      });
    }.bind(this);
  };

  //Added By Tarangini Alva 01.01.2020 from Recruit Image cropping

  render() {
    const videoConstraints = {
      width: 230,
      height: 230,
      facingMode: "user",
    };
    const recruitFormErrors = this.state.recruitFormErrors;
    const recruitModal = this.state.recruitModal;
    const { crop, Cropsrc } = this.state;
    const recruiteForm = (
      <Form id="recruitFormID" noValidate>
        {this.state.userType === "xRGjRCA3M94%3d" ||
        this.state.userType === "6QjgBiG4ZRU%3d" ? (
          <React.Fragment>
            <div className="row">
              <div className="persondetailwrap">
                {this.state.isWebCamOpen ? (
                  <div className="userpic_box">
                    <div className="images_userpic">
                      <Webcam
                        audio={false}
                        height={230}
                        ref={this.setRef}
                        screenshotFormat="image/jpeg"
                        width={230}
                        videoConstraints={videoConstraints}
                      />
                    </div>
                    <a
                      className="camera_icon"
                      onClick={this.capture}
                      title="Capture Image"
                    />
                    <div className="uploadbut">
                      <input
                        type="file"
                        className="picupload"
                        id="photoUpload"
                        title="Upload Image"
                        name="photoUpload"
                        value=""
                        accept=".jpeg, .jpg, .png"
                        onChange={(e) => this._handleImageChange(e)}
                      />
                    </div>
                    <div className="col-md-3" id="DivSaveImage">
                      <small className="align_right bot">
                        Please upload .jpeg, .jpg, .png file upto 2 MB
                      </small>
                      <div className="saveImgButton">
                        {(this.state.recruitModal.ChangePhoto === true ||
                          this.state.recruitModal.isCamera === true) &&
                        this.state.isLoading == false ? (
                          <button
                            className="submit"
                            type="button"
                            id="saveImage"
                            onClick={this.handleSaveImage}
                          >
                            Save Image
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="userpic_box">
                    <div className="images_userpic">
                      <img
                        style={{ height: "230px", width: "230px" }}
                        src={
                          recruitModal.CandidateImage === ""
                            ? noImage
                            : recruitModal.CandidateImage
                        }
                        alt=""
                      />
                    </div>
                    <a
                      className="camera_icon"
                      onClick={this.openWebCam}
                      title="Capture Image"
                    ></a>
                    <div className="uploadbut">
                      <input
                        type="file"
                        className="picupload"
                        id="photoUpload"
                        title="Upload Image"
                        name="photoUpload"
                        value=""
                        accept=".jpeg, .jpg, .png"
                        onChange={(e) => this._handleImageChange(e)}
                      />
                    </div>
                    <div className="col-md-3" id="DivSaveImage">
                      <small className="align_right bot">
                        Please upload .jpeg, .jpg, .png file upto 2 MB
                      </small>
                      <div className="saveImgButton">
                        {(this.state.recruitModal.ChangePhoto === true ||
                          this.state.recruitModal.isCamera === true) &&
                        this.state.isLoading == false ? (
                          <button
                            className="submit"
                            id="saveImage"
                            type="button"
                            onClick={this.handleSaveImage}
                          >
                            Save Image
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="personal_from">
                  <div className="col-md-12">
                    <h3>Personal Details</h3>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-6 col-lg-4">
                      <small className="align_right bot labelclass">
                        Select Main Technology
                      </small>
                      <div
                        className={
                          recruitFormErrors.TechnologyMasterID.length > 0
                            ? "field_text fieldErrorMessage"
                            : "field_text"
                        }
                      >
                        <DropDownList
                          className="selectbox"
                          name="mainTechnology"
                          data={this.state.technologyDropdown}
                          textField="Description"
                          dataItemKey="TechnologyMasterID"
                          defaultItem={this.defaultTechnologyItem}
                          value={
                            this.state.technologyDropdown[
                              this.getIndex(
                                this.state.technologyDropdown,
                                recruitModal.TechnologyMasterID,
                                "TechnologyMasterID",
                              )
                            ]
                          }
                          onChange={this.handleChange}
                          filterable={true}
                          onFilterChange={this.filterChangeTechnology}
                          loading={this.state.loading}
                        />
                        <span className="star">*</span>
                      </div>
                      {recruitFormErrors.TechnologyMasterID.length > 0 && (
                        <span className="errorMessage">
                          {recruitFormErrors.TechnologyMasterID}
                        </span>
                      )}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-lg-4">
                      <small className="align_right bot labelclass">
                        First Name
                      </small>
                      <div
                        className={
                          recruitFormErrors.FirstName.length > 0
                            ? "field_text fieldErrorMessage"
                            : "field_text"
                        }
                      >
                        <Input
                          type="text"
                          id="firstname"
                          name="firstname"
                          placeholder="First Name"
                          maxLength="75"
                          noValidate
                          value={recruitModal.FirstName}
                          onChange={this.handleChange}
                        />
                        <span className="star">*</span>
                      </div>
                      {recruitFormErrors.FirstName.length > 0 && (
                        <span className="errorMessage">
                          {recruitFormErrors.FirstName}
                        </span>
                      )}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-lg-4">
                      <small className="align_right bot labelclass">
                        Last Name
                      </small>
                      <div
                        className={
                          recruitFormErrors.LastName.length > 0
                            ? "field_text fieldErrorMessage"
                            : "field_text"
                        }
                      >
                        <Input
                          type="text"
                          id="lastname"
                          name="lastname"
                          placeholder="Last Name"
                          maxLength="75"
                          noValidate
                          value={recruitModal.LastName}
                          onChange={this.handleChange}
                        />
                        <span className="star">*</span>
                      </div>
                      {recruitFormErrors.LastName.length > 0 && (
                        <span className="errorMessage">
                          {recruitFormErrors.LastName}
                        </span>
                      )}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-lg-4">
                      <small className="align_right bot labelclass">
                        Email
                      </small>
                      <div
                        className={
                          recruitFormErrors.Email.length > 0
                            ? "field_text fieldErrorMessage"
                            : "field_text"
                        }
                      >
                        <Input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email"
                          maxLength="320"
                          noValidate
                          value={recruitModal.Email}
                          onChange={this.handleChange}
                        />
                        <span className="star">*</span>
                      </div>
                      {recruitFormErrors.Email.length > 0 && (
                        <span className="errorMessage">
                          {recruitFormErrors.Email}
                        </span>
                      )}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-lg-4">
                      <small className="align_right bot labelclass">
                        Mobile No.
                      </small>
                      <div
                        className={
                          recruitFormErrors.Mobile.length > 0
                            ? "field_text fieldErrorMessage"
                            : "field_text"
                        }
                      >
                        <Input
                          type="text"
                          id="mobile"
                          name="mobile"
                          placeholder="Mobile No."
                          minLength="10"
                          maxLength="15"
                          noValidate
                          value={recruitModal.Mobile}
                          onChange={this.handleChange}
                        />
                        <span className="star">*</span>
                      </div>
                      {recruitFormErrors.Mobile.length > 0 && (
                        <span className="errorMessage">
                          {recruitFormErrors.Mobile}
                        </span>
                      )}
                    </div>
                    {this.state.recruitModal.addNewReference ? (
                      <React.Fragment>
                        <div className="col-md-3">
                          <small className="align_right bot labelclass">
                            Enter Reference
                          </small>
                          <div
                            className={
                              recruitFormErrors.ReferenceName.length > 0
                                ? "field_text fieldErrorMessage"
                                : "field_text"
                            }
                          >
                            <Input
                              type="text"
                              id="referenceName"
                              name="referenceName"
                              placeholder="Reference Name"
                              maxLength="150"
                              noValidate
                              value={recruitModal.ReferenceName}
                              onChange={this.handleChange}
                            />
                          </div>
                          {recruitFormErrors.ReferenceName.length > 0 && (
                            <span className="errorMessage">
                              {recruitFormErrors.ReferenceName}
                            </span>
                          )}
                        </div>
                        <div className="col-md-1">
                          <div className="field_text">
                            <button
                              style={{
                                padding: "8px 10px",
                                margin: "0 0 0 0",
                              }}
                              className="cancel changebtn"
                              type="button"
                              onClick={this.hideReferenceName}
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="col-xs-12 col-sm-6 col-lg-4">
                        <small className="align_right bot labelclass">
                          Select Reference
                        </small>
                        <div className="field_text">
                          <DropDownList
                            className="selectbox"
                            name="reference"
                            data={this.state.referenceDropdown}
                            textField="Description"
                            dataItemKey="ReferenceMasterID"
                            defaultItem={this.defaultReferenceItem}
                            value={
                              this.state.referenceDropdown[
                                this.getIndex(
                                  this.state.referenceDropdown,
                                  recruitModal.ReferenceMasterID,
                                  "ReferenceMasterID",
                                )
                              ]
                            }
                            onChange={this.handleChange}
                            filterable={true}
                            onFilterChange={this.filterChangeReference}
                            loading={this.state.loading}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3>Interviewer Details</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <small className="align_right bot labelclass">
                  Interview Taken By
                </small>
                {recruitModal.InterviewTakenByOld === "" ? (
                  ""
                ) : (
                  <React.Fragment>
                    <span
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        margin: "7px 15px 0px 0px",

                        float: "right",
                        border: "solid 1px red",
                      }}
                      href="#"
                      id="InterviewTakenByOld"
                    >
                      <a
                        target="_blank"
                        title="Show old data"
                        className="glyphicon-info glyphicon"
                        style={{ right: "15px", top: "7px" }}
                      ></a>
                    </span>
                    <UncontrolledTooltip
                      style={{ right: "15px", top: "7px" }}
                      placement="top"
                      target="InterviewTakenByOld"
                    >
                      {recruitModal.InterviewTakenByOld}
                    </UncontrolledTooltip>
                  </React.Fragment>
                )}
                <div
                  className={
                    this.state.userType !== "6QjgBiG4ZRU%3d"
                      ? recruitFormErrors.InterviewTakenBy.length > 0
                        ? "field_text fieldErrorMessage"
                        : "field_text"
                      : "field_text"
                  }
                >
                  <MultiSelect
                    name="interviewTakenBy"
                    className="form-control"
                    data={this.state.userDropdown}
                    itemRender={this.itemRender}
                    autoClose={false}
                    textField="Name"
                    dataItemKey="UserMasterID"
                    filterable={true}
                    onFilterChange={this.filterChangeInterviewTakenBy}
                    placeholder="Interview Taken By"
                    value={recruitModal.InterviewTakenBy}
                    onChange={this.handleChange}
                  />
                  {this.state.userType !== "6QjgBiG4ZRU%3d" ? (
                    <span className="star">*</span>
                  ) : (
                    ""
                  )}
                </div>
                {this.state.userType !== "6QjgBiG4ZRU%3d"
                  ? recruitFormErrors.InterviewTakenBy.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.InterviewTakenBy}
                      </span>
                    )
                  : ""}
              </div>
              <div className="col-md-4">
                <small className="align_right bot labelclass">
                  Interview Date
                </small>
                <div className="field_text">
                  <DatePicker
                    format={"dd/MM/yyyy"}
                    name="interviewDate"
                    // min={new Date()}
                    defaultValue={new Date()}
                    value={recruitModal.InterviewDate}
                    onChange={this.handleChange}
                    width="96%"
                  />
                  <span className="star">*</span>
                </div>
                {recruitFormErrors.InterviewDate.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.InterviewDate}
                  </span>
                )}
              </div>
              <div className="col-md-4">
                <small className="align_right bot labelclass">
                  Suitable Status
                </small>
                <div
                  className={
                    recruitFormErrors.StatusMasterID.length > 0
                      ? "field_text fieldErrorMessage"
                      : "field_text"
                  }
                >
                  <DropDownList
                    className="selectbox"
                    name="mainSuitableStatus"
                    data={this.state.statusDropdown}
                    textField="Description"
                    dataItemKey="StatusMasterID"
                    defaultItem={this.defaultStatusItem}
                    value={
                      this.state.statusDropdown[
                        this.getIndex(
                          this.state.statusDropdown,
                          recruitModal.StatusMasterID,
                          "StatusMasterID",
                        )
                      ]
                    }
                    onChange={this.handleChange}
                    filterable={true}
                    onFilterChange={this.filterChangeStatus}
                    loading={this.state.loading}
                  />
                  <span className="star">*</span>
                </div>
                {recruitFormErrors.StatusMasterID.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.StatusMasterID}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <small className="align_right bot labelclass">
                  Joining Date
                </small>
                <div className="field_text">
                  <DatePicker
                    format={"dd/MM/yyyy"}
                    name="dateOfJoining"
                    // min={new Date()}
                    min={recruitModal.InterviewDate}
                    defaultValue={new Date()}
                    value={recruitModal.DateOfJoining}
                    onChange={this.handleChange}
                    width="96%"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <small className="align_right bot labelclass">Agreed CTC</small>
                <div
                  className={
                    recruitFormErrors.AgreeCTC.length > 0
                      ? "field_text fieldErrorMessage"
                      : "field_text"
                  }
                >
                  <Input
                    type="text"
                    id="agreeCTC"
                    name="agreeCTC"
                    className="form-control"
                    placeholder="Agreed CTC"
                    maxLength="10"
                    noValidate
                    value={
                      recruitModal.AgreeCTC == 0.0 ? "" : recruitModal.AgreeCTC
                    }
                    onChange={this.handleChange}
                  />
                </div>
                {recruitFormErrors.AgreeCTC.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.AgreeCTC}
                  </span>
                )}
              </div>
              <div className="col-md-4 radio_btn_part">
                <div className="field_text">
                  <span className="field_texttitle">Ready for Bond</span>
                  {recruitModal.ReadyForBond ? (
                    <React.Fragment>
                      <div className="radiobut">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="readyForBondYes"
                            value={true}
                            name="readyForBond"
                            defaultChecked="checked"
                            onChange={this.handleChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="readyForBondYes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="readyForBondNo"
                            value={false}
                            name="readyForBond"
                            onChange={this.handleChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="readyForBondNo"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : recruitModal.ReadyForBond === null ? (
                    <React.Fragment>
                      <div className="radiobut">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="readyForBondYes"
                            value={true}
                            name="readyForBond"
                            onChange={this.handleChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="readyForBondYes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="readyForBondNo"
                            value={false}
                            name="readyForBond"
                            onChange={this.handleChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="readyForBondNo"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="radiobut">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="readyForBondYes"
                            value={true}
                            name="readyForBond"
                            onChange={this.handleChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="readyForBondYes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="readyForBondNo"
                            value={false}
                            name="readyForBond"
                            defaultChecked="checked"
                            onChange={this.handleChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="readyForBondNo"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <small className="align_right bot labelclass">Reason</small>
                <div
                  className={
                    recruitFormErrors.Reason.length > 0
                      ? "field_text fieldErrorMessage"
                      : "field_text"
                  }
                >
                  <Input
                    type="textarea"
                    name="reason"
                    placeholder="Reason"
                    maxLength="2000"
                    style={{ height: "175px" }}
                    noValidate
                    value={recruitModal.Reason}
                    onChange={this.handleChange}
                  />
                </div>
                {recruitFormErrors.Reason.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.Reason}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3>Comment</h3>
                <a
                  target="_blank"
                  title="Show all Comments"
                  className="glyphicon-info glyphicon"
                  style={{
                    height: "23px",
                    float: "right",
                    margin: "72px 0 0 0",
                    cursor: "pointer",
                  }}
                  onClick={this.showAllComments}
                ></a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="field_text">
                  <DropDownList
                    className="selectbox"
                    name="commentType"
                    data={this.commentType}
                    textField="Value"
                    dataItemKey="CommentTypeID"
                    defaultItem={this.defaultComment}
                    onChange={this.handleChangeCommentType}
                    value={this.commentType[-1]}
                  />
                </div>
              </div>
            </div>
            {this.state.recruitModal.commentType === 1 ? (
              <React.Fragment>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className={
                        recruitFormErrors.TechnicalComment.length > 0
                          ? "field_text fieldErrorMessage"
                          : "field_text"
                      }
                    >
                      <Input
                        type="textarea"
                        name="techComment"
                        placeholder="Technical Comment"
                        maxLength="4000"
                        style={{ height: "175px" }}
                        noValidate
                        value={recruitModal.TechnicalComment}
                        onChange={this.handleChange}
                      />
                      <span className="star">*</span>
                    </div>
                    {recruitFormErrors.TechnicalComment.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.TechnicalComment}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div
                      className={
                        recruitFormErrors.TechnicalOtherComment.length > 0
                          ? "field_text fieldErrorMessage"
                          : "field_text"
                      }
                    >
                      <Input
                        type="textarea"
                        name="techOtherComment"
                        placeholder="Technical Other Comment"
                        maxLength="4000"
                        style={{ height: "175px" }}
                        noValidate
                        value={recruitModal.TechnicalOtherComment}
                        onChange={this.handleChange}
                      />
                    </div>
                    {recruitFormErrors.TechnicalOtherComment.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.TechnicalOtherComment}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 mb-4">
                    {this.state.isLoading ? (
                      <button
                        disabled="disabled"
                        className="submit"
                        type="button"
                      >
                        Save Comment
                      </button>
                    ) : (
                      <button
                        className="submit"
                        type="button"
                        onClick={this.handleSaveTechnicalComment}
                      >
                        Save Comment
                      </button>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : this.state.recruitModal.commentType === 2 ? (
              <React.Fragment>
                <div className="row">
                  <div className="col-md-5">
                    <div
                      className={
                        recruitFormErrors.PracticalComment.length > 0
                          ? "field_text fieldErrorMessage"
                          : "field_text"
                      }
                    >
                      <Input
                        type="textarea"
                        name="practicalComment"
                        placeholder="Practical Comment"
                        maxLength="4000"
                        style={{ height: "175px" }}
                        noValidate
                        value={recruitModal.PracticalComment}
                        onChange={this.handleChange}
                      />
                      <span className="star">*</span>
                    </div>
                    {recruitFormErrors.PracticalComment.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.PracticalComment}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div
                      className={
                        recruitFormErrors.PracticalOtherComment.length > 0
                          ? "field_text fieldErrorMessage"
                          : "field_text"
                      }
                    >
                      <Input
                        type="textarea"
                        name="practicalOtherComment"
                        placeholder="Practical Other Comment"
                        maxLength="4000"
                        style={{ height: "175px" }}
                        noValidate
                        value={recruitModal.PracticalOtherComment}
                        onChange={this.handleChange}
                      />
                    </div>
                    {recruitFormErrors.PracticalOtherComment.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.PracticalOtherComment}
                      </span>
                    )}
                  </div>
                  <div className="col-md-3">
                    <div
                      className={
                        recruitFormErrors.PracticalDate.length > 0
                          ? "field_text fieldErrorMessage"
                          : "field_text"
                      }
                    >
                      <DatePicker
                        format={"dd/MM/yyyy"}
                        name="practicalDate"
                        defaultValue={recruitModal.InterviewDate}
                        value={recruitModal.PracticalDate}
                        onChange={this.handleChange}
                        width="96%"
                      />
                      <span className="star">*</span>
                      <small
                        className="align_right bot"
                        style={{ color: "#303c48" }}
                      >
                        Practical Date
                      </small>
                    </div>
                    {recruitFormErrors.PracticalDate.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.PracticalDate}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 mb-4">
                    {this.state.isLoading ? (
                      <button
                        disabled="disabled"
                        className="submit"
                        type="button"
                      >
                        Save Comment
                      </button>
                    ) : (
                      <button
                        className="submit"
                        type="button"
                        onClick={this.handleSavePracticalComment}
                      >
                        Save Comment
                      </button>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : this.state.recruitModal.commentType === 3 ? (
              <React.Fragment>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className={
                        recruitFormErrors.HRComment.length > 0
                          ? "field_text fieldErrorMessage"
                          : "field_text"
                      }
                    >
                      <Input
                        type="textarea"
                        name="hrComment"
                        placeholder="HR Comment"
                        maxLength="4000"
                        style={{ height: "175px" }}
                        noValidate
                        value={recruitModal.HRComment}
                        onChange={this.handleChange}
                      />
                      <span className="star">*</span>
                    </div>
                    {recruitFormErrors.HRComment.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.HRComment}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div
                      className={
                        recruitFormErrors.HRInterviewDate.length > 0
                          ? "field_text fieldErrorMessage"
                          : "field_text"
                      }
                    >
                      <DatePicker
                        format={"dd/MM/yyyy"}
                        name="hrInterviewDate"
                        defaultValue={recruitModal.InterviewDate}
                        value={recruitModal.HRInterviewDate}
                        onChange={this.handleChange}
                        width="96%"
                      />
                      <span className="star">*</span>
                      <small
                        className="align_right bot"
                        style={{ color: "#303c48" }}
                      >
                        HR Interview Date
                      </small>
                    </div>
                    {recruitFormErrors.HRInterviewDate.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.HRInterviewDate}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 mb-4">
                    {this.state.isLoading ? (
                      <button
                        disabled="disabled"
                        className="submit"
                        type="button"
                      >
                        Save Comment
                      </button>
                    ) : (
                      <button
                        className="submit"
                        type="button"
                        onClick={this.handleSaveHRComment}
                      >
                        Save Comment
                      </button>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
            <div className="mt-4 table-responsive flexi-table">
              <table className="table table-striped table-sm">
                <thead>
                  <tr
                    style={{
                      background: "#226db4",
                      color: "#fff",
                      borderRadius: "7px 7px 0 0",
                    }}
                  >
                    <th style={{ width: "15%" }}>Type</th>
                    <th style={{ width: "40%" }}>Comment</th>
                    <th style={{ width: "30%" }}>Other Comment</th>
                    <th style={{ width: "15%" }}>Added By</th>
                    <th style={{ width: "5%" }}>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {recruitModal.commentDetails.map((item, idx) => (
                    <tr id={"commentDetails-" + idx} key={idx}>
                      <td>
                        {recruitModal.commentDetails[idx].CommentType === 1
                          ? "Technical"
                          : recruitModal.commentDetails[idx].CommentType === 2
                          ? "Practical"
                          : "HR"}
                      </td>
                      <td title={recruitModal.commentDetails[idx].Comment}
                        dangerouslySetInnerHTML={{
                          __html:
                            recruitModal.commentDetails[idx].Comment.replace(
                              /\<\/br>/g,
                              " ",
                            ).length > 40
                              ? recruitModal.commentDetails[
                                  idx
                                ].Comment.replace(/\<\/br>/g, " ").substring(
                                  0,
                                  40,
                                ) + "..."
                              : recruitModal.commentDetails[
                                  idx
                                ].Comment.replace(/\<\/br>/g, " "),
                        }}
                      />

                      <td title={recruitModal.commentDetails[idx].OtherComment}
                        dangerouslySetInnerHTML={{
                          __html:
                            recruitModal.commentDetails[idx].OtherComment !=
                            null
                              ? recruitModal.commentDetails[
                                  idx
                                ].OtherComment.replace(/\<\/br>/g, " ").length >
                                30
                                ? recruitModal.commentDetails[
                                    idx
                                  ].OtherComment.replace(
                                    /\<\/br>/g,
                                    " ",
                                  ).substring(0, 30) + "..."
                                : recruitModal.commentDetails[
                                    idx
                                  ].OtherComment.replace(/\<\/br>/g, " ")
                              : "",
                        }}
                      />
                      <td>{recruitModal.commentDetails[idx].Name}</td>
                      <td>
                        {recruitModal.commentDetails[idx].Date === null ? (
                          <Moment format="DD/MM/YYYY">
                            {recruitModal.commentDetails[idx].CreatedDate}
                          </Moment>
                        ) : (
                          <Moment format="DD/MM/YYYY">
                            {recruitModal.commentDetails[idx].Date}
                          </Moment>
                        )}
                      </td>
                    </tr>
                  ))}
                  {recruitModal.commentDetails.length === 0 ? (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan="5">
                        No Comments Found
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3>Qualification Details</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <small className="align_right bot labelclass">
                  Highest Qualification
                </small>
                <div
                  className={
                    recruitFormErrors.HighestQualification.length > 0
                      ? "field_text fieldErrorMessage"
                      : "field_text"
                  }
                >
                  <Input
                    type="text"
                    id="highestQualification"
                    name="highestQualification"
                    className="form-control"
                    placeholder="Highest Qualification"
                    maxLength="50"
                    noValidate
                    value={recruitModal.HighestQualification}
                    onChange={this.handleChange}
                  />
                  <span className="star">*</span>
                </div>
                {recruitFormErrors.HighestQualification.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.HighestQualification}
                  </span>
                )}
              </div>
              <div className="col-md-4">
                <small className="align_right bot labelclass">
                  Extra Certification
                </small>
                <div
                  className={
                    recruitFormErrors.ExtraCertification.length > 0
                      ? "field_text fieldErrorMessage"
                      : "field_text"
                  }
                >
                  <Input
                    type="text"
                    id="extraCertification"
                    name="extraCertification"
                    className="form-control"
                    placeholder="Extra Certification"
                    maxLength="400"
                    noValidate
                    value={recruitModal.ExtraCertification}
                    onChange={this.handleChange}
                  />
                </div>
                {recruitFormErrors.ExtraCertification.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.ExtraCertification}
                  </span>
                )}
              </div>
              <div className="col-md-4">
                <div>
                  <input
                    className="k-checkbox"
                    type="checkbox"
                    id="experienced"
                    checked={recruitModal.Experienced ? true : false}
                    name="experienced"
                    onChange={this.handleChange}
                  />
                  <label
                    htmlFor="experienced"
                    className="k-checkbox-label"
                    style={{
                      fontSize: "15px",
                      color: "#898989",
                      fontFamily: "Open Sans",
                    }}
                  >
                    Experienced ?
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3>
                  Technical Skils
                  <small
                    className="note_message"
                    style={{
                      textTransform: "none",
                      fontSize: "14px !important",
                    }}
                  >
                    Click on the "+" icon on the right to expand and add more
                    details.
                  </small>
                </h3>
                <a
                  className="glyphicon-plus glyphicon"
                  style={{
                    height: "23px",
                    float: "right",
                    margin: "72px 0 0 0",
                    cursor: "pointer",
                  }}
                  onClick={this.handleAddTechnologyRow}
                ></a>
              </div>
            </div>
            {recruitModal.technologies.map((item, idx) => (
              <div className="row" id={"technology-" + idx} key={idx}>
                <div className="col-md-3">
                  <small className="align_right bot labelclass">
                    Technology
                  </small>
                  <div className="field_text">
                    <Input
                      type="text"
                      data-id={idx}
                      id={"technology-" + idx}
                      name={"technology-" + idx}
                      className="form-control"
                      placeholder="Technology"
                      maxLength="50"
                      noValidate
                      value={recruitModal.technologies[idx].technology}
                      onChange={this.handleTechnologyChange(idx)}
                    />
                  </div>
                  {recruitFormErrors.technologiesError[idx].technology ===
                  undefined
                    ? ""
                    : recruitFormErrors.technologiesError[idx].technology
                        .length > 0 && (
                        <span className="errorMessage">
                          {recruitFormErrors.technologiesError[idx].technology}
                        </span>
                      )}
                </div>
                <div className="col-md-2">
                  <small className="align_right bot labelclass">Year(s)</small>
                  <div className="field_text">
                    <DropDownList
                      className="selectbox"
                      data-id={idx}
                      name={"experienceInYear-" + idx}
                      data={this.years}
                      textField="Value"
                      dataItemKey="ExperienceInYear"
                      defaultItem={this.defaultTechnologyYear}
                      value={
                        this.years[
                          recruitModal.technologies[idx].ExperienceInYear
                        ]
                      }
                      onChange={this.handleTechnologyChange(idx)}
                    />
                  </div>
                  {recruitFormErrors.technologiesError[idx].experienceInYear ===
                  undefined
                    ? ""
                    : recruitFormErrors.technologiesError[idx].experienceInYear
                        .length > 0 && (
                        <span className="errorMessage">
                          {
                            recruitFormErrors.technologiesError[idx]
                              .experienceInYear
                          }
                        </span>
                      )}
                </div>
                <div className="col-md-2">
                  <small className="align_right bot labelclass">Month(s)</small>
                  <div className="field_text">
                    <DropDownList
                      className="selectbox"
                      data-id={idx}
                      name={"experienceInMonth-" + idx}
                      data={this.months}
                      textField="Value"
                      dataItemKey="ExperienceInMonth"
                      defaultItem={this.defaultTechnologyMonth}
                      value={
                        this.months[
                          recruitModal.technologies[idx].ExperienceInMonth
                        ]
                      }
                      onChange={this.handleTechnologyChange(idx)}
                    />
                  </div>
                  {recruitFormErrors.technologiesError[idx]
                    .experienceInMonth === undefined
                    ? ""
                    : recruitFormErrors.technologiesError[idx].experienceInMonth
                        .length > 0 && (
                        <span className="errorMessage">
                          {
                            recruitFormErrors.technologiesError[idx]
                              .experienceInMonth
                          }
                        </span>
                      )}
                </div>
                {recruitModal.technologies[idx].Experience != null &&
                recruitModal.technologies[idx].Experience != "" ? (
                  <React.Fragment>
                    <div className="col-md-1">
                      <span
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          margin: "7px 15px 0px 0px",

                          float: "right",
                          border: "solid 1px red",
                        }}
                        href="#"
                        id={"experience-" + idx}
                      >
                        <a
                          target="_blank"
                          title="Show old data"
                          className="glyphicon-info glyphicon"
                          style={{ right: "15px", top: "7px" }}
                        ></a>
                      </span>
                      <UncontrolledTooltip
                        style={{ right: "15px", top: "7px" }}
                        placement="top"
                        target={"experience-" + idx}
                      >
                        {recruitModal.technologies[idx].Experience}
                      </UncontrolledTooltip>
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                )}
                {idx === 0 ? (
                  ""
                ) : (
                  <a
                    className="glyphicon-minus glyphicon"
                    onClick={this.handleRemoveTechnologyRow(idx)}
                  ></a>
                )}
              </div>
            ))}
            {recruitModal.technologies.length === 0 ? (
              <div className="row">
                <div
                  className="col-md-12 errorMessage"
                  style={{ textAlign: "center" }}
                >
                  {NoTechnologyFound}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-md-12">
                <h3>
                  Employment Details
                  <small
                    className="note_message"
                    style={{
                      textTransform: "none",
                      fontSize: "14px !important",
                    }}
                  >
                    Click on "+" icon on the right to expand and add more
                    details, starting from the First Job to the Current one.
                  </small>
                </h3>
                <a
                  className="glyphicon-plus glyphicon"
                  style={{
                    height: "23px",
                    float: "right",
                    margin: "72px 0 0 0",
                    cursor: "pointer",
                  }}
                  onClick={this.handleAddEmployerRow}
                ></a>
              </div>
            </div>
            {recruitModal.employerDetails.map((item, idx) => (
              <div className="row" id={"employment-" + idx} key={idx}>
                <div className="col-md-3">
                  <small className="align_right bot labelclass">
                    Company Name
                  </small>
                  {recruitModal.employerDetails[idx].Duration != null &&
                  recruitModal.employerDetails[idx].Duration != "" ? (
                    <React.Fragment>
                      <span
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          margin: "7px 15px 0px 0px",

                          float: "right",
                          border: "solid 1px red",
                        }}
                        href="#"
                        id={"duration-" + idx}
                      >
                        <a
                          target="_blank"
                          title="Show old data"
                          className="glyphicon-info glyphicon"
                          style={{ right: "15px", top: "7px" }}
                        ></a>
                      </span>
                      <UncontrolledTooltip
                        style={{ right: "15px", top: "7px" }}
                        placement="top"
                        target={"duration-" + idx}
                      >
                        {recruitModal.employerDetails[idx].Duration}
                      </UncontrolledTooltip>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  <div className="field_text">
                    <Input
                      type="text"
                      data-id={idx}
                      id={"companyName-" + idx}
                      name={"companyName-" + idx}
                      className="form-control"
                      placeholder="Company Name"
                      maxLength="200"
                      noValidate
                      value={recruitModal.employerDetails[idx].companyName}
                      onChange={this.handleEmployerChange(idx)}
                    />
                  </div>
                  {recruitFormErrors.employerDetailsError[idx].companyName ===
                  undefined
                    ? ""
                    : recruitFormErrors.employerDetailsError[idx].companyName
                        .length > 0 && (
                        <span className="errorMessage">
                          {
                            recruitFormErrors.employerDetailsError[idx]
                              .companyName
                          }
                        </span>
                      )}
                </div>
                <div className="col-md-2">
                  <small className="align_right bot labelclass">
                    From Month
                  </small>
                  <div className="field_text">
                    <DropDownList
                      className="selectbox"
                      data-id={idx}
                      name={"fromMonth-" + idx}
                      data={this.fromMonths}
                      textField="Value"
                      dataItemKey="DurationFromMonth"
                      defaultItem={this.defaultCompanyFromMonth}
                      value={
                        this.fromMonths[
                          recruitModal.employerDetails[idx].DurationFromMonth -
                            1
                        ]
                      }
                      onChange={this.handleEmployerChange(idx)}
                    />
                  </div>
                  {recruitFormErrors.employerDetailsError[idx]
                    .durationFromMonth === undefined
                    ? ""
                    : recruitFormErrors.employerDetailsError[idx]
                        .durationFromMonth.length > 0 && (
                        <span className="errorMessage">
                          {
                            recruitFormErrors.employerDetailsError[idx]
                              .durationFromMonth
                          }
                        </span>
                      )}
                </div>
                <div className="col-md-2">
                  <small className="align_right bot labelclass">
                    From Year
                  </small>
                  <div className="field_text">
                    <DropDownList
                      className="selectbox"
                      data-id={idx}
                      name={"fromYear-" + idx}
                      data={this.state.fromYears}
                      textField="Value"
                      dataItemKey="DurationFromYear"
                      defaultItem={this.defaultCompanyFromYear}
                      value={
                        this.state.fromYears[
                          recruitModal.employerDetails[idx].DurationFromYear -
                            1990
                        ]
                      }
                      onChange={this.handleEmployerChange(idx)}
                    />
                  </div>
                  {recruitFormErrors.employerDetailsError[idx]
                    .durationFromYear === undefined
                    ? ""
                    : recruitFormErrors.employerDetailsError[idx]
                        .durationFromYear.length > 0 && (
                        <span className="errorMessage">
                          {
                            recruitFormErrors.employerDetailsError[idx]
                              .durationFromYear
                          }
                        </span>
                      )}
                </div>
                <div className="col-md-2">
                  <small className="align_right bot labelclass">To Month</small>
                  <div className="field_text">
                    <DropDownList
                      className="selectbox"
                      data-id={idx}
                      name={"toMonth-" + idx}
                      data={this.toMonths}
                      textField="Value"
                      dataItemKey="DurationToMonth"
                      defaultItem={this.defaultCompanyToMonth}
                      value={
                        this.toMonths[
                          recruitModal.employerDetails[idx].DurationToMonth - 1
                        ]
                      }
                      onChange={this.handleEmployerChange(idx)}
                    />
                  </div>
                  {recruitFormErrors.employerDetailsError[idx]
                    .durationToMonth === undefined
                    ? ""
                    : recruitFormErrors.employerDetailsError[idx]
                        .durationToMonth.length > 0 && (
                        <span className="errorMessage">
                          {
                            recruitFormErrors.employerDetailsError[idx]
                              .durationToMonth
                          }
                        </span>
                      )}
                </div>
                <div className="col-md-2">
                  <small className="align_right bot labelclass">To Year</small>
                  <div className="field_text">
                    <DropDownList
                      className="selectbox"
                      data-id={idx}
                      name={"toYear-" + idx}
                      data={this.state.toYears}
                      textField="Value"
                      dataItemKey="DurationToYear"
                      defaultItem={this.defaultCompanyToYear}
                      value={
                        this.state.toYears[
                          recruitModal.employerDetails[idx].DurationToYear -
                            1990
                        ]
                      }
                      onChange={this.handleEmployerChange(idx)}
                    />
                  </div>
                  {recruitFormErrors.employerDetailsError[idx]
                    .durationToYear === undefined
                    ? ""
                    : recruitFormErrors.employerDetailsError[idx].durationToYear
                        .length > 0 && (
                        <span className="errorMessage">
                          {
                            recruitFormErrors.employerDetailsError[idx]
                              .durationToYear
                          }
                        </span>
                      )}
                </div>
                <div className="col-md-1">
                  <Label style={{ marginTop: "-55px" }}>&nbsp;</Label>
                  <div>
                    <Label style={{ margin: "0px", fontSize: "12px" }}>
                      {recruitModal.employerDetails[idx].Experience}
                      {recruitModal.employerDetails[idx].Experience === ""
                        ? ""
                        : " years"}
                    </Label>
                  </div>
                </div>
                {idx === 0 ? (
                  ""
                ) : (
                  <a
                    className="glyphicon-minus glyphicon"
                    onClick={this.handleRemoveEmployerRow(idx)}
                  ></a>
                )}
              </div>
            ))}
            {recruitModal.employerDetails.length === 0 ? (
              <div className="row">
                <div
                  className="col-md-12 errorMessage"
                  style={{ textAlign: "center" }}
                >
                  {NoEmployerFound}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-md-12">
                <h3>Salary (INR Per month)</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <small className="align_right bot labelclass">
                  Current Salary
                </small>
                <div
                  className={
                    recruitFormErrors.CurrentSalary.length > 0
                      ? "field_text fieldErrorMessage"
                      : "field_text"
                  }
                >
                  <Input
                    type="text"
                    id="currentSalary"
                    name="currentSalary"
                    className="form-control"
                    placeholder="Current Salary per Month"
                    maxLength="10"
                    noValidate
                    value={recruitModal.CurrentSalary}
                    onChange={this.handleChange}
                  />
                  <span className="star">*</span>
                </div>
                {recruitFormErrors.CurrentSalary.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.CurrentSalary}
                  </span>
                )}
              </div>
              <div className="col-md-4">
                <small className="align_right bot labelclass">
                  Expected Salary
                </small>
                <div
                  className={
                    recruitFormErrors.ExpectedSalary.length > 0
                      ? "field_text fieldErrorMessage"
                      : "field_text"
                  }
                >
                  <Input
                    type="text"
                    id="expectedSalary"
                    name="expectedSalary"
                    className="form-control"
                    placeholder="Expected Salary per Month"
                    maxLength="10"
                    noValidate
                    value={recruitModal.ExpectedSalary}
                    onChange={this.handleChange}
                  />
                  <span className="star">*</span>
                </div>
                {recruitFormErrors.ExpectedSalary.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.ExpectedSalary}
                  </span>
                )}
              </div>
              <div className="col-md-4 radio_btn_part">
                <div className="field_text">
                  <span className="field_texttitle">Ready to Reconsider</span>
                  {recruitModal.Reconsider ? (
                    <React.Fragment>
                      <div className="radiobutreconsider">
                        <div className="custom-control custom-radio">
                          <input
                            className="custom-control-input"
                            id="reconsiderYes"
                            type="radio"
                            value={true}
                            defaultChecked="checked"
                            name="reconsider"
                            onChange={this.handleChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="reconsiderYes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            className="custom-control-input"
                            id="reconsiderNo"
                            type="radio"
                            value={false}
                            name="reconsider"
                            onChange={this.handleChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="reconsiderNo"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      <small className="align_right bot">
                        Are you ready to negotiate, the mentioned expected
                        salary?
                      </small>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="radiobutreconsider">
                        <div className="custom-control custom-radio">
                          <input
                            className="custom-control-input"
                            id="reconsiderYes"
                            type="radio"
                            value={true}
                            name="reconsider"
                            onChange={this.handleChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="reconsiderYes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            className="custom-control-input"
                            id="reconsiderNo"
                            type="radio"
                            value={false}
                            name="reconsider"
                            defaultChecked="checked"
                            onChange={this.handleChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="reconsiderNo"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      <small className="align_right bot">
                        Are you ready to negotiate, the mentioned expected
                        salary?
                      </small>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h3>
                  When can you join?
                  {recruitModal.NoticePeriodOld != "" &&
                  recruitModal.NoticePeriodOld != null ? (
                    <React.Fragment>
                      <span
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          margin: "7px 25px 0px 0px",
                          float: "right",
                          border: "solid 1px red",
                        }}
                        href="#"
                        id="noticePeriodOld"
                      >
                        <a
                          target="_blank"
                          title="Show old data"
                          className="glyphicon-info glyphicon"
                          style={{ right: "15px", top: "7px" }}
                        ></a>
                      </span>
                      <UncontrolledTooltip
                        style={{ right: "15px", top: "7px" }}
                        placement="top"
                        target="noticePeriodOld"
                      >
                        {recruitModal.NoticePeriodOld}
                      </UncontrolledTooltip>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </h3>
                <div className="col-md-12">
                  {/* <small className="align_right bot labelclass">
                    When can you join?
                  </small> */}
                  <div
                    className={
                      recruitFormErrors.NoticePeriodID.length > 0
                        ? "field_text fieldErrorMessage"
                        : "field_text"
                    }
                  >
                    <DropDownList
                      className="selectbox"
                      name="noticePeriod"
                      data={this.state.noticePeriodDropdown}
                      textField="Description"
                      dataItemKey="NoticePeriodID"
                      defaultItem={this.defaultNoticePeriodItem}
                      value={
                        this.state.noticePeriodDropdown[
                          this.getIndex(
                            this.state.noticePeriodDropdown,
                            recruitModal.NoticePeriodID,
                            "NoticePeriodID",
                          )
                        ]
                      }
                      onChange={this.handleChange}
                      filterable={true}
                      onFilterChange={this.filterChangeNoticePeriod}
                      loading={this.state.loading}
                    />
                    <span className="star">*</span>
                  </div>
                  {recruitFormErrors.NoticePeriodID.length > 0 && (
                    <span className="errorMessage">
                      {recruitFormErrors.NoticePeriodID}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <h3>Interview Schedule</h3>
                <div className="row">
                  <div className="col-md-6">
                    <small className="align_right bot labelclass">
                      Suitable Date
                    </small>
                    <div className="field_text">
                      <DatePicker
                        format={"dd/MM/yyyy"}
                        name="suitableDate"
                        // min={new Date()}
                        defaultValue={new Date()}
                        value={recruitModal.SuitableDate}
                        onChange={this.handleChange}
                        width="96%"
                      />
                      <span className="star">*</span>
                    </div>
                    {recruitFormErrors.SuitableDate.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.SuitableDate}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <small className="align_right bot labelclass">
                      Suitable Time
                    </small>
                    <div className="field_text">
                      {this.state.siteConfigData.isWalkingInterview ? (
                        <TimePicker
                          name="suitableTime"
                          steps={this.steps}
                          min={
                            new Date(
                              2100,
                              2,
                              10,
                              this.state.siteConfigData.suitableTimeStarting,
                              0,
                            )
                          }
                          max={
                            new Date(
                              2110,
                              2,
                              10,
                              this.state.siteConfigData.suitableTimeEnding - 1,
                              30,
                            )
                          }
                          defaultValue={new Date()}
                          value={recruitModal.SuitableTime}
                          onChange={this.handleChange}
                          width="96%"
                        />
                      ) : (
                        <TimePicker
                          name="suitableTime"
                          steps={this.steps}
                          min={new Date(2100, 2, 10, 10, 0)}
                          max={new Date(2110, 2, 10, 16, 30)}
                          defaultValue={new Date()}
                          value={recruitModal.SuitableTime}
                          onChange={this.handleChange}
                          width="96%"
                        />
                      )}
                      <span className="star">*</span>
                      <small className="align_right bot">
                        (except 1:00 PM to 1:30 PM Lunch hours)
                      </small>
                    </div>
                    {recruitFormErrors.SuitableTime.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.SuitableTime}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <h3>Resume</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                {/* <small className="align_right bot labelclass">
                  Upload Resume
                </small> */}
                <div className="field_text">
                  <div className="file_listbutwrap">
                    <div className="file_listbut">
                      <input
                        type="file"
                        id="resumeFile"
                        name="resumeFile"
                        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                        onChange={(e) => this._handleResumeChange(e)}
                      />
                    </div>
                    <div className="file_listbox">
                      {recruitModal.ResumeFileName != "" &&
                      recruitModal.ResumeFileName != null
                        ? recruitModal.ResumeFileName
                        : "Upload Resume"}
                    </div>
                    <small className="align_right bot">
                      Please upload .doc, .docx, .pdf file upto 2 MB
                    </small>
                    {/* {recruitModal.ResumeFileName != "" &&
                    recruitModal.ResumeFileName != null ? (
                      <small className="align_right bot">
                        <a href={recruitModal.Resume}>Download Resume</a>
                      </small>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-4 submitbutwrap">
                {this.state.isLoading ? (
                  <React.Fragment>
                    <button
                      className="submit"
                      type="button"
                      disabled="disabled"
                    >
                      Submit
                    </button>{" "}
                    <button
                      className="cancel"
                      type="button"
                      disabled="disabled"
                    >
                      Cancel
                    </button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <button
                      className="submit"
                      type="button"
                      onClick={this.handleRecruitSubmit}
                    >
                      Submit
                    </button>{" "}
                    <button
                      className="cancel"
                      type="button"
                      onClick={this.resetForm}
                    >
                      Cancel
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : this.state.showUnauthorizedMessage ? (
          <React.Fragment>
            <div className="row">
              <div className="persondetailwrap">
                <div className="userpic_box">
                  <img
                    style={{ height: "230px", width: "230px" }}
                    src={
                      recruitModal.CandidateImage === ""
                        ? noImage
                        : recruitModal.CandidateImage
                    }
                    alt=""
                  />
                </div>

                <div className="personal_from">
                  <div className="col-md-12">
                    <h3>Personal Details</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <small className="align_right bot labelclass">
                        Select Main Technology
                      </small>
                      <div className="field_text">
                        <DropDownList
                          disabled
                          className="selectbox"
                          name="mainTechnology"
                          data={this.state.technologyDropdown}
                          textField="Description"
                          dataItemKey="TechnologyMasterID"
                          defaultItem={this.defaultTechnologyItem}
                          value={
                            this.state.technologyDropdown[
                              this.getIndex(
                                this.state.technologyDropdown,
                                recruitModal.TechnologyMasterID,
                                "TechnologyMasterID",
                              )
                            ]
                          }
                          onChange={this.handleChange}
                          filterable={true}
                          onFilterChange={this.filterChangeTechnology}
                          loading={this.state.loading}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <small className="align_right bot labelclass">
                        First Name
                      </small>
                      <div className="field_text">
                        <Input
                          disabled
                          type="text"
                          id="firstname"
                          name="firstname"
                          placeholder="First Name"
                          maxLength="150"
                          noValidate
                          value={recruitModal.FirstName}
                          onChange={this.handleChange}
                        />
                        <span className="star">*</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <small className="align_right bot labelclass">
                        Last Name
                      </small>
                      <div className="field_text">
                        <Input
                          disabled
                          type="text"
                          id="lastname"
                          name="lastname"
                          placeholder="Last Name"
                          maxLength="150"
                          noValidate
                          value={recruitModal.LastName}
                          onChange={this.handleChange}
                        />
                        <span className="star">*</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <small className="align_right bot labelclass">
                        Email
                      </small>
                      <div className="field_text">
                        <Input
                          disabled
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email"
                          maxLength="320"
                          noValidate
                          value={recruitModal.Email}
                          onChange={this.handleChange}
                        />
                        <span className="star">*</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <small className="align_right bot labelclass">
                        Mobile No.
                      </small>
                      <div className="field_text">
                        <Input
                          disabled
                          type="text"
                          id="mobile"
                          name="mobile"
                          placeholder="Mobile"
                          minLength="10"
                          maxLength="15"
                          noValidate
                          value={recruitModal.Mobile}
                          onChange={this.handleChange}
                        />
                        <span className="star">*</span>
                      </div>
                    </div>
                    {this.state.recruitModal.addNewReference ? (
                      <div className="col-md-4">
                        <small className="align_right bot labelclass">
                          Enter Reference
                        </small>
                        <div className="field_text">
                          <Input
                            disabled
                            type="text"
                            id="referenceName"
                            name="referenceName"
                            placeholder="Reference Name"
                            maxLength="150"
                            noValidate
                            value={recruitModal.ReferenceName}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-4">
                        <small className="align_right bot labelclass">
                          Select Reference
                        </small>
                        <div className="field_text">
                          <DropDownList
                            disabled
                            className="selectbox"
                            name="reference"
                            data={this.state.referenceDropdown}
                            textField="Description"
                            dataItemKey="ReferenceMasterID"
                            defaultItem={this.defaultReferenceItem}
                            value={
                              this.state.referenceDropdown[
                                this.getIndex(
                                  this.state.referenceDropdown,
                                  recruitModal.ReferenceMasterID,
                                  "ReferenceMasterID",
                                )
                              ]
                            }
                            onChange={this.handleChange}
                            filterable={true}
                            onFilterChange={this.filterChangeReference}
                            loading={this.state.loading}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3>Interviewer Details</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <small className="align_right bot labelclass">
                  Interview Taken By
                </small>
                <div className="field_text">
                  <MultiSelect
                    name="interviewTakenBy"
                    className="form-control"
                    data={this.state.userDropdown}
                    textField="Name"
                    dataItemKey="UserMasterID"
                    filterable={true}
                    onFilterChange={this.filterChangeInterviewTakenBy}
                    placeholder="Interview Taken By"
                    value={recruitModal.InterviewTakenBy}
                    onChange={this.handleChange}
                  />
                  <span className="star">*</span>
                </div>
                {recruitFormErrors.InterviewTakenBy.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.InterviewTakenBy}
                  </span>
                )}
              </div>
              <div className="col-md-4">
                <small className="align_right bot labelclass">
                  Interview Date
                </small>
                <div className="field_text">
                  <DatePicker
                    format={"dd/MM/yyyy"}
                    name="interviewDate"
                    // min={new Date()}
                    defaultValue={new Date()}
                    value={recruitModal.InterviewDate}
                    onChange={this.handleChange}
                    width="96%"
                  />
                  <span className="star">*</span>
                </div>
                {recruitFormErrors.InterviewDate.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.InterviewDate}
                  </span>
                )}
              </div>
              <div className="col-md-4">
                <small className="align_right bot labelclass">
                  Suitable Status
                </small>
                <div className="field_text">
                  <DropDownList
                    className="selectbox"
                    name="mainSuitableStatus"
                    data={this.state.statusDropdownForNormalUser}
                    textField="Description"
                    dataItemKey="StatusMasterID"
                    defaultItem={this.defaultStatusItem}
                    value={
                      this.state.statusDropdownForNormalUser[
                        this.getIndex(
                          this.state.statusDropdownForNormalUser,
                          recruitModal.StatusMasterID,
                          "StatusMasterID",
                        )
                      ]
                    }
                    onChange={this.handleChange}
                    filterable={true}
                    onFilterChange={this.filterChangeStatusForNormalUser}
                    loading={this.state.loading}
                  />
                  <span className="star">*</span>
                </div>
                {recruitFormErrors.StatusMasterID.length > 0 && (
                  <span className="errorMessage">
                    {recruitFormErrors.StatusMasterID}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3>Comment</h3>
                <a
                  title="Show all Comments"
                  className="glyphicon-info glyphicon"
                  style={{
                    height: "23px",
                    float: "right",
                    margin: "72px 0 0 0",
                    cursor: "pointer",
                  }}
                  onClick={this.showAllComments}
                ></a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="field_text">
                  <DropDownList
                    className="selectbox"
                    name="commentType"
                    data={this.commentTypeForNormalUser}
                    textField="Value"
                    dataItemKey="CommentTypeID"
                    defaultItem={this.defaultComment}
                    onChange={this.handleChangeCommentType}
                    value={this.commentTypeForNormalUser[-1]}
                  />
                </div>
              </div>
            </div>
            {this.state.recruitModal.commentType === 1 ? (
              <React.Fragment>
                <div className="row">
                  <div className="col-md-6">
                    <div className="field_text">
                      <Input
                        type="textarea"
                        name="techComment"
                        placeholder="Technical Comment"
                        maxLength="4000"
                        style={{ height: "175px" }}
                        noValidate
                        value={recruitModal.TechnicalComment}
                        onChange={this.handleChange}
                      />
                      <span className="star">*</span>
                    </div>
                    {recruitFormErrors.TechnicalComment.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.TechnicalComment}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="field_text">
                      <Input
                        type="textarea"
                        name="techOtherComment"
                        placeholder="Technical Other Comment"
                        maxLength="4000"
                        style={{ height: "175px" }}
                        noValidate
                        value={recruitModal.TechnicalOtherComment}
                        onChange={this.handleChange}
                      />
                    </div>
                    {recruitFormErrors.TechnicalOtherComment.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.TechnicalOtherComment}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 mb-4">
                    {this.state.isLoading ? (
                      <button
                        disabled="disabled"
                        className="submit"
                        type="button"
                      >
                        Save Comment
                      </button>
                    ) : (
                      <button
                        className="submit"
                        type="button"
                        onClick={this.handleSaveTechnicalComment}
                      >
                        Save Comment
                      </button>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : this.state.recruitModal.commentType === 2 ? (
              <React.Fragment>
                <div className="row">
                  <div className="col-md-5">
                    <div className="field_text">
                      <Input
                        type="textarea"
                        name="practicalComment"
                        placeholder="Practical Comment"
                        maxLength="4000"
                        style={{ height: "175px" }}
                        noValidate
                        value={recruitModal.PracticalComment}
                        onChange={this.handleChange}
                      />
                      <span className="star">*</span>
                    </div>
                    {recruitFormErrors.PracticalComment.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.PracticalComment}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div className="field_text">
                      <Input
                        type="textarea"
                        name="practicalOtherComment"
                        placeholder="Practical Other Comment"
                        maxLength="4000"
                        style={{ height: "175px" }}
                        noValidate
                        value={recruitModal.PracticalOtherComment}
                        onChange={this.handleChange}
                      />
                    </div>
                    {recruitFormErrors.PracticalOtherComment.length > 0 && (
                      <span className="errorMessage">
                        {recruitFormErrors.PracticalOtherComment}
                      </span>
                    )}
                  </div>
                  <div className="col-md-3">
                    <div className="field_text">
                      <DatePicker
                        format={"dd/MM/yyyy"}
                        name="practicalDate"
                        defaultValue={recruitModal.InterviewDate}
                        value={recruitModal.PracticalDate}
                        onChange={this.handleChange}
                        width="96%"
                      />
                      <span className="star">*</span>
                      <small
                        className="align_right bot"
                        style={{ color: "#303c48" }}
                      >
                        Practical Date
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 mb-4">
                    {this.state.isLoading ? (
                      <button
                        disabled="disabled"
                        className="submit"
                        type="button"
                      >
                        Save Comment
                      </button>
                    ) : (
                      <button
                        className="submit"
                        type="button"
                        onClick={this.handleSavePracticalComment}
                      >
                        Save Comment
                      </button>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
            <div className="mt-4 table-responsive">
              <table className="table table-striped table-sm">
                <thead>
                  <tr
                    style={{
                      background: "#226db4",
                      color: "#fff",
                      borderRadius: "7px 7px 0 0",
                    }}
                  >
                    <th style={{ width: "15%" }}>Type</th>
                    <th style={{ width: "40%" }}>Comment</th>
                    <th style={{ width: "30%" }}>Other Comment</th>
                    <th style={{ width: "15%" }}>Added By</th>
                    <th style={{ width: "5%" }}>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {recruitModal.commentDetailsWithoutHRComments.map(
                    (item, idx) => (
                      <tr
                        id={"commentDetailsWithoutHRComments-" + idx}
                        key={idx}
                      >
                        <td>
                          {recruitModal.commentDetailsWithoutHRComments[idx]
                            .CommentType === 1
                            ? "Technical"
                            : recruitModal.commentDetailsWithoutHRComments[idx]
                                .CommentType === 2
                            ? "Practical"
                            : ""}
                        </td>
                        <td title={recruitModal.commentDetailsWithoutHRComments[idx].Comment}
                          dangerouslySetInnerHTML={{
                            __html:
                              recruitModal.commentDetailsWithoutHRComments[
                                idx
                              ].Comment.replace(/\<\/br>/g, " ").length > 40
                                ? recruitModal.commentDetailsWithoutHRComments[
                                    idx
                                  ].Comment.replace(/\<\/br>/g, " ").substring(
                                    0,
                                    40,
                                  ) + "..."
                                : recruitModal.commentDetailsWithoutHRComments[
                                    idx
                                  ].Comment.replace(/\<\/br>/g, " "),
                          }}
                        />
                        <td title={recruitModal.commentDetailsWithoutHRComments[idx].OtherComment}
                          dangerouslySetInnerHTML={{
                            __html:
                              recruitModal.commentDetailsWithoutHRComments[idx]
                                .OtherComment != null
                                ? recruitModal.commentDetailsWithoutHRComments[
                                    idx
                                  ].OtherComment.replace(/\<\/br>/g, " ")
                                    .length > 30
                                  ? recruitModal.commentDetailsWithoutHRComments[
                                      idx
                                    ].OtherComment.replace(
                                      /\<\/br>/g,
                                      " ",
                                    ).substring(0, 30) + "..."
                                  : recruitModal.commentDetailsWithoutHRComments[
                                      idx
                                    ].OtherComment.replace(/\<\/br>/g, " ")
                                : "",
                          }}
                        />
                        <td>
                          {
                            recruitModal.commentDetailsWithoutHRComments[idx]
                              .Name
                          }
                        </td>
                        <td>
                          {recruitModal.commentDetailsWithoutHRComments[idx]
                            .Date === null ? (
                            <Moment format="DD/MM/YYYY">
                              {
                                recruitModal.commentDetailsWithoutHRComments[
                                  idx
                                ].CreatedDate
                              }
                            </Moment>
                          ) : (
                            <Moment format="DD/MM/YYYY">
                              {
                                recruitModal.commentDetailsWithoutHRComments[
                                  idx
                                ].Date
                              }
                            </Moment>
                          )}
                        </td>
                      </tr>
                    ),
                  )}
                  {recruitModal.commentDetailsWithoutHRComments.length === 0 ? (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan="5">
                        No Comments Found
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3>Qualification Details</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <small className="align_right bot labelclass">
                  Highest Qualification
                </small>
                <div className="field_text">
                  <Input
                    disabled
                    type="text"
                    id="highestQualification"
                    name="highestQualification"
                    className="form-control"
                    placeholder="Highest Qualification"
                    noValidate
                    value={recruitModal.HighestQualification}
                    onChange={this.handleChange}
                  />
                  <span className="star">*</span>
                </div>
              </div>
              <div className="col-md-8">
                <small className="align_right bot labelclass">
                  Extra Certification
                </small>
                <div className="field_text">
                  <Input
                    disabled
                    type="text"
                    id="extraCertification"
                    name="extraCertification"
                    className="form-control"
                    placeholder="Extra Certification"
                    noValidate
                    value={recruitModal.ExtraCertification}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3>Technical Skils</h3>
              </div>
            </div>
            {recruitModal.technologies.map((item, idx) => (
              <div className="row" id={"technology-" + idx} key={idx}>
                <div className="col-md-3">
                  <small className="align_right bot labelclass">
                    Technology
                  </small>
                  <div className="field_text">
                    <Input
                      disabled
                      type="text"
                      data-id={idx}
                      id={"technology-" + idx}
                      name={"technology-" + idx}
                      className="form-control"
                      placeholder="Technology"
                      noValidate
                      value={recruitModal.technologies[idx].technology}
                      onChange={this.handleTechnologyChange(idx)}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <small className="align_right bot labelclass">Year(s)</small>
                  <div className="field_text">
                    <DropDownList
                      disabled
                      className="selectbox"
                      data-id={idx}
                      name={"experienceInYear-" + idx}
                      data={this.years}
                      textField="Value"
                      dataItemKey="ExperienceInYear"
                      defaultItem={this.defaultTechnologyYear}
                      value={
                        this.years[
                          recruitModal.technologies[idx].ExperienceInYear
                        ]
                      }
                      onChange={this.handleTechnologyChange(idx)}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <small className="align_right bot labelclass">Month(s)</small>
                  <div className="field_text">
                    <DropDownList
                      disabled
                      className="selectbox"
                      data-id={idx}
                      name={"experienceInMonth-" + idx}
                      data={this.months}
                      textField="Value"
                      dataItemKey="ExperienceInMonth"
                      defaultItem={this.defaultTechnologyMonth}
                      value={
                        this.months[
                          recruitModal.technologies[idx].ExperienceInMonth
                        ]
                      }
                      onChange={this.handleTechnologyChange(idx)}
                    />
                  </div>
                </div>
              </div>
            ))}
            {recruitModal.technologies.length === 0 ? (
              <div className="row">
                <div
                  className="col-md-12 errorMessage"
                  style={{ textAlign: "center" }}
                >
                  {NoTechnologyFound}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-md-12">
                <h3>
                  Employment Details
                  {/* <small style={{ textTransform: "none" }}>
                    Click on "+" icon on the right to expand and add more
                    details, starting from the First Job to the Current one.
                  </small> */}
                </h3>
              </div>
            </div>
            {recruitModal.employerDetails.map((item, idx) => (
              <div className="row" id={"employment-" + idx} key={idx}>
                <div className="col-md-3">
                  <small className="align_right bot labelclass">
                    Company Name
                  </small>
                  <div className="field_text">
                    <Input
                      disabled
                      type="text"
                      data-id={idx}
                      id={"companyName-" + idx}
                      name={"companyName-" + idx}
                      className="form-control"
                      placeholder="Company Name"
                      maxLength="200"
                      noValidate
                      value={recruitModal.employerDetails[idx].companyName}
                      onChange={this.handleEmployerChange(idx)}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <small className="align_right bot labelclass">
                    From Month
                  </small>
                  <div className="field_text">
                    <DropDownList
                      disabled
                      className="selectbox"
                      data-id={idx}
                      name={"fromMonth-" + idx}
                      data={this.fromMonths}
                      textField="Value"
                      dataItemKey="DurationFromMonth"
                      defaultItem={this.defaultCompanyFromMonth}
                      value={
                        this.fromMonths[
                          recruitModal.employerDetails[idx].DurationFromMonth -
                            1
                        ]
                      }
                      onChange={this.handleEmployerChange(idx)}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <small className="align_right bot labelclass">
                    From Year
                  </small>
                  <div className="field_text">
                    <DropDownList
                      disabled
                      className="selectbox"
                      data-id={idx}
                      name={"fromYear-" + idx}
                      data={this.state.fromYears}
                      textField="Value"
                      dataItemKey="DurationFromYear"
                      defaultItem={this.defaultCompanyFromYear}
                      value={
                        this.state.fromYears[
                          recruitModal.employerDetails[idx].DurationFromYear -
                            1990
                        ]
                      }
                      onChange={this.handleEmployerChange(idx)}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <small className="align_right bot labelclass">To Month</small>
                  <div className="field_text">
                    <DropDownList
                      disabled
                      className="selectbox"
                      data-id={idx}
                      name={"toMonth-" + idx}
                      data={this.toMonths}
                      textField="Value"
                      dataItemKey="DurationToMonth"
                      defaultItem={this.defaultCompanyToMonth}
                      value={
                        this.toMonths[
                          recruitModal.employerDetails[idx].DurationToMonth - 1
                        ]
                      }
                      onChange={this.handleEmployerChange(idx)}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <small className="align_right bot labelclass">To Year</small>
                  <div className="field_text">
                    <DropDownList
                      disabled
                      className="selectbox"
                      data-id={idx}
                      name={"toYear-" + idx}
                      data={this.state.toYears}
                      textField="Value"
                      dataItemKey="DurationToYear"
                      defaultItem={this.defaultCompanyToYear}
                      value={
                        this.state.toYears[
                          recruitModal.employerDetails[idx].DurationToYear -
                            1990
                        ]
                      }
                      onChange={this.handleEmployerChange(idx)}
                    />
                  </div>
                </div>
                <div className="col-md-1">
                  <Label style={{ marginTop: "-55px" }}>&nbsp;</Label>
                  <div>
                    <Label style={{ margin: "0px", fontSize: "12px" }}>
                      {recruitModal.employerDetails[idx].Experience}
                      {recruitModal.employerDetails[idx].Experience === ""
                        ? ""
                        : " years"}
                    </Label>
                  </div>
                </div>
              </div>
            ))}
            {recruitModal.employerDetails.length === 0 ? (
              <div className="row">
                <div
                  className="col-md-12 errorMessage"
                  style={{ textAlign: "center" }}
                >
                  {NoEmployerFound}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-md-6">
                <h3>When can you join?</h3>
                <div className="col-md-12">
                  {/* <small className="align_right bot labelclass">
                    When can you join?
                  </small> */}
                  <div className="field_text">
                    <DropDownList
                      disabled
                      className="selectbox"
                      name="noticePeriod"
                      data={this.state.noticePeriodDropdown}
                      textField="Description"
                      dataItemKey="NoticePeriodID"
                      defaultItem={this.defaultNoticePeriodItem}
                      value={
                        this.state.noticePeriodDropdown[
                          this.getIndex(
                            this.state.noticePeriodDropdown,
                            recruitModal.NoticePeriodID,
                            "NoticePeriodID",
                          )
                        ]
                      }
                      onChange={this.handleChange}
                      filterable={true}
                      onFilterChange={this.filterChangeNoticePeriod}
                      loading={this.state.loading}
                    />
                    <span className="star">*</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h3>Interview Schedule</h3>
                <div className="row">
                  <div className="col-md-6">
                    <small className="align_right bot labelclass">
                      Suitable Date
                    </small>
                    <div className="field_text">
                      <DatePicker
                        disabled
                        format={"dd/MM/yyyy"}
                        name="suitableDate"
                        // min={new Date()}
                        defaultValue={new Date()}
                        value={recruitModal.SuitableDate}
                        onChange={this.handleChange}
                        width="96%"
                      />
                      <span className="star">*</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <small className="align_right bot labelclass">
                      Suitable Time
                    </small>
                    <div className="field_text">
                      {this.state.siteConfigData.isWalkingInterview ? (
                        <TimePicker
                          name="suitableTime"
                          steps={this.steps}
                          min={
                            new Date(
                              2100,
                              2,
                              10,
                              this.state.siteConfigData.suitableTimeStarting,
                              0,
                            )
                          }
                          max={
                            new Date(
                              2110,
                              2,
                              10,
                              this.state.siteConfigData.suitableTimeEnding - 1,
                              30,
                            )
                          }
                          defaultValue={new Date()}
                          value={recruitModal.SuitableTime}
                          onChange={this.handleChange}
                          width="96%"
                        />
                      ) : (
                        <TimePicker
                          disabled
                          name="suitableTime"
                          steps={this.steps}
                          min={new Date(2100, 2, 10, 10, 0)}
                          max={new Date(2110, 2, 10, 16, 30)}
                          defaultValue={new Date()}
                          value={recruitModal.SuitableTime}
                          onChange={this.handleChange}
                          width="96%"
                        />
                      )}
                      <span className="star">*</span>
                      <small className="align_right bot">
                        (except 1:00 PM to 1:30 PM Lunch hours)
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h3>Resume</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                {/* <small className="align_right bot labelclass">
                  Upload Resume
                </small> */}
                <div className="field_text">
                  <div className="file_listbutwrap">
                    <div className="file_listbut">
                      <input
                        disabled
                        type="file"
                        id="resumeFile"
                        name="resumeFile"
                        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                        onChange={(e) => this._handleResumeChange(e)}
                      />
                    </div>
                    <div className="file_listbox">
                      {recruitModal.ResumeFileName != "" &&
                      recruitModal.ResumeFileName != null
                        ? recruitModal.ResumeFileName
                        : "Upload Resume"}
                    </div>
                    <small className="align_right bot">
                      Please upload .doc, .docx, .pdf file upto 2 MB
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-4 submitbutwrap">
                {this.state.isLoading ? (
                  <React.Fragment>
                    <button
                      className="submit"
                      type="button"
                      disabled="disabled"
                    >
                      Submit
                    </button>{" "}
                    <button
                      className="cancel"
                      type="button"
                      disabled="disabled"
                    >
                      Cancel
                    </button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <button
                      className="submit"
                      type="button"
                      onClick={this.handleRecruitSubmit}
                    >
                      Submit
                    </button>{" "}
                    <button
                      className="cancel"
                      type="button"
                      onClick={this.resetForm}
                    >
                      Cancel
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </Form>
    );
    const ReactCropImage = (
      <div className="cropImage">
        {this.state.Cropsrc && this.state.rotateFlag && (
          <CommonImageCrop
            src={this.state.Cropsrc}
            getSelectedImageBase64={this.getSelectedImageBase64}
          />
        )}
      </div>
    );

    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
          </div>
        ) : (
          ""
        )}

        {this.state.pageLoad ? (
          <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
          </div>
        ) : (
          <div className="mainbody">
            <div className="career_form">
              <div className="container">
                <div className="career_formwrap">
                  <h1>
                    {this.state.recruitModal.FirstName +
                      " " +
                      this.state.recruitModal.LastName +
                      " #" +
                      this.state.recruitModal.CandidateNumber +
                      " - " +
                      this.state.recruitModal.TechnologyName +
                      " - " +
                      this.state.recruitModal.TotalExp}

                    {this.state.recruitModal.ResumeFileName != "" &&
                        this.state.recruitModal.ResumeFileName != null ? (
                        <a
                          target="_blank"
                          style={{ float: "right", color: "white" }}
                          href={this.state.recruitModal.Resume}
                        >
                          Open Resume
                        </a>
                    ) : (
                      ""
                    )}
                  </h1>

                  {ReactCropImage && this.state.visibleDialog && (
                    <Dialog
                      title={"Crop the Profile Picture"}
                      onClose={this.toggleDialogClose}
                    >
                      {ReactCropImage}
                      <DialogActionsBar>
                        {this.state.IsIOS ? (
                          <button
                            className="m-2 btn btn-danger"
                            onClick={this.rotateBase64Image90deg}
                          >
                            Rotate
                          </button>
                        ) : (
                          ""
                        )}

                        <button
                          className="m-2 btn btn-danger"
                          onClick={this.toggleDialog}
                        >
                          Crop
                        </button>
                      </DialogActionsBar>
                    </Dialog>
                  )}
                  {recruiteForm}
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default EditRecruit;
