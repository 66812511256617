import React, { Component } from "react";
import Moment from "react-moment";
import { formatDate } from "@telerik/kendo-intl";

class SuitableDateFormatCell extends Component {
  render() {
    const SuitableDate = this.props.dataItem["SuitableDate"];
    const SuitableTime = this.props.dataItem["SuitableTime"];
    return (
      <React.Fragment>
        <td>
          {SuitableDate === null
            ? ""
            : // <Moment format="DD/MM/YYYY">{new Date(value)}</Moment> +
            formatDate(new Date(SuitableTime), "HH") >= 12
            ? formatDate(new Date(SuitableDate), "dd/MM/yyyy") +
              " " +
              formatDate(new Date(SuitableTime), "hh:mm") +
              " PM"
            : formatDate(new Date(SuitableDate), "dd/MM/yyyy") +
              " " +
              formatDate(new Date(SuitableTime), "hh:mm") +
              " AM"

          // <Moment format="DD/MM/YYYY">{new Date(SuitableDate)}</Moment> +
          // <Moment format="hh:mm">{new Date(SuitableTime)}</Moment>

          //formatDate(new Date(value), "d")
          }
        </td>
      </React.Fragment>
    );
  }
}

export default SuitableDateFormatCell;
