import React, { Component } from "react";
import Moment from "react-moment";
import { Button } from "reactstrap";
import { getRecruitByID } from "../APICalls/commonAPICalls";
import { decryptKey } from "../CommonFunctions/CommonValues";
import Cryptr from "cryptr";
import { async } from "q";
const cryptr = new Cryptr(decryptKey);
const delay = 100;

class ShowAllComments extends Component {
  constructor(props) {
    super(props);
    let RecruitMasterID = props.match.params.id;
    RecruitMasterID = RecruitMasterID.replace(":", "");
    this.state = {
      isLoading: false,
      userType: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .UserType
        : "",
      recruitData: {
        RecruitMasterID: cryptr.decrypt(RecruitMasterID)
      },
      recruitModal: {
        RecruitMasterID: 0,
        FirstName: "",
        LastName: "",
        CandidateNumber: "",
        TechnologyName: "",
        TotalExp: 0,
        commentDetails: [
          { CommentType: 0, Date: "", Comment: "", OtherComment: "", Name: "" }
        ],
        commentDetailsWithoutHRComments: [
          { CommentType: 0, Date: "", Comment: "", OtherComment: "", Name: "" }
        ]
      }
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    await getRecruitByID(this.state.recruitData).then(response => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage")
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          }
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
        } else if (response.data.ResponseStatus === 1) {
          this.setState({
            isLoading: false,
            recruitModal: {
              RecruitMasterID: response.data.RecruitMasterID,
              CandidateNumber: response.data.CandidateNumber,
              FirstName: response.data.FirstName,
              LastName: response.data.LastName,
              commentDetails: response.data.commentDetails,
              TechnologyName: response.data.TechnologyName,
              TotalExp: response.data.TotalExp,
              commentDetailsWithoutHRComments:
                response.data.commentDetailsWithoutHRComments
            }
          });
        } else {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
        }
      }
    });
  }

  backtoProfile = () => {
    this.props.history.push(
      "/recruitportal/editrecruit:" +
        cryptr.encrypt(this.state.recruitData.RecruitMasterID)
    );
  };

  render() {
    const recruitModal = this.state.recruitModal;
    return (
      <React.Fragment>
        <main role="main">
          {this.state.isLoading ? (
            <div className="k-loading-mask">
              <span className="k-loading-text">Loading</span>
              <div className="k-loading-image" />
              <div className="k-loading-color" />
            </div>
          ) : (
            <div className="starter-template view_recruit_main">
              <h1 className="h3 mb-3 font-weight-normal">
                <span style={{ textTransform: "uppercase" }}>
                  {this.state.recruitModal.FirstName +
                    " " +
                    this.state.recruitModal.LastName +
                    " #" +
                    this.state.recruitModal.CandidateNumber +
                    " - " +
                    this.state.recruitModal.TechnologyName +
                    " - " +
                    this.state.recruitModal.TotalExp +
                    "' "}
                </span>
                Comment List
              </h1>
              <div
                className="row"
                style={{ paddingLeft: "20px", float: "left" }}
              >
                <div className="field_textbut">
                  <Button
                    className="btn btn-info btn btn-secondary ciplbtn"
                    type="button"
                    onClick={this.backtoProfile}
                  >
                    Back to Profile
                  </Button>
                </div>
              </div>
              <div
                className="table-responsive flexi-table"
                style={{
                  padding: "20px",
                  marginBottom: "20px",
                  textAlign: "left"
                }}
              >
                <table className="table table-striped table-sm">
                  <thead>
                    <tr
                      style={{
                        background: "#226db4",
                        color: "#fff",
                        borderRadius: "7px 7px 0 0"
                      }}
                    >
                      <th style={{ width: "15%" }}>Type</th>
                      <th style={{ width: "40%" }}>Comment</th>
                      <th style={{ width: "30%" }}>Other Comment</th>
                      <th style={{ width: "15%" }}>Added By</th>
                      <th style={{ width: "5%" }}>Date</th>
                    </tr>
                  </thead>
                  {this.state.userType === "xRGjRCA3M94%3d" ||
                  this.state.userType === "6QjgBiG4ZRU%3d" ? (
                    <tbody>
                      {recruitModal.commentDetails.map((item, idx) => (
                        <tr id={"commentDetails-" + idx} key={idx}>
                          <td>
                            {recruitModal.commentDetails[idx].CommentType === 1
                              ? "Technical"
                              : recruitModal.commentDetails[idx].CommentType ===
                                2
                              ? "Practical"
                              : "HR"}
                          </td>
                          <td
                            dangerouslySetInnerHTML={{
                              __html: recruitModal.commentDetails[idx].Comment
                            }}
                          />

                          <td
                            dangerouslySetInnerHTML={{
                              __html:
                                recruitModal.commentDetails[idx].OtherComment
                            }}
                          />
                          <td>{recruitModal.commentDetails[idx].Name}</td>
                          <td>
                            {recruitModal.commentDetails[idx].Date === null ? (
                              <Moment format="DD/MM/YYYY">
                                {recruitModal.commentDetails[idx].CreatedDate}
                              </Moment>
                            ) : (
                              <Moment format="DD/MM/YYYY">
                                {recruitModal.commentDetails[idx].Date}
                              </Moment>
                            )}
                          </td>
                        </tr>
                      ))}
                      {recruitModal.commentDetails.length === 0 ? (
                        <tr>
                          <td style={{ textAlign: "center" }} colSpan="5">
                            No Comments Found
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      {recruitModal.commentDetailsWithoutHRComments.map(
                        (item, idx) => (
                          <tr
                            id={"commentDetailsWithoutHRComments-" + idx}
                            key={idx}
                          >
                            <td>
                              {recruitModal.commentDetailsWithoutHRComments[idx]
                                .CommentType === 1
                                ? "Technical"
                                : recruitModal.commentDetailsWithoutHRComments[
                                    idx
                                  ].CommentType === 2
                                ? "Practical"
                                : "HR"}
                            </td>
                            <td
                              dangerouslySetInnerHTML={{
                                __html:
                                  recruitModal.commentDetailsWithoutHRComments[
                                    idx
                                  ].Comment
                              }}
                            />

                            <td
                              dangerouslySetInnerHTML={{
                                __html:
                                  recruitModal.commentDetailsWithoutHRComments[
                                    idx
                                  ].OtherComment
                              }}
                            />
                            <td>
                              {
                                recruitModal.commentDetailsWithoutHRComments[
                                  idx
                                ].Name
                              }
                            </td>
                            <td>
                              {recruitModal.commentDetailsWithoutHRComments[idx]
                                .Date === null ? (
                                <Moment format="DD/MM/YYYY">
                                  {recruitModal.commentDetails[idx].CreatedDate}
                                </Moment>
                              ) : (
                                <Moment format="DD/MM/YYYY">
                                  {
                                    recruitModal
                                      .commentDetailsWithoutHRComments[idx].Date
                                  }
                                </Moment>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                      {recruitModal.commentDetailsWithoutHRComments.length ===
                      0 ? (
                        <tr>
                          <td style={{ textAlign: "center" }} colSpan="5">
                            No Comments Found
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          )}
        </main>
      </React.Fragment>
    );
  }
}

export default ShowAllComments;
