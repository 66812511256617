import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Noty from "noty";
import { Button, Input } from "reactstrap";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  Grid,
  GridColumn as Column,
  GridToolbar
} from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup
} from "@progress/kendo-react-excel-export";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { process, filterBy } from "@progress/kendo-data-query";
import {
  // recruitList,
  // recruitListForNormalUser,
  deleteRecruitByID,
  recruitFilterList,
  recruitFilterListForNormalUser,
  statusDropdown,
  statusDropdownForNormalUser,
  addMeetingToRecruitCalendar,
  updateRecruitSuitableStatus,
  technologyList
} from "../APICalls/commonAPICalls";
import ColumnMenu from "../CommonFunctions/ColumnMenu";
import ActiveInactiveCell from "../CommonFunctions/ActiveInactiveCell";
import SuitableDateFormatCell from "../CommonFunctions/SuitableDateFormatCell";
import StatusDropdownCell from "../CommonFunctions/StatusDropdownCell";
import {
  CannotEditDeleteAdminContent,
  MailBody,
  NoAccess
} from "../Resources/MessageResource";
import { decryptKey } from "../CommonFunctions/CommonValues";

import Cryptr from "cryptr";
import RecruitActionMenu from "../CommonFunctions/RecruitActionMenu";
import TechnologyFilterCell from "../CommonFunctions/TechnologyFilterCell";
import ExperienceCell from "../CommonFunctions/ExperienceCell";
const cryptr = new Cryptr(decryptKey);
const dtFromDate = new Date();
dtFromDate.setDate(dtFromDate.getDate() - 30);

const delay = 100;
const dtToDate = new Date();
dtToDate.setDate(dtToDate.getDate() + 60);

class ViewRecruite extends Component {
  _export;
  export = () => {
    this._export.save();
  };

  defaultStatusItem = {
    Description: "Select Suitable Status",
    StatusMasterID: 0
  };

  steps = {
    hour: 1,
    minute: 5,
    second: 10
  };

  constructor(props) {
    localStorage.setItem("localpath", props.location.pathname);
    super(props);
    this.state = {
      isLoading: false,
      userType: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .UserType
        : "",
      searchedCalled: 0,
      showUnauthorizedMessage: true,
      visibleCalendarDialog: false,
      visibleDialog: false,
      visibleStatusConfirmationDialog: false,
      deleteProps: [],
      calendarProps: [],
      showAlertMessage: false,
      alertMessage: "",
      alertColor: "",
      notyTimeOut: 5000,
      alertTimeOut: 500,
      skip: 0,
      take: 50,
      total: 0,
      oldStatus: "",
      newStatus: "",
      recruits: [],
      isLoading: false,
      loading: false,
      sort: [{ field: "RecruitMasterID", dir: "desc" }],
      tempStatusData: [],
      statusDropdown: [],
      TechnologyDrp: [],
      clickedNo: false,
      statusDropdownModal: {
        RecruitMasterID: 0,
        StatusMasterID: 0,
        OldStatusMasterID: 0
      },
      recruitModal: {
        recruitMasterID: 0,
        Name: "",
        CandidateNumber: "",
        Email: "",
        encCreatedBy: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .UserMasterID
          : 0,
        modifiedDate: new Date(),
        fromDate: new Date(dtFromDate),
        toDate: new Date(dtToDate),
        statusMasterID: 0,
        SuitableDate: new Date(),
        SuitableTime: new Date(),
        MailBody: MailBody
      }
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    if (
      this.state.userType === "xRGjRCA3M94%3d" ||
      this.state.userType === "6QjgBiG4ZRU%3d"
    ) {
      await statusDropdown().then(response => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "danger",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
              }, this.state.alertTimeOut);
            }
          );
          this.showNotification(this.state);
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                showAlertMessage: true,
                alertColor: "danger",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                }, this.state.alertTimeOut);
              }
            );
            this.showNotification(this.state);
          } else if (response.data.ResponseStatus === 1) {
            this.setState({
              tempStatusData: response.data.lstStatus.slice(),
              statusDropdown: response.data.lstStatus.slice()
            });

            recruitFilterList(this.state.recruitModal).then(response => {
              if (localStorage.getItem("failerMessage")) {
                this.setState(
                  {
                    isLoading: false,
                    showAlertMessage: true,
                    alertColor: "error",
                    alertMessage: localStorage.getItem("failerMessage")
                  },
                  () => {
                    window.setTimeout(() => {
                      this.setState({ showAlertMessage: false });
                      this.showNotification(this.state);
                    }, this.state.alertTimeOut);
                  }
                );

                localStorage.removeItem("failerMessage");
              } else {
                var response = JSON.parse(cryptr.decrypt(response));
                if (response.data.ResponseStatus === 3) {
                  this.setState(
                    {
                      isLoading: false,
                      showAlertMessage: true,
                      alertColor: "error",
                      alertMessage: response.data.ResponseMsg
                    },
                    () => {
                      window.setTimeout(() => {
                        this.setState({ showAlertMessage: false });
                        this.showNotification(this.state);
                      }, this.state.alertTimeOut);
                    }
                  );
                } else if (response.data.ResponseStatus === 1) {
                  this.setState({
                    recruits: response.data.lstFilteredRecruit,
                    total: response.data.lstFilteredRecruit.length,
                    isLoading: false
                  });
                } else {
                  this.setState(
                    {
                      isLoading: false,
                      showAlertMessage: true,
                      alertColor: "error",
                      alertMessage: localStorage.getItem("failerMessage")
                    },
                    () => {
                      window.setTimeout(() => {
                        this.setState({ showAlertMessage: false });
                        this.showNotification(this.state);
                      }, this.state.alertTimeOut);
                    }
                  );
                }
              }
            });
          } else {
            this.setState(
              {
                showAlertMessage: true,
                alertColor: "danger",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                }, this.state.alertTimeOut);
              }
            );
            this.showNotification(this.state);
          }
        }
      });
    } else {
      await statusDropdownForNormalUser().then(response => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "danger",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
              }, this.state.alertTimeOut);
            }
          );
          this.showNotification(this.state);
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                showAlertMessage: true,
                alertColor: "danger",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                }, this.state.alertTimeOut);
              }
            );
            this.showNotification(this.state);
          } else if (response.data.ResponseStatus === 1) {
            this.setState({
              tempStatusData: response.data.lstStatusForNormalUser.slice(),
              statusDropdown: response.data.lstStatusForNormalUser.slice()
            });

            recruitFilterListForNormalUser(this.state.recruitModal).then(
              response => {
                if (localStorage.getItem("failerMessage")) {
                  this.setState(
                    {
                      isLoading: false,
                      showAlertMessage: true,
                      alertColor: "error",
                      alertMessage: localStorage.getItem("failerMessage")
                    },
                    () => {
                      window.setTimeout(() => {
                        this.setState({ showAlertMessage: false });
                        this.showNotification(this.state);
                      }, this.state.alertTimeOut);
                    }
                  );

                  localStorage.removeItem("failerMessage");
                } else {
                  var response = JSON.parse(cryptr.decrypt(response));
                  if (response.data.ResponseStatus === 3) {
                    this.setState(
                      {
                        isLoading: false,
                        showAlertMessage: true,
                        alertColor: "error",
                        alertMessage: response.data.ResponseMsg
                      },
                      () => {
                        window.setTimeout(() => {
                          this.setState({ showAlertMessage: false });
                          this.showNotification(this.state);
                        }, this.state.alertTimeOut);
                      }
                    );
                  } else if (response.data.ResponseStatus === 1) {
                    this.setState({
                      recruits: response.data.lstFilteredRecruitForNormalUser,
                      total:
                        response.data.lstFilteredRecruitForNormalUser.length,
                      isLoading: false
                    });
                  } else {
                    this.setState(
                      {
                        isLoading: false,
                        showAlertMessage: true,
                        alertColor: "error",
                        alertMessage: localStorage.getItem("failerMessage")
                      },
                      () => {
                        window.setTimeout(() => {
                          this.setState({ showAlertMessage: false });
                          this.showNotification(this.state);
                        }, this.state.alertTimeOut);
                      }
                    );
                  }
                }
              }
            );
          } else {
            this.setState(
              {
                showAlertMessage: true,
                alertColor: "danger",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                }, this.state.alertTimeOut);
              }
            );
            this.showNotification(this.state);
          }
        }
      });
    }
  }

  customData = [{ color: "green" }, { color: "red" }];
  showActiveInactive = props => (
    <ActiveInactiveCell {...props} myProp={this.customData} />
  );

  showExperience = props => <ExperienceCell {...props} />;

  showDateFormat = props => <SuitableDateFormatCell {...props} />;

  showStatusDropdown = props => (
    <StatusDropdownCell
      {...props}
      clickedNo={this.state.clickedNo}
      changesStatusID={this.state.statusDropdownModal.StatusMasterID}
      statusDropdownChange={this.statusDropdownChange}
      searchCalled={this.state.searchedCalled}
      statusData={this.state.statusDropdown}
      tempStatusData={this.state.statusDropdown}
    />
  );

  statusDropdownChange = async (
    statusMasterID,
    recruitMasterID,
    oldStatusMasterID
  ) => {
    var { statusDropdownModal } = this.state;
    statusDropdownModal.OldStatusMasterID = oldStatusMasterID;
    statusDropdownModal.StatusMasterID = statusMasterID;
    statusDropdownModal.RecruitMasterID = recruitMasterID;
    this.setState({
      statusDropdownModal
    });

    var newStatusDesc = "";
    var oldStatusDesc = "";
    for (var i = 0; i < this.state.statusDropdown.length; i++) {
      let statusID = this.state.statusDropdown[i].StatusMasterID;
      let statusDesc = this.state.statusDropdown[i].Description;
      if (statusID === oldStatusMasterID) {
        oldStatusDesc = statusDesc;
      } else if (statusID === statusMasterID) {
        newStatusDesc = statusDesc;
      } else {
      }
    }

    this.setState({
      oldStatus: oldStatusDesc,
      newStatus: newStatusDesc
    });

    this.toggleStatusConfirmationDialog();
  };

  showActionMenu = props => (
    <RecruitActionMenu
      {...props}
      callEdit={() => this.callEdit({ ...props })}
      callAddToCalendar={() => this.callAddToCalendar({ ...props })}
      callDelete={() => this.callDelete({ ...props })}
    />
  );

  selectionChange = event => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  };

  rowClick = event => {
    let last = this.lastSelectedIndex;
    const current = this.state.recruits.findIndex(
      dataItem => dataItem === event.dataItem
    );

    if (!event.nativeEvent.shiftKey) {
      this.lastSelectedIndex = last = current;
    }

    if (!event.nativeEvent.ctrlKey) {
      this.state.recruits.forEach(item => (item.selected = false));
    }
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      this.state.recruits[i].selected = select;
    }
    this.forceUpdate();
  };

  headerSelectionChange = event => {
    const checked = event.syntheticEvent.target.checked;
    this.state.recruits.forEach(item => (item.selected = checked));
    this.forceUpdate();
  };

  callEdit = props => {
    this.state.userType === "6QjgBiG4ZRU%3d"
      ? props.dataItem["UserType"] === 1
        ? this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: CannotEditDeleteAdminContent
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          )
        : window.open(
            "#/recruitportal/editrecruit:" +
              cryptr.encrypt(props.dataItem["RecruitMasterID"]),
            "_blank"
          )
      : window.open(
          "#/recruitportal/editrecruit:" +
            cryptr.encrypt(props.dataItem["RecruitMasterID"]),
          "_blank"
        );
  };

  callDelete = props => {
    this.state.userType === "6QjgBiG4ZRU%3d"
      ? props.dataItem["UserType"] === 1
        ? this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: CannotEditDeleteAdminContent
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          )
        : this.toggleDialog(props)
      : this.toggleDialog(props);

    // {
    //   this.state.showAlertMessage ? this.showNotification(this.state) : "";
    // }
  };

  toggleStatusConfirmationDialog = () => {
    this.setState({
      visibleStatusConfirmationDialog: !this.state
        .visibleStatusConfirmationDialog
    });
    if (this.state.visibleStatusConfirmationDialog) {
      // this.setState({
      //   clickedNo: true
      // });
      window.setTimeout(() => {
        this.props.history.push("/recruitportal/dashboard");
        this.props.history.push("/recruitportal/view");
      }, 500);
    }
  };

  toggleDialog = props => {
    this.setState({
      visibleDialog: !this.state.visibleDialog,
      deleteProps: props
    });
  };

  callAddToCalendar = props => {
    this.state.userType === "6QjgBiG4ZRU%3d"
      ? props.dataItem["UserType"] === 1
        ? this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: CannotEditDeleteAdminContent
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          )
        : this.toggleCalendarDialog(props)
      : this.toggleCalendarDialog(props);
  };

  toggleCalendarDialog = props => {
    if (props.dataItem === undefined) {
      this.setState({
        visibleCalendarDialog: !this.state.visibleCalendarDialog,
        calendarProps: props
      });
    } else {
      var { recruitModal } = this.state;
      recruitModal.Name = props.dataItem["Name"];
      recruitModal.CandidateNumber = props.dataItem["CandidateNumber"];
      recruitModal.Email = props.dataItem["Email"];
      recruitModal.SuitableDate = new Date(props.dataItem["SuitableDate"]);
      // recruitModal.SuitableDate = new Date(
      //   props.dataItem["SuitableDate"].replace(
      //     /(\d{2})\/(\d{2})\/(\d{4})/,
      //     "$2/$1/$3"
      //   )
      // );
      recruitModal.SuitableTime = new Date(props.dataItem["SuitableTime"]);
      // recruitModal.SuitableTime = new Date(
      //   "1970-01-01T" + props.dataItem["SuitableTime"]
      // );

      this.setState({
        visibleCalendarDialog: !this.state.visibleCalendarDialog,
        calendarProps: props,
        recruitModal
      });
    }
  };

  update(data, item, remove) {
    let updated;
    let index = data.findIndex(
      p =>
        p === item ||
        (item.RecruitMasterID && p.RecruitMasterID === item.RecruitMasterID)
    );
    if (index >= 0) {
      updated = Object.assign({}, item);
      data[index] = updated;
    } else {
      let id = 1;
      data.forEach(p => {
        id = Math.max(p.RecruitMasterID + 1, id);
      });
      updated = Object.assign({}, item, { RecruitMasterID: id });
      data.unshift(updated);
      index = 0;
    }
    if (remove) {
      data = data.splice(index, 1);
    }
    return data[index];
  }

  handleYesButton = async props => {
    this.setState({ isLoading: true });
    var { recruitModal } = this.state;
    recruitModal.recruitMasterID = props.dataItem["RecruitMasterID"];
    await deleteRecruitByID(this.state.recruitModal).then(response => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage")
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          }
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
        } else if (response.data.ResponseStatus === 4) {
          this.toggleDialog(props);
          this.update(this.state.recruits, props.dataItem, true);
          this.update(response.data.lstRecruit, props.dataItem, true);
          this.setState(
            {
              recruits: this.state.recruits.slice(),
              total: this.state.recruits.length,
              isLoading: false,
              showAlertMessage: true,
              alertColor: "success",
              alertMessage: response.data.ResponseMsg
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
        } else {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
        }
      }
    });
  };

  handleStatusConfirmation = async () => {
    var { statusDropdownModal } = this.state;
    await updateRecruitSuitableStatus(statusDropdownModal).then(response => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            showAlertMessage: true,
            alertColor: "danger",
            alertMessage: localStorage.getItem("failerMessage")
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
            }, this.state.alertTimeOut);
          }
        );
        this.showNotification(this.state);
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "danger",
              alertMessage: response.data.ResponseMsg
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
              }, this.state.alertTimeOut);
            }
          );
          this.showNotification(this.state);
        } else if (response.data.ResponseStatus === 1) {
          this.toggleStatusConfirmationDialog();
          this.componentDidMount();
          statusDropdownModal.OldStatusMasterID = this.state.statusDropdownModal.StatusMasterID;
          this.setState(
            {
              // clickedNo: false,
              statusDropdownModal,
              showAlertMessage: true,
              alertColor: "success",
              alertMessage: response.data.ResponseMsg
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
              }, this.state.alertTimeOut);
            }
          );
          this.showNotification(this.state);
        } else {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "danger",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
              }, this.state.alertTimeOut);
            }
          );
          this.showNotification(this.state);
        }
      }
    });
  };

  handleAddtoCalendar = async props => {
    this.setState({ isLoading: true });
    var { recruitModal } = this.state;
    recruitModal.recruitMasterID = props.dataItem["RecruitMasterID"];
    await addMeetingToRecruitCalendar(this.state.recruitModal).then(
      response => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          } else if (response.data.ResponseStatus === 1) {
            this.toggleCalendarDialog(props);
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "success",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          } else {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          }
        }
      }
    );
  };

  onDismiss = () => {
    this.setState({ showAlertMessage: false });
  };

  handleChange = e => {
    var { recruitModal } = this.state;
    var target = e.target;
    var value = target.value;
    var name = target.name;

    switch (name) {
      case "fromDate":
        recruitModal.fromDate = new Date(value);
        break;
      case "toDate":
        recruitModal.toDate = new Date(value);
        break;
      case "mainSuitableStatus":
        recruitModal.statusMasterID = value.StatusMasterID;
        break;
      case "suitableDate":
        recruitModal.SuitableDate = value;
        break;
      case "suitableTime":
        recruitModal.SuitableTime = value;
        break;
      case "reason":
        recruitModal.MailBody = value;
        break;
      default:
        break;
    }

    this.setState({
      recruitModal
    });
  };

  handleSearch = async e => {
    this.setState({
      searchedCalled: 1,
      isLoading: true
    });
    this.state.userType === "xRGjRCA3M94%3d" ||
    this.state.userType === "6QjgBiG4ZRU%3d"
      ? await recruitFilterList(this.state.recruitModal).then(response => {
          if (localStorage.getItem("failerMessage")) {
            this.setState(
              {
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
            localStorage.removeItem("failerMessage");
          } else {
            var response = JSON.parse(cryptr.decrypt(response));
            if (response.data.ResponseStatus === 3) {
              this.setState(
                {
                  showAlertMessage: true,
                  alertColor: "error",
                  alertMessage: response.data.ResponseMsg
                },
                () => {
                  window.setTimeout(() => {
                    this.setState({ showAlertMessage: false });
                    this.showNotification(this.state);
                  }, this.state.alertTimeOut);
                }
              );
            } else if (response.data.ResponseStatus === 1) {
              this.setState({
                recruits: response.data.lstFilteredRecruit,
                total: response.data.lstFilteredRecruit.length,
                isLoading: false
              });
            } else {
              this.setState(
                {
                  showAlertMessage: true,
                  alertColor: "error",
                  alertMessage: localStorage.getItem("failerMessage")
                },
                () => {
                  window.setTimeout(() => {
                    this.setState({ showAlertMessage: false });
                    this.showNotification(this.state);
                  }, this.state.alertTimeOut);
                }
              );
            }
          }
        })
      : await recruitFilterListForNormalUser(this.state.recruitModal).then(
          response => {
            if (localStorage.getItem("failerMessage")) {
              this.setState(
                {
                  showAlertMessage: true,
                  alertColor: "error",
                  alertMessage: localStorage.getItem("failerMessage")
                },
                () => {
                  window.setTimeout(() => {
                    this.setState({ showAlertMessage: false });
                    this.showNotification(this.state);
                  }, this.state.alertTimeOut);
                }
              );
              localStorage.removeItem("failerMessage");
            } else {
              var response = JSON.parse(cryptr.decrypt(response));
              if (response.data.ResponseStatus === 3) {
                this.setState(
                  {
                    showAlertMessage: true,
                    alertColor: "error",
                    alertMessage: response.data.ResponseMsg
                  },
                  () => {
                    window.setTimeout(() => {
                      this.setState({ showAlertMessage: false });
                      this.showNotification(this.state);
                    }, this.state.alertTimeOut);
                  }
                );
              } else if (response.data.ResponseStatus === 1) {
                this.setState({
                  recruits: response.data.lstFilteredRecruitForNormalUser,
                  total: response.data.lstFilteredRecruitForNormalUser.length,
                  isLoading: false
                });
              } else {
                this.setState(
                  {
                    showAlertMessage: true,
                    alertColor: "error",
                    alertMessage: localStorage.getItem("failerMessage")
                  },
                  () => {
                    window.setTimeout(() => {
                      this.setState({ showAlertMessage: false });
                      this.showNotification(this.state);
                    }, this.state.alertTimeOut);
                  }
                );
              }
            }
          }
        );
  };

  handleViewAll = e => {
    window.setTimeout(() => {
      this.props.history.push("/recruitportal/dashboard");
      this.props.history.push("/recruitportal/view");
    }, 500);
  };

  showNotification(state) {
    new Noty({
      type: state.alertColor,
      theme: "bootstrap-v4",
      layout: "topRight",
      closeWith: ["button"],
      text: state.alertMessage,
      timeout: state.notyTimeOut
    }).show();
  }

  filterChangeStatus = event => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        statusDropdown: this.filterStatusData(event.filter),
        loading: false
      });
    }, delay);

    this.setState({
      loading: true
    });
  };

  filterStatusData(filter) {
    const data = this.state.tempStatusData;
    return filterBy(data, filter);
  }

  rowRender(trElement, props) {
    const available = !props.dataItem.DuplicateEmail;
    const green = { backgroundColor: "#ffffff" };
    const red = { backgroundColor: "rgba(243, 23, 0, 0.32)" };
    const trProps = { style: available ? green : red };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }

  render() {
    const Technology = Array.from(
      new Set(this.state.TechnologyDrp.map(p => p.Description))
    );

    const TechnologyFilter = new TechnologyFilterCell(
      Technology,
      "Select category"
    );

    const GridBind = (
      <React.Fragment>
        <ExcelExport
          data={this.state.recruits}
          ref={exporter => (this._export = exporter)}
        >
          <ExcelExportColumn
            field="CandidateNumber"
            title="ID(#)"
            locked={true}
            width={50}
          />
          <ExcelExportColumn field="Name" title="Name" width={180} />
          <ExcelExportColumn field="Email" title="Email" width={220} />
          <ExcelExportColumn field="Mobile" title="Mobile" width={130} />
          <ExcelExportColumn
            field="Experience"
            title="Total Experience"
            width={100}
          />

          <ExcelExportColumn
            field="Suitable"
            title="Suitable Status"
            width={260}
          />
          <ExcelExportColumn
            field="SuitableDateString"
            title="Suitable Date"
            width={150}
          />
          <ExcelExportColumn
            field="SuitableTimeString"
            title="Suitable Time"
            width={150}
          />
          <ExcelExportColumn
            field="Technology"
            title="Main Technology"
            width={190}
          />
        </ExcelExport>
        <Grid
          selectedField="selected"
          //filterable={true}
          sortable={true}
          reorderable={true}
          pageable={{
            buttonCount: 4,
            pageSizes: [5, 10, 20, 50, 100],
            info: true,
            type: "numeric",
            previousNext: true
          }}
          {...this.props}
          skip={this.state.skip}
          take={this.state.take}
          sort={this.state.sort}
          filter={this.state.filter}
          data={process(this.state.recruits, this.state)}
          onDataStateChange={e => {
            this.setState(e.data);
          }}
          onSelectionChange={this.selectionChange}
          onHeaderSelectionChange={this.headerSelectionChange}
          onRowClick={this.rowClick}
          rowRender={this.rowRender}
        >
          {/* <Column
          locked
          filterable={false}
          reorderable={false}
          field="selected"
          width="50px"
          headerSelectionValue={
            this.state.recruits.findIndex(
              dataItem => dataItem.selected === false
            ) === -1
          }
        /> */}
          {/* <Column
          locked
          field="RecruitMasterID"
          filterable={false}
          reorderable={false}
          title="#"
          width="80px"
          visible={false}
        /> */}
          <Column
            filterable={false}
            sortable={false}
            reorderable={false}
            title="Action"
            cell={this.showActionMenu}
          />
          <Column
            field="CandidateNumber"
            title="ID(#)"
            columnMenu={ColumnMenu}
          />
          <Column field="Name" title="Name" columnMenu={ColumnMenu} />
          <Column field="Email" title="Email" columnMenu={ColumnMenu} />
          <Column field="Mobile" title="Mobile" columnMenu={ColumnMenu} />
          <Column
            field="Experience"
            title="Total Exp."
            filterable={false}
            cell={this.showExperience}
          />
          <Column field="Experienced" title="Exp?" columnMenu={ColumnMenu} />

          <Column
            field="Suitable"
            title="Suitable"
            showStatusDropdown
            cell={this.showStatusDropdown}
          />
          <Column
            field="SuitableDate"
            title="Suitable Date"
            cell={this.showDateFormat}
          />
          <Column
            field="Technology"
            title="Technology"
            columnMenu={ColumnMenu}
            filterCell={TechnologyFilter}
          />
        </Grid>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <main role="main">
          <div className="starter-template recruit_list view_recruit_main">
            <h1 className="h3 mb-3 font-weight-normal">
              Recruit List
              {/* <span
                style={{
                  fontSize: "12px",
                  color: "red",
                  verticalAlign: "center"
                }}
              >
                {" "}
                Duplicate records will be highlighted in Red.
              </span> */}
            </h1>
            {this.state.visibleStatusConfirmationDialog && (
              <Dialog
                title={"Please confirm"}
                onClose={this.toggleStatusConfirmationDialog}
              >
                <p style={{ margin: "25px", textAlign: "center" }}>
                  Are you sure you want to change status from "
                  <b>
                    {this.state.oldStatus === "" ? "-" : this.state.oldStatus}
                  </b>
                  " to "
                  <b>
                    {this.state.newStatus === "" ? "-" : this.state.newStatus}
                  </b>
                  " ?
                </p>
                <DialogActionsBar>
                  <button
                    className="m-2 btn btn-danger"
                    onClick={this.toggleStatusConfirmationDialog}
                  >
                    No
                  </button>
                  <button
                    className="m-2 btn btn-info"
                    onClick={() => this.handleStatusConfirmation()}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
            {this.state.visibleDialog && (
              <Dialog title={"Please confirm"} onClose={this.toggleDialog}>
                <p style={{ margin: "25px", textAlign: "center" }}>
                  Are you sure you want to delete?
                </p>
                <DialogActionsBar>
                  <button
                    className="m-2 btn btn-danger"
                    onClick={this.toggleDialog}
                  >
                    No
                  </button>
                  <button
                    className="m-2 btn btn-info"
                    onClick={() => this.handleYesButton(this.state.deleteProps)}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
            {this.state.visibleCalendarDialog && (
              <Dialog
                title={
                  "Add to Calendar : " +
                  this.state.recruitModal.Name +
                  " - #" +
                  this.state.recruitModal.CandidateNumber
                }
                onClose={this.toggleCalendarDialog}
              >
                <div className="row">
                  <div className="col-md-6">
                    <small
                      className="align_right bot labelclass"
                      style={{ float: "left" }}
                    >
                      Suitable Date
                    </small>
                    <div className="field_text">
                      <DatePicker
                        format={"dd/MM/yyyy"}
                        name="suitableDate"
                        defaultValue={new Date()}
                        value={this.state.recruitModal.SuitableDate}
                        onChange={this.handleChange}
                        width="96%"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <small
                      className="align_right bot labelclass"
                      style={{ float: "left" }}
                    >
                      Suitable Time
                    </small>
                    <div className="field_text">
                      <TimePicker
                        name="suitableTime"
                        steps={this.steps}
                        min={new Date(2100, 2, 10, 8, 0)}
                        max={new Date(2110, 2, 10, 22, 0)}
                        defaultValue={new Date()}
                        value={this.state.recruitModal.SuitableTime}
                        onChange={this.handleChange}
                        width="96%"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <small
                      className="align_right bot labelclass"
                      style={{ float: "left" }}
                    >
                      Email Body (In Plain Text)
                    </small>
                    <div className="field_text">
                      <Input
                        type="textarea"
                        name="reason"
                        placeholder="Email Body (In Plain Text)"
                        maxLength="2000"
                        style={{ height: "175px" }}
                        noValidate
                        value={this.state.recruitModal.MailBody}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <DialogActionsBar>
                  <button
                    className="m-2 btn btn-danger"
                    onClick={this.toggleCalendarDialog}
                  >
                    No
                  </button>
                  <button
                    className="m-2 btn btn-info"
                    onClick={() =>
                      this.handleAddtoCalendar(this.state.calendarProps)
                    }
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
            <div className="row mb-4 recruit_filter">
              <div className="filter_leftpart">
                <div
                  className="field_text"
                  style={{ float: "left", padding: "0 15px" }}
                >
                  <div className="field_texttitel">Suitable From Date :</div>
                  <div className="field_textfield">
                    <DatePicker
                      format={"dd/MM/yyyy"}
                      name="fromDate"
                      defaultValue={dtFromDate}
                      max={this.state.recruitModal.toDate}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div
                  className="field_text"
                  style={{ float: "left", padding: "0 15px" }}
                >
                  <div className="field_texttitel">
                    Suitable To Date : &nbsp;
                  </div>
                  <div className="field_textfield">
                    <DatePicker
                      format={"dd/MM/yyyy"}
                      name="toDate"
                      defaultValue={dtToDate}
                      min={this.state.recruitModal.fromDate}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div
                  className="field_text statuspart"
                  style={{ float: "left", padding: "0 15px" }}
                >
                  <div className="field_texttitel">
                    Suitable Status : &nbsp;
                  </div>
                  <div className="field_textfield">
                    <DropDownList
                      defaultValue={this.defaultStatusItem}
                      className="selectbox"
                      name="mainSuitableStatus"
                      data={this.state.statusDropdown}
                      textField="Description"
                      dataItemKey="StatusMasterID"
                      onChange={this.handleChange}
                      filterable={true}
                      onFilterChange={this.filterChangeStatus}
                      loading={this.state.loading}
                    />
                  </div>
                </div>
              </div>
              <div className="filter_rightpart">
                <div className="field_textbut">
                  <Button
                    className="btn btn-info btn btn-secondary ciplbtn"
                    type="button"
                    onClick={this.handleSearch}
                  >
                    Search
                  </Button>
                  <Button
                    className="submit ciplbtn" 
                    type="button"
                    onClick={this.handleViewAll}
                  >
                    View All
                  </Button>
                  {this.state.userType === "xRGjRCA3M94%3d" ||
                  this.state.userType === "6QjgBiG4ZRU%3d" ? (
                    <Button
                      title="Export PDF"
                      className="ml-3 k-button k-primary ciplbtn"
                      onClick={this.export}
                    >
                      Export to Excel
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div></div>
            <div>{GridBind}</div>
            {this.state.isLoading ? (
              <div className="k-loading-mask" style={{ zIndex: "9999999" }}>
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image" />
                <div className="k-loading-color" />
              </div>
            ) : null}
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default ViewRecruite;
