import React, { Component } from "react";
import "../css/form.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../Images/concept_logo.png";
import Moment from "react-moment";

class LoadingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <main role="main">
        <div className="bannerpart">
          <div className="container">
            <div className="logo">
              <a>
                <img src={logo} alt="" />
              </a>
            </div>
            <h1>Careers</h1>
          </div>
        </div>
        <div className="career_form">
          <div className="starter-template">
            <h1 className="h3 mb-3 font-weight-normal">Loading...</h1>
          </div>
        </div>
        <nav className="navbar fixed-bottom navbar-expand-lg navbar-light bg-light">
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav mr-auto" />
            <span className="navbar-text">
              Copyright &copy;
              <Moment format="YYYY">{this.state.currentDate}</Moment> Concept
              Infoway. All rights reserved
            </span>
          </div>
        </nav>
      </main>
    );
  }
}

export default LoadingPage;
