import React, { Component } from "react";
import Noty from "noty";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { filterBy } from "@progress/kendo-data-query";
import { decryptKey } from "./CommonValues";
import Cryptr from "cryptr";
import { async } from "q";
import { updateRecruitSuitableStatus } from "../APICalls/commonAPICalls";
const cryptr = new Cryptr(decryptKey);
const delay = 100;
class StatusDropdownCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .UserType
        : "",
      loading: false,
      tempStatusData: this.props.statusData,
      statusDropdown: this.props.tempStatusData,
      changeCall: 0,
      recruitModal: {
        RecruitMasterID: this.props.dataItem["RecruitMasterID"],
        StatusMasterID: this.props.dataItem["StatusMasterID"]
      }
    };
  }

  filterChangeStatus = event => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        statusDropdown: this.filterStatusData(event.filter),
        loading: false
      });
    }, delay);

    this.setState({
      loading: true
    });
  };

  filterStatusData(filter) {
    const data = this.state.tempStatusData;
    return filterBy(data, filter);
  }

  handleChange = async e => {
    const recruitModal = this.state.recruitModal;
    var target = e.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    var name = target.name;
    switch (name) {
      case "mainSuitableStatus":
        recruitModal.StatusMasterID = value.StatusMasterID;
        break;
    }

    if (this.props.searchCalled === 1) {
      this.setState({
        recruitModal,
        changeCall: 2
      });
    } else {
      this.setState({
        recruitModal,
        changeCall: 1
      });
    }

    this.props.statusDropdownChange(
      value.StatusMasterID,
      this.props.dataItem["RecruitMasterID"],
      this.props.dataItem["StatusMasterID"]
    );
    // await updateRecruitSuitableStatus(this.state.recruitModal).then(
    //   response => {
    //     if (localStorage.getItem("failerMessage")) {
    //       this.setState(
    //         {
    //           showAlertMessage: true,
    //           alertColor: "danger",
    //           alertMessage: localStorage.getItem("failerMessage")
    //         },
    //         () => {
    //           window.setTimeout(() => {
    //             this.setState({ showAlertMessage: false });
    //           }, this.state.alertTimeOut);
    //         }
    //       );
    //       this.showNotification(this.state);
    //       localStorage.removeItem("failerMessage");
    //     } else {
    //       var response = JSON.parse(cryptr.decrypt(response));
    //       if (response.data.ResponseStatus === 3) {
    //         this.setState(
    //           {
    //             showAlertMessage: true,
    //             alertColor: "danger",
    //             alertMessage: response.data.ResponseMsg
    //           },
    //           () => {
    //             window.setTimeout(() => {
    //               this.setState({ showAlertMessage: false });
    //             }, this.state.alertTimeOut);
    //           }
    //         );
    //         this.showNotification(this.state);
    //       } else if (response.data.ResponseStatus === 1) {
    //         this.setState(
    //           {
    //             showAlertMessage: true,
    //             alertColor: "success",
    //             alertMessage: response.data.ResponseMsg
    //           },
    //           () => {
    //             window.setTimeout(() => {
    //               this.setState({ showAlertMessage: false });
    //             }, this.state.alertTimeOut);
    //           }
    //         );
    //         this.showNotification(this.state);
    //       } else {
    //         this.setState(
    //           {
    //             showAlertMessage: true,
    //             alertColor: "danger",
    //             alertMessage: localStorage.getItem("failerMessage")
    //           },
    //           () => {
    //             window.setTimeout(() => {
    //               this.setState({ showAlertMessage: false });
    //             }, this.state.alertTimeOut);
    //           }
    //         );
    //         this.showNotification(this.state);
    //       }
    //     }
    //   }
    // );
  };

  getIndex(list, StatusMasterID, props) {
    for (var i = 0; i < list.length; i++) {
      if (list[i][props] === StatusMasterID) {
        return i;
      }
    }
  }

  showNotification(state) {
    new Noty({
      type: state.alertColor,
      theme: "bootstrap-v4",
      layout: "topRight",
      closeWith: ["button"],
      text: state.alertMessage,
      timeout: state.notyTimeOut
    }).show();
  }

  render() {
    const value = this.props.dataItem[this.props.field];
    return (
      <React.Fragment>
        <td>
          {this.state.userType === "xRGjRCA3M94%3d" ||
          this.state.userType === "6QjgBiG4ZRU%3d" ? (
            <DropDownList
              className="selectbox"
              name="mainSuitableStatus"
              style={{ width: "100%" }}
              data={this.state.statusDropdown}
              textField="Description"
              dataItemKey="StatusMasterID"
              value={
                this.state.statusDropdown[
                  this.getIndex(
                    this.state.statusDropdown,

                    this.props.searchCalled === 0
                      ? this.props.dataItem["StatusMasterID"]
                      : this.state.changeCall === 2 &&
                        this.props.searchCalled === 1
                      ? this.state.recruitModal.StatusMasterID
                      : this.props.dataItem["StatusMasterID"],
                    "StatusMasterID"
                  )
                ]
              }
              onChange={this.handleChange}
              filterable={true}
              onFilterChange={this.filterChangeStatus}
              loading={this.state.loading}
            />
          ) : (
            value
          )}
        </td>
      </React.Fragment>
    );
  }
}

export default StatusDropdownCell;
