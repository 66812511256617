export const FailerMessage =
  "Oops. Something went wrong. Please check API connection.";

export const NoAccess =
  "You don't have rights to access this page. Please contact to administrator.";

export const CannotEditDeleteAdminContent =
  "You don't have rights to edit this content. Please contact to administrator.";

export const ShowErrorMessageForMandatoryFields =
  "Please fill up mandatory details";

export const ShowErrorMessageForTechnologyFields =
  "Please fill up proper  technology details";

export const ShowErrorMessageForCompanyFields =
  "Please fill up proper employer details";

export const ShowErrorMessageForInvalidCaptcha = "Invalid Captcha";

export const ShowErrorMessageForNoWebCam =
  "Please connect to Webcam or Upload an Image";

export const NoTechnologyFound = "No Technologies Found";
export const NoEmployerFound = "No Employer Details Found";

export const EnterProperTechnicalComment =
  "Please fill up proper Technical Comment details";

export const EnterProperPracticalComment =
  "Please fill up proper Practical Comment details";

export const EnterProperHRComment = "Please fill up proper HR Comment details";

export const EnterProperDescription = "Please enter proper description";

export const EnterProperDetails = "Please enter proper details";

export const MailBody =
  "Thanks for filling our online form and we are happy to proceed with your interview as per meeting request.";

export const ShowErrorMessageForResumeSizeExceed =
  "Please upload resume up to 2 MB file size.";

export const ShowErrorMessageForProfilePicSizeExceed =
  "Please upload profile picture up to 2 MB file size.";

export const ShowErrorMessageForProfilePicResolution =
  "Profile picture should have atleast 230px or more height.";
