import React, { Component } from "react";
import Noty from "noty";
import { Form, Input, Label, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import logo from "../Images/CompanyLogo.png";
import MasterPage from "../Layout/MasterPage";
import Moment from "react-moment";
import { async } from "q";
import { Authenticate } from "../APICalls/commonAPICalls";
import { forgotPassword } from "../APICalls/commonAPICalls";
import {
  decryptKey,
  emailAddressRegex,
  passwordRegex
} from "../CommonFunctions/CommonValues";
import Cryptr from "cryptr";

const cryptr = new Cryptr(decryptKey);
const emailRegex = emailAddressRegex;
const strongRegex = passwordRegex;

const loginFormValid = ({ loginFormErrors, ...rest }) => {
  var valid = true;

  Object.values(loginFormErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach(val => {
    (val.username === "" || val.password === "") && (valid = false);
  });
  return valid;
};

const forgotPasswordFormValid = ({ forgotPasswordFormErrors, ...rest }) => {
  var valid = true;

  Object.values(forgotPasswordFormErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach(val => {
    val.email === "" && (valid = false);
  });
  return valid;
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectedFromEmail: false,
      nextURL: "",
      isLoading: false,
      currentDate: new Date(),
      isForgotPassword: false,
      showAlertMessage: false,
      alertMessage: "",
      alertColor: "",
      notyTimeOut: 5000,
      alertTimeOut: 500,
      loginModal: {
        username: "",
        password: ""
      },
      loginFormErrors: {
        username: "",
        password: ""
      },
      forgotPasswordModal: {
        email: ""
      },
      forgotPasswordFormErrors: {
        email: ""
      }
    };
    localStorage.removeItem("failerMessage");
  }

  handleLoginSubmit = async e => {
    e.preventDefault();
    if (loginFormValid(this.state)) {
      this.setState({ isLoading: true });
      await Authenticate(this.state.loginModal).then(response => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          } else if (response.data.ResponseStatus === 1) {
            localStorage.setItem("localpath", this.props.location.pathname);
            console.log(this.props.location.pathname);
            this.props.location.pathname === "/recruitportal"
              ? localStorage.getItem("userToken")
                ? this.props.history.push("/recruitportal/view")
                : this.props.history.push("/recruitportal/view")
              : this.props.history.push(this.props.location.pathname);

            this.setState({
              isLoading: false
            });
          } else {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          }
        }
      });
    } else {
      this.setState(
        {
          isLoading: false,
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: "Please enter login credentials!"
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        }
      );
    }
  };

  handleForgotPasswordSubmit = async e => {
    var { forgotPasswordModal } = this.state;
    e.preventDefault();
    if (forgotPasswordFormValid(this.state)) {
      this.setState({ isLoading: true });
      await forgotPassword(this.state.forgotPasswordModal).then(response => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "success",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          } else if (response.data.ResponseStatus === 1) {
            forgotPasswordModal.email = "";
            this.setState(
              {
                isLoading: false,
                forgotPasswordModal,
                showAlertMessage: true,
                alertColor: "success",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          } else {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          }
        }
      });
    } else {
      this.setState(
        {
          isLoading: false,
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: "Please enter email address!"
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        }
      );
    }
  };

  handleChange = e => {
    var loginFormErrors = this.state.loginFormErrors;
    var { loginModal } = this.state;

    var target = e.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    var name = target.name;

    switch (name) {
      case "username":
        loginModal.username = value;
        loginFormErrors.username =
          emailRegex.test(value) && value.length > 0
            ? ""
            : "Invalid Email Address";
        break;
      case "password":
        loginModal.password = value;
        loginFormErrors.password =
          !strongRegex.test(value) && value.length > 0
            ? "Password must contain at least 1 upper case, 1 lower case and 1 number."
            : value.length > 0 && value.length < 6
            ? "Minimum 6 characters required"
            : "";
        break;
      default:
        break;
    }

    this.setState({
      loginFormErrors,
      loginModal
    });
  };

  handleForgotPasswordChange = e => {
    var forgotPasswordFormErrors = this.state.forgotPasswordFormErrors;
    var { forgotPasswordModal } = this.state;

    var target = e.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    var name = target.name;

    switch (name) {
      case "email":
        forgotPasswordModal.email = value;
        forgotPasswordFormErrors.email =
          emailRegex.test(value) && value.length > 0
            ? ""
            : "Invalid email address";
        break;
      default:
        break;
    }

    this.setState({
      forgotPasswordFormErrors,
      forgotPasswordModal
    });
  };

  onDismiss = () => {
    this.setState({ showAlertMessage: false });
  };

  redirectToForgotPassword = () => {
    var { forgotPasswordModal } = this.state;
    forgotPasswordModal.email = "";
    this.setState({ isForgotPassword: true, forgotPasswordModal });
  };

  redirectToLogin = () => {
    var { loginModal } = this.state;
    loginModal.username = "";
    loginModal.password = "";
    this.setState({ isForgotPassword: false, loginModal });
  };

  handleLogOut = () => {
    localStorage.clear();
    this.props.history.push("/recruitportal");
  };

  showNotification(state) {
    new Noty({
      type: state.alertColor,
      theme: "bootstrap-v4",
      layout: "topRight",
      closeWith: ["button"],
      text: state.alertMessage,
      timeout: state.notyTimeOut
    }).show();
  }

  render() {
    const { loginFormErrors } = this.state;
    const { loginModal } = this.state;
    const { forgotPasswordFormErrors } = this.state;
    const { forgotPasswordModal } = this.state;

    const LoginForm = (
      <Form
        className="form-signin"
        noValidate
        onSubmit={this.handleLoginSubmit}
      >
        <div className="text-center mb-4">
          <img
            className="mb-4"
            src={logo}
            alt="Concept Infoway Pvt. Ltd."
            width="72"
            height="72"
          />
          <h1 className="h3 mb-3 font-weight-normal">Concept Infoway</h1>
        </div>
        <h1 className="h5 mb-3 font-weight-normal">Login</h1>
        <div className="form-label-group">
          <Input
            type="email"
            id="username"
            name="username"
            className="form-control"
            placeholder="Email address"
            noValidate
            autoFocus
            value={loginModal.username}
            onChange={this.handleChange}
          />
          <Label htmlFor="username">Email address</Label>
          {loginFormErrors.username.length > 0 && (
            <span className="errorMessage">{loginFormErrors.username}</span>
          )}
        </div>

        <div className="form-label-group">
          <Input
            type="password"
            id="passowrd"
            name="password"
            className="form-control"
            placeholder="Password"
            noValidate
            value={loginModal.password}
            onChange={this.handleChange}
          />
          <Label htmlFor="password">Password</Label>
          {loginFormErrors.password.length > 0 && (
            <span className="errorMessage">{loginFormErrors.password}</span>
          )}
        </div>

        {/* <div className="checkbox mb-3">
              <Label>
                <Input type="checkbox" value="remember-me" /> Remember me
              </Label>
            </div> */}

        <div>
        <Button
            className="btn btn-lg btn-primary btn-block mb-3 ciplbtn"
            color="primary"
            type="submit"
          >
            Sign in
          </Button>
          <input
            type="button"
            value="Forgot Password ?"
            className="form-control ciplbtn btn btn-lg"
            onClick={this.redirectToForgotPassword}
          />
          {/* <NavLink
              to="/forgotpassword"
              activeClassName="FormTitle__Link--Active"
              className="FormTitle__Link"
            >
              Forgot Password ?
            </NavLink> */}
          
        </div>
        <p className="mt-5 mb-3 text-muted text-center">
          Copyright &copy;
          <Moment format="YYYY">{this.state.currentDate}</Moment> Concept
          Infoway. All rights reserved
        </p>
      </Form>
    );

    const ForgotPasswordForm = (
      <Form
        className="form-signin"
        noValidate
        onSubmit={this.handleForgotPasswordSubmit}
      >
        <div className="text-center mb-4">
          <img
            className="mb-4"
            src={logo}
            alt="Concept Infoway Pvt. Ltd."
            width="72"
            height="72"
          />
          <h1 className="h3 mb-3 font-weight-normal">Concept Infoway</h1>
        </div>
        <h1 className="h5 mb-3 font-weight-normal">Forgot Password?</h1>
        <div className="form-label-group">
          <Input
            type="email"
            id="email"
            name="email"
            className="form-control"
            placeholder="Email address"
            noValidate
            autoFocus
            value={forgotPasswordModal.email}
            onChange={this.handleForgotPasswordChange}
          />
          <Label htmlFor="username">Email address</Label>
        </div>
        {forgotPasswordFormErrors.email.length > 0 && (
          <span className="errorMessage">{forgotPasswordFormErrors.email}</span>
        )}
        <div>
          <Button
            className="btn btn-lg btn-primary btn-block mb-3"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
          <input
            type="button"
            value="Back to Login"
            className="form-control"
            onClick={this.redirectToLogin}
          />
          {/* <NavLink
              exact
              to="/"
              activeClassName="FormTitle__Link--Active"
              className="FormTitle__Link"
            >
              Back to Login
            </NavLink> */}
        </div>
        <p className="mt-5 mb-3 text-muted text-center">
          Copyright &copy;
          <Moment format="YYYY">{this.state.currentDate}</Moment> Concept
          Infoway. All rights reserved
        </p>
      </Form>
    );

    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
          </div>
        ) : (
          ""
        )}
        {localStorage.getItem("userToken") ? (
          <MasterPage handleLogOut={this.handleLogOut} />
        ) : this.state.isForgotPassword ? (
          <div className="container">{ForgotPasswordForm}</div>
        ) : (
          <div className="container"> {LoginForm}</div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Login);
