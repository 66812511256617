import Axios from "axios";
import Cryptr from "cryptr";
import { FailerMessage } from "../Resources/MessageResource";

const cryptr = new Cryptr("ewfWE@#%$rfdsefgdsf");
//const LivePath = "https://recruitapi.livedemowork.net/api/";
const LivePath = "https://careersapi.conceptinfoway.net/api/";
//const LivePath = "http://localhost:62311/api/";

export const Authenticate = loginModal => {
  return Axios.post(LivePath + "Authenticate/UserAuthenticate", loginModal)
    .then(function(response) {
      if (response.data.ResponseStatus === 1) {
        localStorage.setItem("activeMenu", "/recruitportal/Recruit");
        localStorage.setItem(
          "userToken",
          cryptr.encrypt(JSON.stringify(response))
        );
      }
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const forgotPassword = forgotPasswordModal => {
  return Axios.post(
    LivePath + "Authenticate/ForgotPassword",
    forgotPasswordModal
  )
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const resetPassword = resetPasswordModal => {
  return Axios.post(LivePath + "Authenticate/ResetPassword", resetPasswordModal)
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const checkResetPasswordLink = resetPasswordModal => {
  return Axios.post(
    LivePath + "Authenticate/CheckResetPasswordLink",
    resetPasswordModal
  )
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const changePassword = changePasswordModal => {
  return Axios.post(
    LivePath + "Authenticate/ChangePassword",
    changePasswordModal,
    {
      headers: {
        Token: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .Token
          : ""
      }
    }
  )
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const technologyList = () => {
  return Axios.get(LivePath + "Technology/GetTechnologyMaster", {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const addUpdateTechnology = technologyModal => {
  return Axios.post(
    LivePath + "Technology/AddUpdateTechnology",
    technologyModal,
    {
      headers: {
        Token: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .Token
          : ""
      }
    }
  )
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const getTechnologyByID = technologyData => {
  return Axios.post(
    LivePath + "Technology/GetTechnologyMasterByID",
    technologyData,
    {
      headers: {
        Token: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .Token
          : ""
      }
    }
  )
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const deleteTechnologyByID = technologyData => {
  return Axios.post(
    LivePath + "Technology/DeleteTechnologyByID",
    technologyData,
    {
      headers: {
        Token: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .Token
          : ""
      }
    }
  )
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const statusList = () => {
  return Axios.get(LivePath + "Status/GetStatusMaster", {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const addUpdateStatus = statusModal => {
  return Axios.post(LivePath + "Status/AddUpdateStatus", statusModal, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const getStatusByID = statusData => {
  return Axios.post(LivePath + "Status/GetStatusMasterByID", statusData, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const deleteStatusByID = statusData => {
  return Axios.post(LivePath + "Status/DeleteStatusByID", statusData, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const recruitList = () => {
  return Axios.get(LivePath + "Recruit/GetRecruitMaster", {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const recruitListForNormalUser = () => {
  return Axios.get(LivePath + "Recruit/GetRecruitMasterForNormalUser", {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const technologyDropdown = () => {
  return Axios.get(LivePath + "Recruit/GetTechnologyMaster")
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const referenceDropdown = () => {
  return Axios.get(LivePath + "Recruit/GetReferenceMaster")
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const statusDropdown = () => {
  return Axios.get(LivePath + "Recruit/GetStatusMaster")
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const statusDropdownForNormalUser = () => {
  return Axios.get(LivePath + "Recruit/GetStatusMasterForNormalUser")
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const userDropdown = () => {
  return Axios.get(LivePath + "Recruit/GetUserMaster")
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const addRecruit = recruitModal => {
  return Axios.post(LivePath + "Recruit/AddRecruit", recruitModal)
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const updateRecruit = recruitModal => {
  console.log(recruitModal);
  return Axios.post(LivePath + "Recruit/UpdateRecruit", recruitModal, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const getRecruitByID = recruitData => {
  return Axios.post(LivePath + "Recruit/GetRecruitMasterByID", recruitData, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const deleteRecruitByID = recruitData => {
  return Axios.post(LivePath + "Recruit/DeleteRecruitByID", recruitData, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const userList = () => {
  return Axios.get(LivePath + "User/GetUserMaster", {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const addUpdateUser = userModal => {
  return Axios.post(LivePath + "User/AddUpdateUser", userModal, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const getUserByID = userData => {
  return Axios.post(LivePath + "User/GetUserMasterByID", userData, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const deleteUserByID = userData => {
  return Axios.post(LivePath + "User/DeleteUserByID", userData, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const referenceList = () => {
  return Axios.get(LivePath + "Reference/GetReferenceMaster", {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const addUpdateReference = referenceModal => {
  return Axios.post(LivePath + "Reference/addUpdateReference", referenceModal, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const getReferenceByID = referenceData => {
  return Axios.post(
    LivePath + "Reference/GetReferenceMasterByID",
    referenceData,
    {
      headers: {
        Token: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .Token
          : ""
      }
    }
  )
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const deleteReferenceByID = referenceData => {
  return Axios.post(LivePath + "Reference/DeleteReferenceByID", referenceData, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const addTechnicalComment = recruitModal => {
  return Axios.post(LivePath + "Recruit/AddTechnicalComment", recruitModal, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const addPracticalComment = recruitModal => {
  return Axios.post(LivePath + "Recruit/AddPracticalComment", recruitModal, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const addHRComment = recruitModal => {
  return Axios.post(LivePath + "Recruit/AddHRComment", recruitModal, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const recruitFilterList = recruitModal => {
  return Axios.post(LivePath + "Recruit/GetRecruitFilterMaster", recruitModal, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const recruitFilterListForNormalUser = recruitModal => {
  return Axios.post(
    LivePath + "Recruit/GetRecruitFilterMasterForNormalUser",
    recruitModal,
    {
      headers: {
        Token: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .Token
          : ""
      }
    }
  )
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const userFilterList = userModal => {
  return Axios.post(LivePath + "User/GetUserFilterMaster", userModal, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const technologyFilterList = technologyModal => {
  return Axios.post(
    LivePath + "Technology/GetTechnologyFilterMaster",
    technologyModal,
    {
      headers: {
        Token: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .Token
          : ""
      }
    }
  )
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const statusFilterList = statusModal => {
  return Axios.post(LivePath + "Status/GetStatusFilterMaster", statusModal, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const referenceFilterList = referenceModal => {
  return Axios.post(
    LivePath + "Reference/GetReferenceFilterMaster",
    referenceModal,
    {
      headers: {
        Token: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .Token
          : ""
      }
    }
  )
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const updateRecruitSuitableStatus = recruitStatusUpdateModal => {
  return Axios.post(
    LivePath + "Recruit/UpdateRecruitSuitableStatus",
    recruitStatusUpdateModal,
    {
      headers: {
        Token: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .Token
          : ""
      }
    }
  )
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const noticePeriodDropdown = () => {
  return Axios.get(LivePath + "NoticePeriod/GetNoticePeriodMaster")
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const siteConfiguration = () => {
  return Axios.get(LivePath + "Authenticate/GetSiteConfiguration")
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const addMeetingToRecruitCalendar = recruitModal => {
  return Axios.post(LivePath + "Recruit/AddMeetingToCalendar", recruitModal, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const bindFromYears = () => {
  return Axios.get(LivePath + "Recruit/BindFromYears")
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const bindToYears = () => {
  return Axios.get(LivePath + "Recruit/BindToYears")
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};

export const UpdateRecruitImage = recruitModal => {
  return Axios.post(LivePath + "Recruit/UpdateRecruitImage", recruitModal, {
    headers: {
      Token: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .Token
        : ""
    }
  })
    .then(function(response) {
      return cryptr.encrypt(JSON.stringify(response));
    })
    .catch(function(error) {
      localStorage.setItem("failerMessage", FailerMessage);
      return error;
    });
};
