import React, { useState, useEffect } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import Moment from "react-moment";
import Cryptr from "cryptr";
import logo from "../Images/CIPL-Logo-header.svg";

const cryptr = new Cryptr("ewfWE@#%$rfdsefgdsf");
const Navigation = ({ handleLogOut }) => {
  const menuItems = [
    "/recruitportal/dashboard",
    "/recruitportal/Recruit",
    "/recruitportal/management"
  ];
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const [divCollapse, setdivCollapse] = useState(false);
  const [subMenuRecruitOpen, setSubMenuRecruitOpen] = useState(false);
  const [subMenuManagementOpen, setSubMenuManagementOpen] = useState(false);
  const [userType] = useState(
    localStorage.getItem("userToken")
      ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
          .UserType
      : ""
  );
  const [menuActive, setMenuActive] = useState(
    localStorage.getItem("activeMenu")
  );
  const [currentDate] = useState(new Date());
  const toggle = () => {
    if (dropdownOpen) {
      setdropdownOpen(false);
    } else {
      setdropdownOpen(true);
    }
  };

  const menuRecruitToggle = () => {
    setSubMenuManagementOpen(false);
    if (subMenuRecruitOpen) {
      setSubMenuRecruitOpen(false);
    } else {
      setSubMenuRecruitOpen(true);
    }
  };

  const menuManagementToggle = () => {
    setSubMenuRecruitOpen(false);
    if (subMenuManagementOpen) {
      setSubMenuManagementOpen(false);
    } else {
      setSubMenuManagementOpen(true);
    }
  };

  const makeActive = value => {
    localStorage.setItem("activeMenu", value.menuItem);
    setMenuActive(value.menuItem);
    setSubMenuManagementOpen(false);
    setSubMenuRecruitOpen(false);
  };

  const showCollapseDiv = () => {
    if (divCollapse) {
      setdivCollapse(false);
    } else {
      setdivCollapse(true);
    }
  };

  const handleClickOutside = event => {
    if (
      event.target.id !== "navbarRecruitLink" &&
      event.target.className !== "nav-link dropdown-toggle" &&
      document.getElementById("menuRecruit").className === "dropdown-menu show"
    ) {
      document.getElementById("navbarRecruitLink").click();
    } else if (
      document.getElementById("menuManagement") != undefined &&
      event.target.id !== "navbarManagementLink" &&
      event.target.className !== "nav-link dropdown-toggle" &&
      document.getElementById("menuManagement").className ===
        "dropdown-menu show"
    ) {
      document.getElementById("navbarManagementLink").click();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  return (
    <React.Fragment>
      <nav id="cipl_newheader"
        className="navbar navbar-expand-lg navbar-dark "
        style={{ backgroundColor: "#fde8d5" }}
      >
        {/* <div className="container"> */}
        <Link className="navbar-brand" href="#" to="/recruitportal/dashboard">
          <img
            className="mt-2 mr-2"
            //src={companylogo}
            src={logo}
            alt="Concept Infoway Pvt. Ltd."
            width="140"
            height="55"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => showCollapseDiv()}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={
            divCollapse
              ? "collapse navbar-collapse show"
              : "collapse navbar-collapse"
          }
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav mr-auto">
            {menuItems.map(menuItem =>
              menuItem === "/recruitportal/Recruit" ? (
                <li
                  className={
                    menuActive === menuItem
                      ? "nav-item mr-2 active dropdown"
                      : "nav-item mr-2 dropdown"
                  }
                  key={menuItem}
                >
                  <Link
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarRecruitLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => menuRecruitToggle()}
                  >
                    Recruit
                  </Link>
                  <div
                    id="menuRecruit"
                    className={
                      subMenuRecruitOpen
                        ? "dropdown-menu show"
                        : "dropdown-menu"
                    }
                    aria-labelledby="navbarRecruitLink"
                  >
                    <Link
                      to="/recruitportal/add"
                      className={
                        localStorage.getItem("localpath") ===
                        "/recruitportal/add"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      href="#"
                      onClick={() => makeActive({ menuItem })}
                    >
                      Add
                    </Link>
                    <Link
                      to="/recruitportal/view"
                      className={
                        localStorage.getItem("localpath") ===
                        "/recruitportal/view"
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      href="#"
                      onClick={() => makeActive({ menuItem })}
                    >
                      View
                    </Link>
                  </div>
                </li>
              ) : menuItem === "/recruitportal/management" ? (
                userType === "xRGjRCA3M94%3d" ||
                userType === "6QjgBiG4ZRU%3d" ? (
                  <li
                    className={
                      menuActive === menuItem
                        ? "nav-item mr-2 active dropdown"
                        : "nav-item mr-2 dropdown"
                    }
                    key={menuItem}
                  >
                    <Link
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarManagementLink"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => menuManagementToggle()}
                    >
                      Management
                    </Link>
                    <div
                      id="menuManagement"
                      className={
                        subMenuManagementOpen
                          ? "dropdown-menu show"
                          : "dropdown-menu"
                      }
                      aria-labelledby="navbarManagementLink"
                    >
                      <Link
                        to="/recruitportal/user"
                        className={
                          localStorage.getItem("localpath") ===
                            "/recruitportal/user" ||
                          localStorage.getItem("localpath") ===
                            "/recruitportal/adduser" ||
                          localStorage.getItem("localpath") ===
                            "/recruitportal/edituser"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                        href="#"
                        onClick={() => makeActive({ menuItem })}
                      >
                        User
                      </Link>
                      <Link
                        to="/recruitportal/technology"
                        className={
                          localStorage.getItem("localpath") ===
                            "/recruitportal/technology" ||
                          localStorage.getItem("localpath") ===
                            "/recruitportal/addtechnology" ||
                          localStorage.getItem("localpath") ===
                            "/recruitportal/edittechnology"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                        href="#"
                        onClick={() => makeActive({ menuItem })}
                      >
                        Technology
                      </Link>
                      <Link
                        to="/recruitportal/status"
                        className={
                          localStorage.getItem("localpath") ===
                            "/recruitportal/status" ||
                          localStorage.getItem("localpath") ===
                            "/recruitportal/addstatus" ||
                          localStorage.getItem("localpath") ===
                            "/recruitportal/editstatus"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                        href="#"
                        onClick={() => makeActive({ menuItem })}
                      >
                        Status
                      </Link>
                      <Link
                        to="/recruitportal/reference"
                        className={
                          localStorage.getItem("localpath") ===
                            "/recruitportal/reference" ||
                          localStorage.getItem("localpath") ===
                            "/recruitportal/addreference" ||
                          localStorage.getItem("localpath") ===
                            "/recruitportal/editreference"
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                        href="#"
                        onClick={() => makeActive({ menuItem })}
                      >
                        Reference
                      </Link>
                    </div>
                  </li>
                ) : (
                  ""
                )
              ) : (
                <li
                  key={menuItem}
                  className={
                    menuActive === menuItem
                      ? "nav-item mr-2 active"
                      : "nav-item mr-2"
                  }
                >
                  <Link
                    to="/recruitportal/dashboard"
                    className="nav-link"
                    href="#"
                    onClick={() => makeActive({ menuItem })}
                  >
                    Dashboard
                  </Link>
                </li>
              )
            )}
          </ul>
          <span className="navbar-text">
            <ButtonDropdown isOpen={dropdownOpen} toggle={() => toggle()} >
              <DropdownToggle caret size="lg" className="dropbtn">
                <b>Welcome </b>
                {localStorage.getItem("userToken")
                  ? JSON.parse(
                      cryptr.decrypt(localStorage.getItem("userToken"))
                    ).data.Name
                  : null}
                  
              </DropdownToggle>
              <DropdownMenu>
                {/* <DropdownItem header>Header</DropdownItem>
                <DropdownItem disabled>Action</DropdownItem> 
                <DropdownItem>Profile</DropdownItem>
                <DropdownItem divider /> */}
                <DropdownItem>
                  <Link
                    to="/recruitportal/changepassword"
                    href="#"
                    style={{ color: "#212529", textDecoration: "none" }}
                    onClick={() => makeActive("changepassword")}
                  >
                    Change Password
                  </Link>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleLogOut()}>
                  Log Out
                </DropdownItem>
                {/* <DropdownItem divider />
                <DropdownItem>Another Action</DropdownItem> */}
              </DropdownMenu>
            </ButtonDropdown>
          </span>
        </div>
        {/* </div> */}
      </nav>
    </React.Fragment>
  );
};

export default withRouter(Navigation);
