import React, { Component } from "react";
import Noty from "noty";
import { Button } from "reactstrap";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { process } from "@progress/kendo-data-query";
import {
  technologyList,
  technologyFilterList
} from "../../APICalls/commonAPICalls";
import { deleteTechnologyByID } from "../../APICalls/commonAPICalls";
import ColumnMenu from "../../CommonFunctions/ColumnMenu";
import ActiveInactiveCell from "../../CommonFunctions/ActiveInactiveCell";
import DateFormatCell from "../../CommonFunctions/DateFormatCell";
import ActionMenu from "../../CommonFunctions/ActionMenu";
import { NoAccess } from "../../Resources/MessageResource";
import { decryptKey } from "../../CommonFunctions/CommonValues";
import Cryptr from "cryptr";
import { async } from "q";

const cryptr = new Cryptr(decryptKey);
const dtFromDate = new Date();
dtFromDate.setDate(dtFromDate.getDate() - 31);

const dtToDate = new Date();
class TechnologyList extends Component {
  constructor(props) {
    localStorage.setItem("localpath", props.location.pathname);
    super(props);
    this.state = {
      userType: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .UserType
        : "",
      showUnauthorizedMessage: true,
      visibleAddDialog: false,
      visibleDialog: false,
      deleteProps: [],
      showAlertMessage: false,
      alertMessage: "",
      alertColor: "",
      notyTimeOut: 5000,
      alertTimeOut: 500,
      skip: 0,
      take: 50,
      total: 0,
      technologies: [],
      isLoading: true,
      sort: [{ field: "TechnologyMasterID", dir: "desc" }],
      technologyModal: {
        technologyMasterID: 0,
        encCreatedBy: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .UserMasterID
          : 0,
        modifiedDate: new Date(),
        fromDate: new Date(dtFromDate),
        toDate: new Date(dtToDate)
      }
    };
  }

  async componentDidMount() {
    this.state.userType === "xRGjRCA3M94%3d" ||
    this.state.userType === "6QjgBiG4ZRU%3d"
      ? await technologyList().then(response => {
          if (localStorage.getItem("failerMessage")) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
            localStorage.removeItem("failerMessage");
          } else {
            var response = JSON.parse(cryptr.decrypt(response));
            if (response.data.ResponseStatus === 3) {
              this.setState(
                {
                  isLoading: false,
                  showAlertMessage: true,
                  alertColor: "error",
                  alertMessage: response.data.ResponseMsg
                },
                () => {
                  window.setTimeout(() => {
                    this.setState({ showAlertMessage: false });
                    this.showNotification(this.state);
                  }, this.state.alertTimeOut);
                }
              );
            } else if (response.data.ResponseStatus === 1) {
              this.setState({
                technologies: response.data.lstTechnology,
                total: response.data.lstTechnology.length,
                isLoading: false
              });
            } else {
              this.setState(
                {
                  showAlertMessage: true,
                  alertColor: "error",
                  alertMessage: localStorage.getItem("failerMessage")
                },
                () => {
                  window.setTimeout(() => {
                    this.setState({ showAlertMessage: false });
                    this.showNotification(this.state);
                  }, this.state.alertTimeOut);
                }
              );
            }
          }
        })
      : this.setState(
          {
            isLoading: false,
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: NoAccess
          },
          () => {
            window.setTimeout(() => {
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          }
        );
  }

  customData = [{ color: "green" }, { color: "red" }];
  showActiveInactive = props => (
    <ActiveInactiveCell {...props} myProp={this.customData} />
  );

  showDateFormat = props => <DateFormatCell {...props} />;

  showActionMenu = props => (
    <ActionMenu
      {...props}
      callEdit={() => this.callEdit({ ...props })}
      callDelete={() => this.callDelete({ ...props })}
    />
  );

  selectionChange = event => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  };

  rowClick = event => {
    let last = this.lastSelectedIndex;
    const current = this.state.technologies.findIndex(
      dataItem => dataItem === event.dataItem
    );

    if (!event.nativeEvent.shiftKey) {
      this.lastSelectedIndex = last = current;
    }

    if (!event.nativeEvent.ctrlKey) {
      this.state.technologies.forEach(item => (item.selected = false));
    }
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      this.state.technologies[i].selected = select;
    }
    this.forceUpdate();
  };

  headerSelectionChange = event => {
    const checked = event.syntheticEvent.target.checked;
    this.state.technologies.forEach(item => (item.selected = checked));
    this.forceUpdate();
  };

  itemChange(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.technologies.slice();
    const item = this.update(updatedData, event.dataItem);
    item[name] = value;
    this.setState({
      technologies: updatedData
    });
  }

  callEdit = props => {
    // this.props.history.push({
    //   pathname: "/recruitportal/edittechnology",
    //   search: "?id=" + props.dataItem["TechnologyMasterID"],
    //   state: { detail: response.data }
    // });
    this.props.history.push(
      "/recruitportal/edittechnology:" +
        cryptr.encrypt(props.dataItem["TechnologyMasterID"])
    );
  };

  callDelete = props => {
    this.toggleDialog(props);
  };

  toggleDialog = props => {
    this.setState({
      visibleDialog: !this.state.visibleDialog,
      visibleAddDialog: !this.state.visibleAddDialog,
      deleteProps: props
    });
  };

  toggleAddDialog = props => {
    this.setState({
      visibleAddDialog: !this.state.visibleAddDialog
    });
  };

  update(data, item, remove) {
    let updated;
    let index = data.findIndex(
      p =>
        p === item ||
        (item.TechnologyMasterID &&
          p.TechnologyMasterID === item.TechnologyMasterID)
    );
    if (index >= 0) {
      updated = Object.assign({}, item);
      data[index] = updated;
    } else {
      let id = 1;
      data.forEach(p => {
        id = Math.max(p.TechnologyMasterID + 1, id);
      });
      updated = Object.assign({}, item, { TechnologyMasterID: id });
      data.unshift(updated);
      index = 0;
    }
    if (remove) {
      data = data.splice(index, 1);
    }
    return data[index];
  }

  handleYesButton = async props => {
    var { technologyModal } = this.state;
    technologyModal.technologyMasterID = props.dataItem["TechnologyMasterID"];
    await deleteTechnologyByID(this.state.technologyModal).then(response => {
      if (localStorage.getItem("failerMessage")) {
        this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: localStorage.getItem("failerMessage")
          },
          () => {
            window.setTimeout(() => {
              this.setState({ showAlertMessage: false });
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          }
        );
        localStorage.removeItem("failerMessage");
      } else {
        var response = JSON.parse(cryptr.decrypt(response));
        if (response.data.ResponseStatus === 3) {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: response.data.ResponseMsg
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
        } else if (response.data.ResponseStatus === 4) {
          this.toggleDialog(props);
          this.update(this.state.technologies, props.dataItem, true);
          this.update(response.data.lstTechnology, props.dataItem, true);
          this.setState(
            {
              technologies: this.state.technologies.slice(),
              total: this.state.technologies.length,
              isLoading: false,
              showAlertMessage: true,
              alertColor: "success",
              alertMessage: response.data.ResponseMsg
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
        } else {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
        }
      }
    });
  };

  onDismiss = () => {
    this.setState({ showAlertMessage: false, showUnauthorizedMessage: false });
  };

  handleAddTechnology = () => {
    //this.setState({ visibleAddDialog: true });
    this.props.history.push("/recruitportal/addtechnology");
  };

  handleChange = e => {
    var { technologyModal } = this.state;
    var target = e.target;
    var value = target.value;
    var name = target.name;

    switch (name) {
      case "fromDate":
        technologyModal.fromDate = new Date(value);
        break;
      case "toDate":
        technologyModal.toDate = new Date(value);
        break;
      default:
        break;
    }

    this.setState({
      technologyModal
    });
  };

  // handleSearch = async e => {
  //   await technologyFilterList(this.state.technologyModal).then(response => {
  //     if (localStorage.getItem("failerMessage")) {
  //       this.setState(
  //         {
  //           showAlertMessage: true,
  //           alertColor: "error",
  //           alertMessage: localStorage.getItem("failerMessage")
  //         },
  //         () => {
  //           window.setTimeout(() => {
  //             this.setState({ showAlertMessage: false });
  //             this.showNotification(this.state);
  //           }, this.state.alertTimeOut);
  //         }
  //       );
  //       localStorage.removeItem("failerMessage");
  //     } else {
  //       var response = JSON.parse(cryptr.decrypt(response));
  //       if (response.data.ResponseStatus === 3) {
  //         this.setState(
  //           {
  //             showAlertMessage: true,
  //             alertColor: "error",
  //             alertMessage: response.data.ResponseMsg
  //           },
  //           () => {
  //             window.setTimeout(() => {
  //               this.setState({ showAlertMessage: false });
  //               this.showNotification(this.state);
  //             }, this.state.alertTimeOut);
  //           }
  //         );
  //       } else if (response.data.ResponseStatus === 1) {
  //         this.setState({
  //           technologies: response.data.lstFilteredTechnology,
  //           total: response.data.lstFilteredTechnology.length,
  //           isLoading: false
  //         });
  //       } else {
  //         this.setState(
  //           {
  //             showAlertMessage: true,
  //             alertColor: "error",
  //             alertMessage: localStorage.getItem("failerMessage")
  //           },
  //           () => {
  //             window.setTimeout(() => {
  //               this.setState({ showAlertMessage: false });
  //               this.showNotification(this.state);
  //             }, this.state.alertTimeOut);
  //           }
  //         );
  //       }
  //     }
  //   });
  // };

  handleViewAll = e => {
    window.setTimeout(() => {
      this.props.history.push("/recruitportal/dashboard");
      this.props.history.push("/recruitportal/technology");
    }, 500);
  };

  showNotification(state) {
    new Noty({
      type: state.alertColor,
      theme: "bootstrap-v4",
      layout: "topRight",
      closeWith: ["button"],
      text: state.alertMessage,
      timeout: state.notyTimeOut
    }).show();
  }

  render() {
    const GridBind = (
      <Grid
        selectedField="selected"
        //filterable={true}
        sortable={true}
        reorderable={true}
        pageable={{
          buttonCount: 4,
          pageSizes: [5, 10, 20, 50, 100],
          info: true,
          type: "numeric",
          previousNext: true
        }}
        {...this.props}
        // {...this.state}
        skip={this.state.skip}
        take={this.state.take}
        sort={this.state.sort}
        filter={this.state.filter}
        data={process(this.state.technologies, this.state)}
        onItemChange={this.itemChange}
        onDataStateChange={e => {
          this.setState(e.data);
        }}
        onSelectionChange={this.selectionChange}
        onHeaderSelectionChange={this.headerSelectionChange}
        onRowClick={this.rowClick}
      >
        {/* <Column
          locked
          filterable={false}
          reorderable={false}
          field="selected"
          width="50px"
          headerSelectionValue={
            this.state.technologies.findIndex(
              dataItem => dataItem.selected === false
            ) === -1
          }
        /> */}
        <Column
          filterable={false}
          sortable={false}
          reorderable={false}
          title="Action"
          cell={this.showActionMenu}
          width="100px"
        />
        <Column
          field="TechnologyMasterID"
          filterable={false}
          reorderable={false}
          title="#"
          width="80px"
        />
        <Column
          field="Description"
          title="Description"
          columnMenu={ColumnMenu}
        />
        <Column
          field="CreatedDate"
          filterable={false}
          title="Created Date"
          cell={this.showDateFormat}
          width="280px"
        />
        <Column
          filterable={false}
          field="IsActive"
          title="Status"
          cell={this.showActiveInactive}
          width="120px"
        />
      </Grid>
    );

    return (
      <React.Fragment>
        <main role="main">
          {this.state.userType === "xRGjRCA3M94%3d" ||
          this.state.userType === "6QjgBiG4ZRU%3d" ? (
            <div className="starter-template view_recruit_main">
              <h1 className="h3 mb-3 font-weight-normal">Technology List</h1>

              {this.state.visibleDialog && (
                <Dialog title={"Please confirm"} onClose={this.toggleDialog}>
                  <p style={{ margin: "25px", textAlign: "center" }}>
                    Are you sure you want to delete?
                  </p>
                  <DialogActionsBar>
                    <button
                      className="m-2 btn btn-danger"
                      onClick={this.toggleDialog}
                    >
                      No
                    </button>
                    <button
                      className="m-2 btn btn-info"
                      onClick={() =>
                        this.handleYesButton(this.state.deleteProps)
                      }
                    >
                      Yes
                    </button>
                  </DialogActionsBar>
                </Dialog>
              )}
              <div className="row mb-4">
                {/* <div
                  className="field_text"
                  style={{ float: "left", padding: "0 15px" }}
                >
                  <div className="field_texttitel">From Date :</div>
                  <div className="field_textfield">
                    <DatePicker
                      format={"dd/MM/yyyy"}
                      name="fromDate"
                      defaultValue={dtFromDate}
                      max={this.state.technologyModal.toDate}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div
                  className="field_text"
                  style={{ float: "left", padding: "0 15px" }}
                >
                  <div className="field_texttitel">To Date : &nbsp;</div>
                  <div className="field_textfield">
                    <DatePicker
                      format={"dd/MM/yyyy"}
                      name="toDate"
                      defaultValue={dtToDate}
                      min={this.state.technologyModal.fromDate}
                      onChange={this.handleChange}
                    />
                  </div>
                </div> */}
                <div className="filter_rightpart full_width">
                  <div className="field_textbut">
                    {/* <Button
                    className="btn btn-info btn btn-secondary ciplbtn"
                    type="button"
                    onClick={this.handleSearch}
                  >
                    Search
                  </Button> */}
                    <Button
                      className="submit ciplbtn"
                      type="button"
                      onClick={this.handleViewAll}
                    >
                      View All
                    </Button>
                    <Button
                      className="addNew ciplbtn"
                      color="primary right"
                      onClick={this.handleAddTechnology}
                    >
                      Add Technology
                    </Button>
                  </div>
                </div>
              </div>

              <div>{GridBind}</div>
              {this.state.isLoading ? (
                <div className="k-loading-mask">
                  <span className="k-loading-text">Loading</span>
                  <div className="k-loading-image" />
                  <div className="k-loading-color" />
                </div>
              ) : null}
            </div>
          ) : (
            ""
          )}
        </main>
      </React.Fragment>
    );
  }
}

export default TechnologyList;
