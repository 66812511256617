import React, { Component } from "react";

class UserTypeCell extends Component {
  render() {
    const value = this.props.dataItem[this.props.field];
    return (
      <td>
        {value === null ? (
          ""
        ) : (
          <React.Fragment>
            <label htmlFor={this.props.dataItem["UserMasterID"]}>
              {value === 1 ? "Admin" : value === 2 ? "HR" : "Normal User"}
            </label>
          </React.Fragment>
        )}
      </td>
    );
  }
}

export default UserTypeCell;
