import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import "./css/custom.css";
import "noty/lib/noty.css";
import "noty/lib/themes/bootstrap-v4.css";
//import "./css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./Account/Login";
import AddRecruite from "./Admin/AddRecruite";
import NotFound from "./CommonFunctions/NotFound";
import ThankYou from "./Admin/ThankYou";
import LoadingPage from "./Admin/LoadingPage";
import ResetPassword from "./Account/ResetPassword";
import RedirectTo from "./Account/RedirectTo";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={AddRecruite} />
            <Route exact path="/thankyou:id" component={ThankYou} />
            <Route exact path="/loading" component={LoadingPage} />
            <Route path="/resetpassword:param" component={ResetPassword} />
            <Route path="/redirectTo:id" component={RedirectTo} />
            <Route path="/recruitportal" component={Login} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
