import React, { Component } from "react";
import Moment from "react-moment";
import { formatDate } from "@telerik/kendo-intl";

class DateFormatCell extends Component {
  render() {
    const value = this.props.dataItem[this.props.field];
    return (
      <td>
        {value === null
          ? ""
          : // <Moment format="DD/MM/YYYY">{new Date(value)}</Moment>
            formatDate(new Date(value), "dd/MM/yyyy")}
      </td>
    );
  }
}

export default DateFormatCell;
