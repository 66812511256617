export const decryptKey = "ewfWE@#%$rfdsefgdsf";

//export const captchaKey = "6LfPCrQUAAAAAJFsbFqONFCaEZCtJX_EKpB4uVnh";
export const captchaKey = "6Lee1rcUAAAAAJuOBLxjMQFJYHz6kaizowgLLns1";

export const emailAddressRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export const passwordRegex = RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*)()])(?=.{8,})"
);

export const digitRegex = RegExp(/^[0-9]\d*$/);

export const digitRegexWithEmptySpace = RegExp(/^(\s*|\d+)$/);


export const noScriptRegex = RegExp(/^[^<\>]*$/);
