import React, { Component } from "react";
import { technologyList } from "../APICalls/commonAPICalls";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Cryptr from "cryptr";
import { decryptKey } from "./CommonValues";
import { NoAccess } from "../Resources/MessageResource";

const cryptr = new Cryptr(decryptKey);

export default function TechnologyFilterCell(data, defaultItem) {
  return class extends Component {
    render() {
      return (
        <React.Fragment>
          <div className="k-filtercell">
            <DropDownList
              data={data}
              onChange={this.onChange}
              value={this.props.value || defaultItem}
              defaultItem={defaultItem}
            />
          </div>
        </React.Fragment>
      );
    }

    hasValue = value => Boolean(value && value !== defaultItem);

    onChange = event => {
      const hasValue = this.hasValue(event.target.value);
      this.props.onChange({
        value: hasValue ? event.target.value : "",
        operator: hasValue ? "eq" : "",
        syntheticEvent: event.syntheticEvent
      });
    };
  };
}
