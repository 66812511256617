import React, { Component } from "react";
import Noty from "noty";
import { Form, Input, Label, Button } from "reactstrap";
import logo from "../Images/CompanyLogo.png";
import { decryptKey, passwordRegex } from "../CommonFunctions/CommonValues";
import {
  resetPassword,
  checkResetPasswordLink
} from "../APICalls/commonAPICalls";
import Moment from "react-moment";
import Cryptr from "cryptr";

const cryptr = new Cryptr(decryptKey);
const strongRegex = passwordRegex;

const resetPasswordFormValid = ({ resetPasswordFormError, ...rest }) => {
  var valid = true;

  Object.values(resetPasswordFormError).map(val => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).map(val => {
    (val.newpassword === "" || val.confirmpassword === "") &&
      (val.newpassword !== "" && val.confirmpassword !== ""
        ? val.newpassword === val.confirmpassword
        : (valid = false)) &&
      (valid = false);
  });

  return valid;
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    const AuthKey = props.match.params.param;
    this.state = {
      isLoading: false,
      submitDisabled: false,
      showAlertMessage: false,
      alertMessage: "",
      alertColor: "",
      notyTimeOut: 5000,
      alertTimeOut: 500,
      resetPasswordModal: {
        authToken: AuthKey.replace(":", ""),
        newpassword: ""
      },
      resetPasswordFormError: {
        newpassword: "",
        confirmpassword: ""
      }
    };
  }

  async componentDidMount() {
    await checkResetPasswordLink(this.state.resetPasswordModal).then(
      response => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                submitDisabled: true,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
            this.props.history.push("/recruitportal");
          } else if (response.data.ResponseStatus === 1) {
          } else {
            this.setState(
              {
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          }
        }
      }
    );
  }

  handleChange = e => {
    var resetPasswordFormError = this.state.resetPasswordFormError;
    var { resetPasswordModal } = this.state;

    var target = e.target;
    var value = target.type === "checked" ? target.checked : target.value;
    var name = target.name;

    switch (name) {
      case "newpassword":
        resetPasswordModal.newpassword = value;
        resetPasswordFormError.newpassword =
          !strongRegex.test(value) && value.length > 0
            ? "Password must contain at least 1 upper case, 1 lower case, 1 special character and 1 number upto minimum 8 characters."
            : value.length > 0 && value.length < 6
            ? "Minimum 6 characters required"
            : "";
        break;
      case "confirmpassword":
        resetPasswordModal.confirmpassword = value;
        resetPasswordFormError.confirmpassword =
          !strongRegex.test(value) && value.length > 0
            ? "Password must contain at least 1 upper case, 1 lower case, 1 special character and 1 number upto minimum 8 characters."
            : value === resetPasswordModal.newpassword
            ? ""
            : "New Password and Confirm Password must be same.";
        break;
      default:
        break;
    }

    this.setState({
      resetPasswordFormError,
      resetPasswordModal
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    if (resetPasswordFormValid(this.state)) {
      this.setState({ isLoading: true });
      await resetPassword(this.state.resetPasswordModal).then(response => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                }, this.state.alertTimeOut);
              }
            );
          } else if (response.data.ResponseStatus === 1) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "success",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );

            var { resetPasswordModal } = this.state;
            resetPasswordModal.newpassword = "";
            resetPasswordModal.confirmpassword = "";
            this.setState({ resetPasswordModal });

            this.props.history.push("/recruitportal");
          } else {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          }
        }
      });
    } else {
      this.setState(
        {
          isLoading: false,
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: "Please fill proper details!"
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        }
      );
    }
  };

  onDismiss = () => {
    this.setState({ showAlertMessage: false });
  };

  showNotification(state) {
    new Noty({
      type: state.alertColor,
      theme: "bootstrap-v4",
      layout: "topRight",
      closeWith: ["button"],
      text: state.alertMessage,
      timeout: state.notyTimeOut
    }).show();
  }

  render() {
    const { resetPasswordFormError } = this.state;
    const { resetPasswordModal } = this.state;

    const ResetPasswordForm = (
      <Form
        className="form-signin"
        noValidate
        onSubmit={this.handleSubmit}
        style={{ textAlign: "left" }}
      >
        <div className="text-center mb-4">
          <img
            className="mb-4"
            src={logo}
            alt="Concept Infoway Pvt. Ltd."
            width="72"
            height="72"
          />
          <h1 className="h3 mb-3 font-weight-normal">Concept Infoway</h1>
        </div>
        <h1 className="h5 mb-3 font-weight-normal">Reset Password</h1>
        <div className="form-label-group">
          <Input
            type="password"
            id="newpassword"
            name="newpassword"
            className="form-control"
            placeholder="New Password"
            noValidate
            autoFocus
            value={resetPasswordModal.newpassword}
            onChange={this.handleChange}
          />
          <Label htmlFor="newpassword">New Password</Label>
          {resetPasswordFormError.newpassword.length > 0 && (
            <span className="errorMessage">
              {resetPasswordFormError.newpassword}
            </span>
          )}
        </div>

        <div className="form-label-group">
          <Input
            type="password"
            id="confirmpassword"
            name="confirmpassword"
            className="form-control"
            placeholder="Confirm Password"
            noValidate
            onChange={this.handleChange}
          />
          <Label htmlFor="confirmpassword">Confirm Password</Label>
          {resetPasswordFormError.confirmpassword.length > 0 && (
            <span className="errorMessage">
              {resetPasswordFormError.confirmpassword}
            </span>
          )}
        </div>

        <div>
          {this.state.submitDisabled ? (
            <Button
              className="btn btn-lg btn-primary btn-block mt-3 ciplbtn"
              color="primary"
              type="submit"
              disabled="disabled"
            >
              Submit
            </Button>
          ) : (
            <Button
              className="btn btn-lg btn-primary btn-block mt-3 ciplbtn"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          )}
        </div>
        <p className="mt-5 mb-3 text-muted text-center">
          Copyright &copy;
          <Moment format="YYYY">{this.state.currentDate}</Moment> Concept
          Infoway. All rights reserved
        </p>
      </Form>
    );

    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
          </div>
        ) : (
          ""
        )}
        <div className="container">{ResetPasswordForm}</div>
      </React.Fragment>
    );
  }
}

export default ResetPassword;
