import React, { Component } from "react";
import Noty from "noty";
import { Form, Input, Label, Button } from "reactstrap";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { addUpdateUser } from "../../APICalls/commonAPICalls";
import { getUserByID } from "../../APICalls/commonAPICalls";
import { NoAccess, EnterProperDetails } from "../../Resources/MessageResource";
import {
  decryptKey,
  noScriptRegex,
  emailAddressRegex,
  passwordRegex
} from "../../CommonFunctions/CommonValues";
import Cryptr from "cryptr";
import { async } from "q";

const cryptr = new Cryptr(decryptKey);
const noScriptTag = noScriptRegex;
const emailRegex = emailAddressRegex;
const userForValid = ({ userFormErrors, ...rest }) => {
  var valid = true;

  Object.values(userFormErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach(val => {
    (val.name === "" ||
      val.email === "" ||
      val.userTypeValue === "Please select User Type") &&
      (valid = false);
  });
  return valid;
};

class EditUser extends Component {
  userTypes = ["Admin", "HR", "Normal User"];
  constructor(props) {
    localStorage.setItem("localpath", props.location.pathname.split(":")[0]);
    super(props);
    const UserMasterID = props.match.params.id;
    this.state = {
      isLoading: false,
      userType: localStorage.getItem("userToken")
        ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
            .UserType
        : "",
      showUnauthorizedMessage: true,
      showAlertMessage: false,
      alertMessage: "",
      alertColor: "",
      notyTimeOut: 5000,
      alertTimeOut: 500,
      userData: {
        UserMasterID: cryptr.decrypt(UserMasterID.replace(":", ""))
      },
      userModal: {
        userMasterID: 0,
        name: "",
        email: "",
        userTypeValue: "Please select User Type",
        isActive: 0,
        encCreatedBy: localStorage.getItem("userToken")
          ? JSON.parse(cryptr.decrypt(localStorage.getItem("userToken"))).data
              .UserMasterID
          : 0,
        createdDate: new Date(),
        modifiedDate: new Date()
      },
      userFormErrors: {
        name: "",
        email: "",
        userTypeValue: ""
      }
    };
  }

  async componentDidMount() {
    var { userModal } = this.state;
    this.state.userType === "xRGjRCA3M94%3d" ||
    this.state.userType === "6QjgBiG4ZRU%3d"
      ? await getUserByID(this.state.userData).then(response => {
          if (localStorage.getItem("failerMessage")) {
            this.setState(
              {
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
            localStorage.removeItem("failerMessage");
          } else {
            var response = JSON.parse(cryptr.decrypt(response));
            if (response.data.ResponseStatus === 3) {
              this.setState(
                {
                  showAlertMessage: true,
                  alertColor: "error",
                  alertMessage: response.data.ResponseMsg
                },
                () => {
                  window.setTimeout(() => {
                    this.setState({ showAlertMessage: false });
                    this.showNotification(this.state);
                  }, this.state.alertTimeOut);
                }
              );
            } else if (response.data.ResponseStatus === 1) {
              userModal.userMasterID = response.data.UserMasterID;
              userModal.name = response.data.Name;
              userModal.email = response.data.Email;
              userModal.userTypeValue = response.data.UserTypeValue;
              userModal.isActive = response.data.IsActive;
              this.setState({
                userModal,
                isLoading: false
              });
            } else {
              this.setState(
                {
                  showAlertMessage: true,
                  alertColor: "error",
                  alertMessage: localStorage.getItem("failerMessage")
                },
                () => {
                  window.setTimeout(() => {
                    this.setState({ showAlertMessage: false });
                    this.showNotification(this.state);
                  }, this.state.alertTimeOut);
                }
              );
            }
          }
        })
      : this.setState(
          {
            showAlertMessage: true,
            alertColor: "error",
            alertMessage: NoAccess
          },
          () => {
            window.setTimeout(() => {
              this.showNotification(this.state);
            }, this.state.alertTimeOut);
          }
        );
  }

  handleSubmit = async e => {
    e.preventDefault();
    if (userForValid(this.state)) {
      this.setState({ isLoading: true });
      await addUpdateUser(this.state.userModal).then(response => {
        if (localStorage.getItem("failerMessage")) {
          this.setState(
            {
              isLoading: false,
              showAlertMessage: true,
              alertColor: "error",
              alertMessage: localStorage.getItem("failerMessage")
            },
            () => {
              window.setTimeout(() => {
                this.setState({ showAlertMessage: false });
                this.showNotification(this.state);
              }, this.state.alertTimeOut);
            }
          );
          localStorage.removeItem("failerMessage");
        } else {
          var response = JSON.parse(cryptr.decrypt(response));
          if (response.data.ResponseStatus === 3) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          } else if (
            response.data.ResponseStatus === 1 ||
            response.data.ResponseStatus === 5
          ) {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "success",
                alertMessage: response.data.ResponseMsg
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );

            window.setTimeout(() => {
              this.props.history.push("/recruitportal/user");
            }, 1000);

            //
            // this.setState({
            //   technologies: response.data.lstTechnology,
            //   total: response.data.lstTechnology.length,
            //   isLoading: false
            // });
          } else {
            this.setState(
              {
                isLoading: false,
                showAlertMessage: true,
                alertColor: "error",
                alertMessage: localStorage.getItem("failerMessage")
              },
              () => {
                window.setTimeout(() => {
                  this.setState({ showAlertMessage: false });
                  this.showNotification(this.state);
                }, this.state.alertTimeOut);
              }
            );
          }
        }
      });
    } else {
      this.setState(
        {
          isLoading: false,
          showAlertMessage: true,
          alertColor: "error",
          alertMessage: EnterProperDetails
        },
        () => {
          window.setTimeout(() => {
            this.setState({ showAlertMessage: false });
            this.showNotification(this.state);
          }, this.state.alertTimeOut);
        }
      );
    }
  };

  handleChange = e => {
    var userFormErrors = this.state.userFormErrors;
    var { userModal } = this.state;

    var target = e.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    var name = target.name;

    switch (name) {
      case "name":
        userModal.name = value;
        userFormErrors.name = !noScriptTag.test(value)
          ? "Invalid Name"
          : value.length > 0
          ? ""
          : "Please enter name";
        break;
      case "email":
        userModal.email = value;
        userFormErrors.email = !emailRegex.test(value)
          ? "Invalid Email Address"
          : value.length > 0
          ? ""
          : "Please enter email address";
        break;
      case "userTypeValue":
        userModal.userTypeValue = value; // === "Admin" ? 1 : value === "HR" ? 2 : 3;
        userFormErrors.userTypeValue =
          value === null ? "Please select user type" : "";
        break;
      case "status":
        userModal.isActive = value;
        break;
      default:
        break;
    }
    this.setState({
      userFormErrors,
      userModal
    });
  };

  handleBacktoList = () => {
    this.props.history.push("/recruitportal/user");
  };

  onDismiss = () => {
    this.setState({ showAlertMessage: false, showUnauthorizedMessage: false });
  };

  showNotification(state) {
    new Noty({
      type: state.alertColor,
      theme: "bootstrap-v4",
      layout: "topRight",
      closeWith: ["button"],
      text: state.alertMessage,
      timeout: state.notyTimeOut
    }).show();
  }

  render() {
    const { userFormErrors } = this.state;
    const { userModal } = this.state;

    const editTechnology = (
      <Form className="form-signin" noValidate onSubmit={this.handleSubmit}>
        <div className="mb-4">
          <h1 className="h5 mb-3 font-weight-normal">Edit User</h1>
          <div className="form-label-group">
            <Input
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="Name"
              noValidate
              autoFocus
              value={userModal.name}
              onChange={this.handleChange}
            />
            <span className="star">*</span>
            <Label htmlFor="name">Name</Label>
            {userFormErrors.name.length > 0 && (
              <span className="errorMessage">{userFormErrors.name}</span>
            )}
          </div>
          <div className="form-label-group">
            <Input
              type="email"
              id="email"
              name="email"
              className="form-control"
              placeholder="Email"
              noValidate
              value={userModal.email}
              onChange={this.handleChange}
            />
            <span className="star">*</span>
            <Label htmlFor="email">Email</Label>
            {userFormErrors.email.length > 0 && (
              <span className="errorMessage">{userFormErrors.email}</span>
            )}
          </div>
          <div className="form-label-group">
            <ComboBox
              className="form-control"
              name="userTypeValue"
              data={this.userTypes}
              value={userModal.userTypeValue}
              onChange={this.handleChange}
            />
            <span className="star">*</span>
            {userFormErrors.userTypeValue.length > 0 && (
              <span className="errorMessage">
                {userFormErrors.userTypeValue}
              </span>
            )}
          </div>
          <div>
            <input
              className="k-checkbox"
              name="status"
              checked={userModal.isActive ? true : false}
              type="checkbox"
              id={this.props.match.params.id}
              onChange={this.handleChange}
            />
            <label
              htmlFor={this.props.match.params.id}
              className={
                userModal.isActive
                  ? "k-checkbox-label"
                  : "k-checkbox-label inactive"
              }
            >
              {userModal.isActive ? "Active" : "In active"}
            </label>
          </div>
          <div>
            <Button
              className="btn btn-lg btn-primary btn-block mt-3 ciplbtn"
              color="primary"
              type="submit"
            >
              Update
            </Button>
            <input
              type="button"
              value="Back to list"
              className="btn btn-lg btn-secondary btn-block mt-3 ciplbtn"
              onClick={this.handleBacktoList}
            />
          </div>
        </div>
      </Form>
    );

    return (
      <React.Fragment>
        <main role="main">
          {this.state.userType === "xRGjRCA3M94%3d" ||
          this.state.userType === "6QjgBiG4ZRU%3d" ? (
            <div className="starter-template view_recruit_main">
              <h1 className="h3 mb-3 font-weight-normal" />
              <div style={{ textAlign: "left" }}>{editTechnology}</div>
              {this.state.isLoading ? (
                <div className="k-loading-mask">
                  <span className="k-loading-text">Loading</span>
                  <div className="k-loading-image" />
                  <div className="k-loading-color" />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </main>
      </React.Fragment>
    );
  }
}

export default EditUser;
